import {EgdLogo, GasLogo} from '@eon.cz/apollo13-frontend-common';
import {Komodita} from '@eon.cz/apollo13-graphql';

type Props = {
    type: Komodita | null | undefined;
};

/**
 * Zobrazení ikony pro danou komoditu
 */
export const KomoditaLogoIcon = ({type}: Props) =>
    type === Komodita.PLYN ? <GasLogo pathFill="white" width={45} height={45} /> : <EgdLogo pathFill="white" width={45} height={45} />;
