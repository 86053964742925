import {Komodita} from '@eon.cz/apollo13-graphql';
import {createContext} from 'react';

/**
 * Kontext obsahující pouze komoditu. Oddělený od ProfileContext protože se musí získávat výše ve stromu komponent než profil,
 * následně je komodita zkopírována i ProfileContext.
 */
export type KomoditaContextType = {
    komodita?: Komodita | null;
};

export const KomoditaContext = createContext<KomoditaContextType>({
    komodita: null,
});
