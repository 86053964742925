import {useQuery} from '@apollo/client';
import {Div, LoadingDialog, Span} from '@eon.cz/apollo13-frontend-common';
import {Komodita, Maybe, Query, UcetSkupinaTyp, UcetSpravceZmocnenecPristup, UctyQueryGetActivePristupyArgs} from '@eon.cz/apollo13-graphql';
import {AccountBoxOutlined, ExitToAppOutlined, LocationCityOutlined, VerifiedUserOutlined} from '@mui/icons-material';
import {Avatar, Button, Dialog, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {ChangeEvent, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {TransitionSlideUp} from '../../Common';
import {KomoditaSwitchBase} from '../../Common/components/layout/KomoditaSwitchBase';
import {LandingBackground} from '../../Common/components/layout/LandingBackground';
import {changeFavicon} from '../../Common/services/CommonService';
import {getFromLS} from '../../Common/services/PageService';
import {UcetQueries} from '../../Ucet/graphql/UcetQueries';

type Props = {
    handleChooseSkupina: (idPristupu: string | undefined, komodita: Komodita) => () => void;
    handleOnChooseKomodita: (komodita: Komodita) => void;
    handleLogout: () => void;
};

export const ChooseAccess = ({handleChooseSkupina, handleOnChooseKomodita, handleLogout}: Props) => {
    const [komodita, setKomodita] = useState(Komodita.ELEKTRINA);
    const [state, setState] = useState<Maybe<UcetSpravceZmocnenecPristup>[]>([]);
    const isMobile = getFromLS('mobile').isMobile;

    const {loading} = useQuery<Query, UctyQueryGetActivePristupyArgs>(UcetQueries.gql.activeAccess, {
        variables: {komodita},
        fetchPolicy: 'no-cache',
        onCompleted: (result) => {
            setState(result?.ucty?.getActivePristupy);
        },
    });
    const handleChangeKomoditaSwitch = (event: ChangeEvent<HTMLInputElement>) => {
        setKomodita(event.target.checked ? Komodita.PLYN : Komodita.ELEKTRINA);
        changeFavicon(event.target.checked ? Komodita.PLYN : Komodita.ELEKTRINA);
    };
    const handlePristup = (id: string | undefined, komodita: Komodita) => () => {
        handleChooseSkupina(id, komodita)();
        handleOnChooseKomodita(komodita);
    };
    return (
        <LandingBackground pageOff logoOff>
            <Dialog open={true} maxWidth="lg" TransitionComponent={TransitionSlideUp}>
                <Paper elevation={8} style={{maxHeight: 900, overflowY: state?.length > 6 ? 'auto' : 'hidden', overflowX: 'hidden'}}>
                    <Grid container alignItems="stretch" spacing={1}>
                        <Grid
                            sx={{
                                marginTop: '10px',
                                marginBottom: '10px',
                                textAlign: 'center',
                            }}
                            size={{xs: 12}}
                        >
                            {loading && <LoadingDialog open={true} />}
                            <Div
                                sx={{
                                    padding: 4,
                                    backgroundColor: 'background.paper',
                                    zIndex: 1,
                                }}
                            >
                                <Typography variant="h6">
                                    <FormattedMessage id={Lang.PRISTUP_VYBER} />
                                </Typography>
                            </Div>
                            {!isMobile && (
                                <Span sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                                    <Typography sx={{fontWeight: komodita !== Komodita.PLYN ? 'bold' : undefined}}>
                                        <FormattedMessage id="ucet.skupina.elektrina" />
                                        &nbsp;&nbsp;
                                    </Typography>
                                    <KomoditaSwitchBase onChange={handleChangeKomoditaSwitch} plyn={komodita === Komodita.PLYN} />
                                    &nbsp;&nbsp;
                                    <Typography sx={{fontWeight: komodita === Komodita.PLYN ? 'bold' : undefined}}>
                                        <FormattedMessage id="ucet.skupina.plyn" />
                                    </Typography>
                                </Span>
                            )}
                            <List>
                                {state?.length > 0 &&
                                    state?.map((pristup) => {
                                        const getIcon = (typ: UcetSkupinaTyp | undefined) => {
                                            switch (typ) {
                                                case UcetSkupinaTyp.MUNICIPALITA:
                                                    return <LocationCityOutlined />;
                                                case UcetSkupinaTyp.KONCOVY_ZAKAZNIK:
                                                    return <AccountBoxOutlined />;
                                                case undefined: {
                                                    throw new Error('Not implemented yet: undefined case');
                                                }
                                                case UcetSkupinaTyp.OBCHODNIK: {
                                                    throw new Error('Not implemented yet: UcetSkupinaTyp.OBCHODNIK case');
                                                }
                                                case UcetSkupinaTyp.OBCHODNIK_ZA_ZAKAZNIKA: {
                                                    throw new Error('Not implemented yet: UcetSkupinaTyp.OBCHODNIK_ZA_ZAKAZNIKA case');
                                                }
                                                case UcetSkupinaTyp.VYROBCE_PROVOZOVATEL: {
                                                    throw new Error('Not implemented yet: UcetSkupinaTyp.VYROBCE_PROVOZOVATEL case');
                                                }
                                                case UcetSkupinaTyp.ZADATEL: {
                                                    throw new Error('Not implemented yet: UcetSkupinaTyp.ZADATEL case');
                                                }
                                                case UcetSkupinaTyp.ZAKAZNIK_SE_SMLOUVOU_NAPRIMO: {
                                                    throw new Error('Not implemented yet: UcetSkupinaTyp.ZAKAZNIK_SE_SMLOUVOU_NAPRIMO case');
                                                }
                                                default:
                                                    return <VerifiedUserOutlined />;
                                            }
                                        };
                                        return (
                                            <ListItem key={pristup?.id} disablePadding>
                                                <ListItemButton onClick={handlePristup(pristup?.id, komodita)}>
                                                    <ListItemAvatar>
                                                        <Avatar sx={{backgroundColor: 'primary.main'}}>{getIcon(pristup?.skupina?.typ)}</Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText
                                                        primary={<Typography variant="body1">{pristup?.skupina?.nazev}</Typography>}
                                                        secondary={<FormattedMessage id={`sprava.uctu.typ.${pristup?.typ.toLowerCase()}`} />}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        );
                                    })}
                                {state?.length === 0 && !loading && (
                                    <>
                                        <Div sx={{padding: 4}}>
                                            <Typography variant="body1">
                                                <FormattedMessage id={Lang.PRISTUP_ZADNY_DESCRIPTION} />
                                            </Typography>
                                        </Div>
                                        <Div sx={{padding: 4}}>
                                            <Button onClick={handleLogout}>
                                                <ExitToAppOutlined />
                                                &nbsp;
                                                <FormattedMessage id={Lang.ODHLASIT_SE} />
                                            </Button>
                                        </Div>
                                    </>
                                )}
                            </List>
                        </Grid>
                    </Grid>
                </Paper>
            </Dialog>
        </LandingBackground>
    );
};
