import {gql} from '@apollo/client';

export const UcetQueries = {
    gql: {
        withProfile: gql`
            query WithProfileQuery {
                me {
                    id
                    admin
                    email
                    jmeno
                    prijmeni
                    typ
                    telefon
                    mobileLogin
                    prihlaseniSppAvailable
                    skupina {
                        id
                        typ
                        nazev
                        elektrina
                        plyn
                        ic
                        dic
                        cislo
                        poskytovatelElektrina
                        poskytovatelPlyn
                        eicObchodnika
                        eanObchodnika
                        obchodniPartner {
                            nazevSpolecnosti
                            ic
                            datumNarozeni
                            jmeno
                            prijmeni
                            typ
                            adresa {
                                cisloOrientacni
                                cisloPopisne
                                mesto
                                mistniCast
                                psc
                                ulice
                            }
                        }
                    }
                    podpisDodatku
                    podpisSopElektrina
                    podpisSopPlyn
                    registrovany
                    pristup {
                        komodita
                        aktivni
                        email
                        id
                        typ
                        jmeno
                        lzeAktivovat
                        prijmeni
                        telefon
                        skupina {
                            typ
                            id
                            elektrina
                            plyn
                            nazev
                            obchodniPartner {
                                adresa {
                                    cisloOrientacni
                                    cisloPopisne
                                    mesto
                                    mistniCast
                                    psc
                                    ulice
                                }
                                datumNarozeni
                                ic
                                id
                                jmeno
                                nazevSpolecnosti
                                prijmeni
                            }
                        }
                    }
                }
                # ucty {
                #     getActivePristupy(komodita: $komodita) @include(if: $isKomodita) {
                #         aktivni
                #         id
                #         typ
                #         skupina {
                #             typ
                #             id
                #             nazev
                #             plyn
                #             elektrina
                #         }
                #     }
                # }
                adminContext {
                    adminId
                    readOnly
                }
                nastaveni {
                    prilohy {
                        povoleneTypyPriloh {
                            typObjektu
                            typyPriloh
                        }
                    }
                }
            }
        `,
        withProfileUcty: gql`
            query WithProfileQueryUctyActivePristupy($komodita: Komodita!) {
                ucty {
                    getActivePristupy(komodita: $komodita) {
                        aktivni
                        id
                        typ
                        skupina {
                            typ
                            id
                            nazev
                            plyn
                            elektrina
                        }
                    }
                }
            }
        `,

        globalniNastaveni: gql`
            query NastenkaGlobalniNastaveni {
                nastaveni {
                    globalni {
                        globalniNastaveni {
                            scNastenkaObchodnikObsahHtml
                            scNastenkaObchodnikTitulekText
                            scNastenkaZakaznikObsahHtml
                            scNastenkaZakaznikTitulekText
                            scNastenkaMunicipalitaObsahHtml
                            scNastenkaMunicipalitaTitulekText
                            scNastenkaSppObsahHtml
                            scNastenkaSppTitulekText
                            scNastenkaZadatelObsahHtml
                            scNastenkaZadatelTitulekText
                        }
                    }
                }
            }
        `,
        globalniNastaveniSppWidget: gql`
            query NastenkaGlobalniNastaveniSppWidget {
                nastaveni {
                    globalni {
                        globalniNastaveni {
                            scNastenkaSppObsahHtml
                            scNastenkaSppTitulekText
                        }
                    }
                }
            }
        `,

        nastenkaGlobalniKontakty: gql`
            query NastenkaGlobalniKontakty($notZadatel: Boolean!, $komodita: Komodita!) {
                nastaveni {
                    globalni {
                        kontaktyDistributoraNastenka {
                            globalniProTypSkupiny(komodita: $komodita) {
                                nazev
                                typ
                                kontakty {
                                    druhKomunikace
                                    hodnota
                                }
                            }
                            skupinyNaPracovnikyDistribuce(komodita: $komodita) @include(if: $notZadatel) {
                                pracovnik {
                                    jmeno
                                    prijmeni
                                    email
                                    telefon
                                }
                                typ
                            }
                        }
                    }
                }
            }
        `,

        investicniStavby: gql`
            query InvesticniStavby {
                nastaveni {
                    planovaneStavby {
                        geoPortalRedirectUrl
                        textace
                    }
                }
            }
        `,

        profileHeader: gql`
            query PageHeaderQuery {
                me {
                    id
                    email
                    jmeno
                    prijmeni
                    multifactorAuth
                    admin
                    typ
                    skupina {
                        id
                        typ
                        nazev
                    }
                    registrovany
                    pristup {
                        aktivni
                        email
                        id
                        typ
                        jmeno
                        lzeAktivovat
                        skupina {
                            id
                            nazev
                        }
                    }
                }
            }
        `,

        profile: gql`
            query PageHeaderQueryProfile($komodita: Komodita!) {
                me {
                    id
                    email
                    jmeno
                    prijmeni
                    admin
                    multifactorAuth
                    typ
                    telefon
                    skupina {
                        id
                        cislo
                        typ
                        nazev
                        elektrina
                        plyn
                        ic
                        dic
                        eanObchodnika
                        eicObchodnika
                        obchodniPartner {
                            nazevSpolecnosti
                            ic
                        }
                    }
                    registrovany
                    pristup {
                        id
                        aktivni
                        skupina {
                            id
                            cislo
                            nazev
                            typ
                            elektrina
                            plyn
                            ic
                            dic
                            eanObchodnika
                            eicObchodnika
                            obchodniPartner {
                                nazevSpolecnosti
                                ic
                            }
                        }
                    }
                }
                ucty {
                    getActivePristupy(komodita: $komodita) {
                        aktivni
                        id
                        typ
                        skupina {
                            typ
                            id
                            nazev
                            plyn
                            elektrina
                        }
                    }
                }
            }
        `,
        activeAccess: gql`
            query AktivniPristupyNaSkupiny($komodita: Komodita!) {
                ucty {
                    getActivePristupy(komodita: $komodita) {
                        aktivni
                        id
                        typ
                        skupina {
                            typ
                            id
                            nazev
                            plyn
                            elektrina
                        }
                    }
                }
            }
        `,
        opacnakomodita: gql`
            query PristupNaOpacnouKomoditu {
                ucty {
                    pristupNaOpacnouKomoditu {
                        id
                        komodita
                    }
                }
            }
        `,
    },
};
