import {ActionButton, ElectricityIcon, useMatches} from '@eon.cz/apollo13-frontend-common';
import {Komodita} from '@eon.cz/apollo13-graphql';
import {ArrowForwardOutlined} from '@mui/icons-material';
import {Dialog, DialogActions, DialogContent, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {LandingBackground} from '../../Common/components/layout/LandingBackground';
import {saveToLS} from '../../Common/services/PageService';

type Props = {
    handleOnChooseKomodita: (komodita: Komodita) => void;
};
/**
 * Komponenta zobrazí profilovou stránku uživatele
 */
export const MobileKomoditaInfoDialog = ({handleOnChooseKomodita}: Props) => {
    const matches = useMatches();
    const handleChooseKomodita = (komodita: Komodita) => () => {
        saveToLS('mobile', {firstLogin: true});
        handleOnChooseKomodita(komodita);
    };
    return (
        <LandingBackground pageOff logoOff>
            <Dialog open data-testid={`komodita-info`}>
                <DialogContent sx={{margin: 'auto', textAlign: 'center'}}>
                    <ElectricityIcon />
                    <Typography variant="h6">
                        <FormattedMessage id="komodita.mobile.info.title" />
                    </Typography>
                    <Typography>
                        <FormattedMessage id="komodita.mobile.info.text" />
                    </Typography>
                </DialogContent>
                <DialogActions sx={{margin: 'auto', width: {xs: '100%', sm: 'auto'}}}>
                    <ActionButton
                        fullWidth={matches}
                        variant="contained"
                        caption="komodita.ok"
                        onClick={handleChooseKomodita(Komodita.ELEKTRINA)}
                        icon={<ArrowForwardOutlined />}
                    />
                </DialogActions>
            </Dialog>
        </LandingBackground>
    );
};
