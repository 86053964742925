import {gql} from '@apollo/client';
import {TooltipIcon} from '@eon.cz/apollo13-frontend-common/lib/components/icons/TooltipIcon';
import {Adresa} from '@eon.cz/apollo13-graphql';
import {TableCell} from '@mui/material';
import {TableCellProps} from '@mui/material/TableCell';
import {formatAddress} from '../../Utils';

type Props = TableCellProps & {
    adresa: Adresa | null | undefined;
    short?: boolean;
};

export const AdresaFragment = {
    adresa: gql`
        fragment AdresaFragment on Adresa {
            ulice
            cisloPopisne
            cisloOrientacni
            mesto
            psc
            mistniCast
        }
    `,
};

/**
 * Komponenta zobrazující adresu v určitém formátu
 */
export const AdresaTableCell = ({adresa, short, ...props}: Props) => {
    const reformatAddress = formatAddress(adresa);
    return (
        <TableCell {...props} sx={{whiteSpace: 'nowrap'}}>
            {short ? (
                <TooltipIcon textRaw={reformatAddress}>
                    <span>{adresa?.mesto}</span>
                </TooltipIcon>
            ) : (
                reformatAddress
            )}
        </TableCell>
    );
};
