import {BezproudiIcon, ElectricityIcon} from '@eon.cz/apollo13-frontend-common';
import {Komodita, Maybe, Ucet, UcetSkupinaTyp, UcetSpravceZmocnenecPristup, UcetSpravceZmocnenecTypPristupu, UcetTyp} from '@eon.cz/apollo13-graphql';
import {
    AssessmentOutlined,
    AssignmentOutlined,
    AttachFileOutlined,
    AutorenewOutlined,
    DashboardOutlined,
    DomainOutlined,
    ElectricBoltOutlined,
    GroupAddOutlined,
    ImportContactsOutlined,
    MenuOutlined,
    MobileFriendlyOutlined,
    PersonOutlined,
    PhoneOutlined,
    PlaylistAddCheckOutlined,
    ReceiptOutlined,
    SettingsInputComponentOutlined,
    SpeakerNotesOutlined,
    SubtitlesOutlined,
    WhatshotOutlined,
} from '@mui/icons-material';
import Router from 'next/router';
import {ParsedUrlQuery} from 'querystring';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {FeatureUtils} from '../../Utils/FeatureUtils';
import {AppBottomNavigationModel, AppLeftMenuModel, PageRoute, PageRouteModel} from '../model';
import {MobileModalModel} from '../model/AppBottomNavigationModel';
import {isProdPreprod} from './CommonService';
import {getFromLS} from './PageService';

/**
 * Vyfiltruje položky v hlavním menu podle skupiny
 * @param {Ucet} account
 * @param {Komodita | null} komodita
 */
const filterRoute =
    (account: Ucet | null | undefined, komodita: Komodita | null | undefined) =>
    (f: PageRouteModel | string): boolean => {
        if (!!account && !account.registrovany) {
            // Neregistrovaný vidí pouze nástěnku a Požadavky
            return f === PageRoute.NASTENKA || f === PageRoute.POZADAVKY;
        }

        if (f === PageRoute.KONTAKTY) {
            // Správu účtů vidí pouze admin
            const obchodnik = account?.typ === UcetTyp.OBCHODNIK;
            const zakaznikMunicipalita = account?.typ === UcetTyp.SPRAVCE_ZMOCNENEC;
            const elektrina = komodita === Komodita.ELEKTRINA;
            const admin = !!account && account?.admin;
            return (admin && obchodnik) || (elektrina && zakaznikMunicipalita);
        }
        if (f === PageRoute.SPRAVA_UCTU || f === PageRoute.MOBILE) {
            // Správu účtů vidí pouze admin
            return (!!account && account?.admin) || account?.typ !== UcetTyp.ZADATEL;
        }

        if (account) {
            // Vrátit seznam podle typu skupiny/uživatele
            if (account?.skupina?.typ === 'OBCHODNIK') {
                return (
                    f === PageRoute.NASTENKA ||
                    f === PageRoute.REKLAMACE ||
                    f === PageRoute.ZADOSTI ||
                    (f === PageRoute.DODATKY && komodita === Komodita.PLYN) ||
                    f === PageRoute.ODECTY ||
                    f === PageRoute.SMLOUVY_ODBERNYCH_MIST ||
                    f === PageRoute.PRILOHY_KE_STAZENI ||
                    (f === PageRoute.REVIZNI_ZPRAVY && komodita === Komodita.ELEKTRINA) ||
                    (f === PageRoute.RSODI && account?.admin && komodita === Komodita.ELEKTRINA && FeatureUtils.isFeatureEnabled('RSODI_ENABLED'))
                );
            }
            if (account?.typ === 'ZADATEL' || account?.pristup?.typ === UcetSpravceZmocnenecTypPristupu.ZADATEL) {
                return (
                    f === PageRoute.NASTENKA ||
                    f === PageRoute.ESD ||
                    f === PageRoute.ZADOSTI_NE_OBCHODNIK ||
                    (f === PageRoute.ELEKTRIKARI_ZADATEL && komodita === Komodita.ELEKTRINA)
                );
            }
            if (account?.skupina?.typ === 'KONCOVY_ZAKAZNIK') {
                return (
                    f === PageRoute.NASTENKA ||
                    f === PageRoute.ZADOSTI ||
                    f === PageRoute.SMLOUVY_ODBERNYCH_MIST ||
                    f === PageRoute.PRILOHY_KE_STAZENI ||
                    // Prozatim skryto
                    // f === PageRoute.ZADOSTI_NE_OBCHODNIK ||
                    (f === PageRoute.BEZPROUDI && komodita === Komodita.ELEKTRINA) ||
                    f === PageRoute.ODECTY ||
                    (f === PageRoute.PLANOVANE_SERVISNI_PRACE && komodita === Komodita.ELEKTRINA) ||
                    (f === PageRoute.ELEKTRIKARI && komodita === Komodita.ELEKTRINA)
                );
            }
            if (account?.skupina?.typ === 'MUNICIPALITA') {
                return (
                    f === PageRoute.NASTENKA ||
                    f === PageRoute.ZADOSTI ||
                    (f === PageRoute.BEZPROUDI && komodita === Komodita.ELEKTRINA) ||
                    f === PageRoute.SMLOUVY_ODBERNYCH_MIST ||
                    f === PageRoute.PRILOHY_KE_STAZENI ||
                    f === PageRoute.ODECTY ||
                    (f === PageRoute.PLANOVANE_SERVISNI_PRACE && komodita === Komodita.ELEKTRINA) ||
                    f === PageRoute.INVESTICNI_STAVBY ||
                    (f === PageRoute.ELEKTRIKARI && komodita === Komodita.ELEKTRINA)
                    // Prozatim skryto
                    // f === PageRoute.ZADOSTI_NE_OBCHODNIK
                );
            }
        }

        // Není účet, není položka :)
        return false;
    };

/**
 * Vyfiltruje sekci Nastavení v hlavním menu jen pro administrátory skupiny
 * @param {Ucet} account
 */
const filterSettingsRoute =
    (account: Ucet, komodita: Komodita | null | undefined) =>
    (f: AppLeftMenuModel): boolean => {
        if (f.route === PageRoute.KONTAKTY) {
            // Kontakty vidí jen admin
            if (account && !account.admin) {
                return false;
            }

            if (account?.typ === UcetTyp.ZADATEL || account?.pristup?.typ === UcetSpravceZmocnenecTypPristupu.ZADATEL) {
                return true;
            }

            if (account && account.skupina !== null) {
                const zakaznikMunicipalita = account?.typ === UcetTyp.SPRAVCE_ZMOCNENEC;
                const elektrina = komodita === Komodita.ELEKTRINA;
                // Kontakty mají jen některé typu skupin
                switch (account?.skupina?.typ) {
                    case UcetSkupinaTyp.OBCHODNIK:
                        return true;
                    case UcetSkupinaTyp.ZADATEL:
                    case UcetSkupinaTyp.KONCOVY_ZAKAZNIK:
                    case UcetSkupinaTyp.MUNICIPALITA:
                        return elektrina && zakaznikMunicipalita;
                    case undefined:
                        return false;
                    case UcetSkupinaTyp.OBCHODNIK_ZA_ZAKAZNIKA:
                        return false;
                    case UcetSkupinaTyp.VYROBCE_PROVOZOVATEL:
                        return false;
                    case UcetSkupinaTyp.ZAKAZNIK_SE_SMLOUVOU_NAPRIMO:
                        return false;
                    default:
                        return false;
                }
            }
        } else if (f.route === PageRoute.SPRAVA_UCTU) {
            // Správu účtů vidí pouze admin
            return !!account && account.typ !== UcetTyp.ZADATEL && account?.pristup?.typ !== UcetSpravceZmocnenecTypPristupu.ZADATEL;
        } else if (f.route === PageRoute.MOBILE) {
            return !isProdPreprod;
        }

        // Nemělo by sem spadnout - schovat
        return false;
    };

/**
 * Get pathname (URI) for given route. Komodita can be omitted when it is not necessary.
 * The whole point of this function is to prepend komodity or not depending on
 * configuration. This is cored of the function.
 *
 * @param {PageRouteModel} route - Route to get path
 * @param {string} postfix - Postfix to append after the route. MUST start with '/' if not empty!
 * @param {Komodita} komodita - Komodita when needed. If needed and not provided, exception is thrown.
 */
export const getPathname = (route: PageRouteModel | undefined, postfix?: string, komodita?: Komodita | null) => {
    if (typeof postfix === 'string' && postfix.length > 0 && postfix[0] !== '/') {
        throw Error(`Postfix ${postfix} not starting with '/'!`);
    }

    if (route?.prependCommodity) {
        if (!komodita) {
            if (route.commodityCanBeMissing) {
                // Just don't prepend
                return `${route.path}${typeof postfix === 'string' ? postfix : ''}`;
            }
            throw Error(`Komodita not provided for route ${route.path}`);
        }

        // Prepend komodita
        return `/${komodita.toLowerCase()}${route.path}${typeof postfix === 'string' ? postfix : ''}`;
    } else {
        // Just a simple path
        return `${route?.path}${typeof postfix === 'string' ? postfix : ''}`;
    }
};

export const RouteService = {
    /**
     *
     * @param {Ucet} account
     * @param {Komodita | null} komodita
     */
    getPageRoutes: (account: Ucet | null | undefined, komodita: Komodita | null | undefined): PageRouteModel[] => {
        return Object.keys(PageRoute)
            .map((m) => PageRoute[m as keyof typeof PageRoute])
            .filter(filterRoute(account, komodita));
    },

    /**
     * Vytvoří položky v menu
     * @param {Ucet} account
     * @param {boolean} restrictedMode
     * @param {Komodita} komodita
     */
    getMenuItems: (account: Ucet, restrictedMode: boolean, komodita: Komodita | null | undefined, admin: boolean): Array<AppLeftMenuModel | undefined> => {
        const isZadatel = account?.typ === UcetTyp.ZADATEL || account?.pristup?.typ === UcetSpravceZmocnenecTypPristupu.ZADATEL;
        if (!account || restrictedMode) {
            return [];
        }
        return [
            {
                label: Lang.NASTENKA,
                route: PageRoute.NASTENKA,
                icon: <DashboardOutlined />,
                description: Lang.NASTENKA_DESCRIPTION,
                afterFragment: true,
                allowInRestrictedMode: true,
            },
            (account?.prihlaseniSppAvailable || admin) && !account.mobileLogin && komodita === Komodita.ELEKTRINA && !isZadatel
                ? {
                      label: Lang.SPP,
                      sppLogin: true,
                      icon: 'login',
                      description: Lang.SPP_DESCRIPTION,
                      afterFragment: true,
                      allowInRestrictedMode: true,
                  }
                : undefined,
            {label: Lang.BEZPROUDI, route: PageRoute.BEZPROUDI, icon: <BezproudiIcon />, description: Lang.BEZPROUDI, afterFragment: false},
            {
                label: Lang.SERVISNI_ZAKAZKY_TITLE,
                route: PageRoute.PLANOVANE_SERVISNI_PRACE,
                icon: <ReceiptOutlined />,
                description: Lang.SERVISNI_ZAKAZKY_TITLE,
                afterFragment: false,
            },
            {
                label: Lang.INVESTICNI_STAVBY_TITLE,
                route: PageRoute.INVESTICNI_STAVBY,
                icon: <DomainOutlined />,
                description: Lang.INVESTICNI_STAVBY_TITLE,
                afterFragment: false,
            },
            {label: Lang.ODECTY, route: PageRoute.ODECTY, icon: <AssessmentOutlined />, description: Lang.ODECTY_DESCRIPTION, afterFragment: false},
            {
                label: Lang.ODBERNA_MISTA_TITLE,
                route: PageRoute.SMLOUVY_ODBERNYCH_MIST,
                description: Lang.ODBERNA_MISTA_TITLE,
                icon: <SettingsInputComponentOutlined />,
                afterFragment: false,
            },
            {label: Lang.ZADOSTI, route: PageRoute.ZADOSTI, icon: <ReceiptOutlined />, description: Lang.ZADOSTI_DESCRIPTION, afterFragment: false},
            {
                label: Lang.ZADOSTI,
                route: PageRoute.ZADOSTI_NE_OBCHODNIK,
                description: Lang.ZADOSTI_DESCRIPTION,
                icon: <ReceiptOutlined />,
                afterFragment: false,
            },
            {
                label: Lang.REVIZNI_ZPRAVY,
                route: PageRoute.REVIZNI_ZPRAVY,
                description: Lang.REVIZNI_ZPRAVY,
                icon: <PlaylistAddCheckOutlined />,
                afterFragment: false,
            },
            {label: Lang.DODATKY, route: PageRoute.DODATKY, description: Lang.DODATKY, icon: <AssignmentOutlined />, afterFragment: false},
            {label: Lang.REKLAMACE, route: PageRoute.REKLAMACE, description: Lang.REKLAMACE, icon: <SpeakerNotesOutlined />, afterFragment: false},
            {
                label: Lang.SESTAVY_PRILOHY_KE_STAZENI,
                route: PageRoute.PRILOHY_KE_STAZENI,
                icon: <AttachFileOutlined />,
                description: Lang.SESTAVY_PRILOHY_KE_STAZENI_DESCRIPTION,
                afterFragment: false,
            },
            {
                label: Lang.ZADOSTI_ZAKLADNI_SMLOUVY,
                route: PageRoute.RSODI,
                icon: <SubtitlesOutlined />,
                description: Lang.ZADOSTI_ZAKLADNI_SMLOUVY_DESCRIPTION,
                afterFragment: false,
            },
            {
                label: Lang.ELEKTRIKARI,
                route: PageRoute.ELEKTRIKARI,
                icon: <ElectricBoltOutlined />,
                description: Lang.ELEKTRIKARI,
                afterFragment: false,
            },
            {
                label: Lang.ELEKTRIKARI,
                route: PageRoute.ELEKTRIKARI_ZADATEL,
                icon: <ElectricBoltOutlined />,
                description: Lang.ELEKTRIKARI,
                afterFragment: false,
            },
            {
                label: Lang.NASTAVENI,
                icon: 'settings',
                children: [
                    {label: Lang.SPRAVA_UCTU, route: PageRoute.SPRAVA_UCTU, icon: <GroupAddOutlined />, description: Lang.SPRAVA_UCTU, afterFragment: false},
                    {label: Lang.MOBILE_TITLE, route: PageRoute.MOBILE, icon: <MobileFriendlyOutlined />, description: Lang.MOBILE_TITLE, afterFragment: true},
                    {label: Lang.KONTAKTY, route: PageRoute.KONTAKTY, icon: <ImportContactsOutlined />, description: Lang.KONTAKTY, afterFragment: true},
                ].filter(filterSettingsRoute(account, komodita)),
                afterFragment: true,
            },
            (account?.prihlaseniSppAvailable || admin) && account.mobileLogin
                ? {
                      label: Lang.SPP,
                      sppLogin: true,
                      icon: 'login',
                      description: Lang.SPP_DESCRIPTION,
                      afterFragment: true,
                      allowInRestrictedMode: true,
                  }
                : undefined,
        ]
            .filter((f) => !!f)
            .filter((f) => (!f?.route && account.registrovany) || RouteService.getPageRoutes(account, komodita).includes(f?.route ?? {path: ''}))
            .filter((f) => f?.allowInRestrictedMode || !restrictedMode)
            .filter((f) => f?.children?.length !== 0); // Filter out elements with an empty chilren list
    },

    /**
     * Vytvoří odkazy ve spodní části stránky
     * @param {Ucet | null} account
     * @param {boolean} loggedInLimitedMode
     * @param {Komodita | null | undefined} komodita
     */
    getBottomMenu: (account: Ucet | null, loggedInLimitedMode: boolean, komodita: Komodita | null | undefined): AppBottomNavigationModel[] => {
        if (!account || loggedInLimitedMode) {
            return [];
        }

        return [
            {
                value: 'dashboard',
                label: <FormattedMessage id={Lang.NASTENKA} />,
                route: PageRoute.NASTENKA,
                icon: <DashboardOutlined />,
                showLabel: true,
                allowInLimitedMode: true,
            },
            {
                value: 'zadosti',
                label: <FormattedMessage id={Lang.ZADOSTI} />,
                route: PageRoute.ZADOSTI,
                icon: <ReceiptOutlined />,
                showLabel: true,
            },
            {
                value: 'zadosti',
                label: <FormattedMessage id={Lang.ZADOSTI} />,
                route: PageRoute.ZADOSTI_NE_OBCHODNIK,
                icon: <ReceiptOutlined />,
                showLabel: true,
                isVisible: true,
            },
            {
                value: 'odecty',
                label: <FormattedMessage id={Lang.ODECTY} />,
                route: PageRoute.ODECTY,
                icon: <AssessmentOutlined />,
                showLabel: true,
            },
            {
                value: 'odbernaMista',
                label: <FormattedMessage id={Lang.ODBERNA_MISTA_TITLE} />,
                route: PageRoute.SMLOUVY_ODBERNYCH_MIST,
                icon: <SettingsInputComponentOutlined />,
                showLabel: true,
            },
        ]
            .filter((f) => (account && !f.route && account.registrovany) || RouteService.getPageRoutes(account, komodita).includes(f.route))
            .filter((f) => (account.typ === UcetTyp.ZADATEL ? f : !f.isVisible))
            .filter((f) => f.allowInLimitedMode || !loggedInLimitedMode);
    },
    /**
     * Vytvoří odkazy ve spodní části stránky na mobilu
     * @param {Ucet | null} account
     * @param {boolean} loggedInLimitedMode
     * @param {Komodita | null | undefined} komodita
     */
    getMobileBottomMenu: (
        account: Ucet | null,
        komodita: Komodita | null | undefined,
        pristupy: Maybe<UcetSpravceZmocnenecPristup>[] | undefined,
    ): AppBottomNavigationModel[] => {
        const isMobile = getFromLS('mobile').isMobile;
        const zakaznikMunicipalita = account?.typ === UcetTyp.SPRAVCE_ZMOCNENEC;
        const elektrina = komodita === Komodita.ELEKTRINA;
        if (!account) {
            return [];
        }

        return [
            {
                value: 'dashboard',
                label: <FormattedMessage id={Lang.NASTENKA} />,
                modal: MobileModalModel.DASHBOARD,
                icon: <DashboardOutlined />,
                showLabel: true,
                isVisible: account.typ === UcetTyp.ZADATEL || account?.typ === UcetTyp.OBCHODNIK,
            },
            {
                value: 'komodita',
                label: <FormattedMessage id={Lang.KOMODITA} />,
                modal: MobileModalModel.KOMODITA,
                icon: (
                    <>
                        {komodita === Komodita.ELEKTRINA && <ElectricityIcon color="secondary" />}
                        {komodita === Komodita.PLYN && <WhatshotOutlined color="secondary" />}
                    </>
                ),
                showLabel: true,
                isVisible: ((account?.typ === UcetTyp.OBCHODNIK && account.skupina?.plyn && account.skupina?.elektrina) || zakaznikMunicipalita) && !isMobile,
            },
            {
                value: 'kontakty',
                label: <FormattedMessage id={Lang.MENU_KONTAKTY} />,
                modal: MobileModalModel.KONTAKTY,
                icon: <PhoneOutlined />,
                showLabel: true,
                isVisible: elektrina && zakaznikMunicipalita,
            },
            {
                value: 'pristupy',
                label: <FormattedMessage id={Lang.MENU_PRISTUPY} />,
                modal: MobileModalModel.PRISTUPY,
                icon: <AutorenewOutlined />,
                showLabel: true,
                isVisible: account?.typ === UcetTyp.SPRAVCE_ZMOCNENEC && pristupy && pristupy?.length > 0,
            },
            {
                value: 'profil',
                label: <FormattedMessage id={Lang.SPRAVA_UCTU_TAB_PROFIL} />,
                modal: MobileModalModel.PROFIL,
                icon: <PersonOutlined />,
                showLabel: true,
                isVisible: true,
            },
            {
                value: 'menu',
                label: <FormattedMessage id={Lang.MENU} />,
                modal: MobileModalModel.MENU,
                icon: <MenuOutlined />,
                showLabel: true,
                isVisible: true,
            },
        ].filter((f) => f.isVisible);
    },

    /**
     * Get pathname (URI) for given route. Komodita can be omitted when it is not necessary.
     * The whole point of this function is to prepend komodity or not depending on
     * configuration.
     *
     * @param {PageRouteModel} route - Route to get path
     * @param {string} postfix - Postfix to append after the route. MUST start with '/' if not empty!
     * @param {Komodita} komodita - Komodita when needed. If needed and not provided, exception is thrown.
     */
    getPathname: (route: PageRouteModel | undefined, postfix?: string, komodita?: Komodita | null) => {
        // Get path name
        const pathName = getPathname(route, postfix, komodita);

        // Convert empty string to slash (= go to root) since empty string does not work that way,
        // but we need it in case commodity is used.
        return pathName === '' ? '/' : pathName;
    },

    /**
     * Check whether given path name equals or is below given route
     * @param {string | string[]} pathname - Path to check
     * @param {PageRouteModel} route - Route
     */
    isOnRoute: (pathname: string | string[], route: PageRouteModel) => {
        const path = typeof pathname === 'string' ? pathname : `/${pathname.join('/')}`;

        if (route.prependCommodity) {
            return path.startsWith(`/elektrina${route.path}`) || path.startsWith(`/plyn${route.path}`);
        } else {
            return path.startsWith(route?.path);
        }
    },

    /**
     * Go to detail (= something that has id parameter) - put id into "id" parameter and as the last component of the URL
     *
     * @param {PageRouteModel} route - Base route (see getPathname())
     * @param {string} postfix - Postfix of the route (see getPathname())
     * @param {string} id - Id of detail
     * @param {Komodita} komodita - Commodity to include in the URL (see getPathname())
     */
    goToDetail: (route: PageRouteModel, postfix: string, id: string, komodita?: Komodita) => {
        const pathname = RouteService.getPathname(route, postfix, komodita);
        return Router.push(
            {
                pathname,
                query: {id},
            },
            `${pathname}/${id}`,
        );
    },

    /**
     * Remove query params
     *
     * @param {Array<string>} param - Array of queries to delete)
     * @param {ParsedUrlQuery} query - Postfix of the route (see getPathname())
     */
    removeQueryParam: (param: Array<string>, query: ParsedUrlQuery) => {
        const updatedQuery = query;
        param.forEach((p) => {
            delete updatedQuery[p];
        });

        Router.push({query: updatedQuery}, undefined, {shallow: true});
    },
};

/**
 * The function `rightOfAccess` determines if a user has access to a specific route based on their
 * account and commodity.
 * @param {Ucet | null | undefined} account - The `account` parameter is of type `Ucet`, which could be
 * `null` or `undefined`.
 * @param {Komodita | null | undefined} komodita - Komodita is a parameter representing a commodity or
 * product. It seems to be used in the function `rightOfAccess` to determine access rights based on the
 * provided account, commodity, and route.
 * @param {string} route - The `route` parameter in the `rightOfAccess` function is a string that
 * represents the current route or path in the application. It is used to determine whether the user
 * has the right of access based on the provided account and commodity.
 * @returns a boolean value.
 */
export const rightOfAccess = (account: Ucet | null | undefined, komodita: Komodita | null | undefined, route: string) => {
    return ['', '/elektrina', '/plyn', '/'].includes(route)
        ? true
        : RouteService.getPageRoutes(account, komodita).filter(
              (predicate) =>
                  predicate.path ===
                  `/${
                      route
                          .split('/')
                          .filter(Boolean)
                          .filter((path) => path !== 'elektrina' && path !== 'plyn')[0]
                  }`,
          ).length > 0;
};

/**
 * Get path name from route and postfix, and convert empty string to slash (= go to root) since empty
 * string does not work that way.
 * @param {PageRoute} route - PageRoute
 * @param {string} [postfix] - string = ''
 * @returns A function that takes in a route and a postfix and returns a pathname.
 */
export const getParsePathname = (route: PageRouteModel, postfix?: string) => {
    // Get path name
    const pathName = getPathname(route, postfix);

    // Convert empty string to slash (= go to root) since empty string does not work that way
    return pathName === '' ? '/' : pathName;
};
