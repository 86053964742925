import {useQuery} from '@apollo/client';
import {Div} from '@eon.cz/apollo13-frontend-common';
import {Komodita, Query, ZadostStav} from '@eon.cz/apollo13-graphql';
import {ReceiptOutlined} from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import Router from 'next/router';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Lang} from '../../../../../Lang';
import {PageRoute, RouteService} from '../../../../Common';
import {DetailTitle} from '../../../../Nastenka/common/DetailTitle';
import {NastenkaWidget} from '../../../../Nastenka/common/NastenkaWidget';
import {PocetStavuWidget} from '../../../../Nastenka/common/PocetStavuWidget';
import {ZadostiService} from '../../../services/ZadostiService';
import {ZadostiElektrinaActionCreator} from '../../actions';
import {ZadostiElektrinaQueriesNastenka} from '../../graphql';

type Props = {
    loading: boolean;
};

/**
 * Komponenta zobrazí widget žádostí o SOP na elektřině na nástěnce
 */
export const ZadostiElektrinaSopWidget = ({loading}: Props) => {
    const {changeSopZmenaJisticeTab, changeSopZmenaJisticeZadostiSelectedLeftMenuType, changeTab, changeSopZadostiSelectedLeftMenuType, changeSopPrepisTab} =
        ZadostiElektrinaActionCreator(useDispatch());
    const {loading: loadingPocty, data} = useQuery<Query>(ZadostiElektrinaQueriesNastenka.gql.poctyStavuZadostiSopElektrina, {fetchPolicy: 'network-only'});

    const vCekaNaPodpisSopPrepis = data?.elektrina?.zadosti?.nastenka?.sopPodpis.prepis ?? 0;

    const vCekaNaPodpisSopZmenaJistice = data?.elektrina?.zadosti?.nastenka?.sopPodpis.zmenaJistice ?? 0;

    const prepisAktivni = data?.elektrina?.zadosti?.nastenka?.sopPodpis.prepisAktivni ?? 0;

    const zmenaJisticeAktivni = data?.elektrina?.zadosti?.nastenka?.sopPodpis.zmenaJisticeAktivni ?? 0;

    const onClickZadosti = (stav: ZadostStav) => {
        changeTab(2);
        changeSopPrepisTab(1);
        changeSopZadostiSelectedLeftMenuType(stav);
        Router.push({pathname: RouteService.getPathname(PageRoute.ZADOSTI, '', Komodita.ELEKTRINA)});
    };

    const onClickZadostiSopPrepis = (stav: ZadostStav) => () => {
        changeTab(2);
        changeSopPrepisTab(1);
        changeSopZadostiSelectedLeftMenuType(stav);
        Router.push({pathname: RouteService.getPathname(PageRoute.ZADOSTI, '', Komodita.ELEKTRINA)});
    };

    const onClickZadostiSopZmenaJistice = (stav: ZadostStav) => () => {
        changeTab(3);
        changeSopZmenaJisticeTab(0);
        changeSopZmenaJisticeZadostiSelectedLeftMenuType(stav);
        Router.push({pathname: RouteService.getPathname(PageRoute.ZADOSTI, '', Komodita.ELEKTRINA)});
    };

    const handleOnClick = (stav: ZadostStav) => () => {
        if (vCekaNaPodpisSopZmenaJistice > 0 && vCekaNaPodpisSopPrepis > 0) {
            onClickZadosti(stav);
        }
        if (vCekaNaPodpisSopPrepis > 0 && vCekaNaPodpisSopZmenaJistice === 0) {
            onClickZadostiSopPrepis(stav)();
        }
        if (vCekaNaPodpisSopPrepis === 0 && vCekaNaPodpisSopZmenaJistice > 0) {
            onClickZadostiSopZmenaJistice(stav)();
        }
    };

    return (
        <NastenkaWidget
            titleBlockIcon={<ReceiptOutlined color="primary" />}
            title={<FormattedMessage id={Lang.ZADOSTI_NASTENKA_WIDGET_SOP_TITLE} />}
            onClick={handleOnClick(ZadostStav.CEKA_NA_PODPIS)}
            loading={loading || loadingPocty}
        >
            <Grid container spacing={2}>
                {prepisAktivni && vCekaNaPodpisSopPrepis > 0 && (
                    <Grid size={{xs: 12, md: zmenaJisticeAktivni ? 6 : 12}}>
                        <Div sx={{paddingLeft: 1}}>
                            <DetailTitle title={Lang.ZADOSTI_NASTENKA_WIDGET_SOP_PREPIS} />
                        </Div>
                        <PocetStavuWidget
                            stav={ZadostStav.CEKA_NA_PODPIS}
                            pocet={vCekaNaPodpisSopPrepis}
                            icon={ZadostiService.getZadostiIconByLeftMenuType(ZadostStav.CEKA_NA_PODPIS)}
                            onClick={onClickZadostiSopPrepis}
                        />
                    </Grid>
                )}
                {zmenaJisticeAktivni && vCekaNaPodpisSopZmenaJistice > 0 && (
                    <Grid size={{xs: 12, md: prepisAktivni ? 6 : 12}}>
                        <Div sx={{paddingLeft: 1}}>
                            <DetailTitle title={Lang.ZADOSTI_NASTENKA_WIDGET_SOP_PREPIS_ZMENA_JISTICE} />
                        </Div>
                        <PocetStavuWidget
                            stav={ZadostStav.CEKA_NA_PODPIS}
                            pocet={vCekaNaPodpisSopZmenaJistice}
                            icon={ZadostiService.getZadostiIconByLeftMenuType(ZadostStav.CEKA_NA_PODPIS)}
                            onClick={onClickZadostiSopZmenaJistice}
                        />
                    </Grid>
                )}
            </Grid>
        </NastenkaWidget>
    );
};
