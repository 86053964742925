import {gql} from '@apollo/client';
import {DetailValue} from '@eon.cz/apollo13-frontend-common';
import {ObchodniPartner, ObchodniPartnerTyp} from '@eon.cz/apollo13-graphql';
import {FormattedDate} from 'react-intl';
import {Lang} from '../../../Lang';

type Props = {
    obchodniPartner: ObchodniPartner;
};

export const ObchodniPartnerIcDetailValueFragment = {
    obchodniPartner: gql`
        fragment ObchodniPartnerIcDetailValue on ObchodniPartner {
            typ
            ic
            datumNarozeni
        }
    `,
};

const getLabel = (typ: ObchodniPartnerTyp) =>
    typ === ObchodniPartnerTyp.PRAVNICKA_OSOBA ? Lang.ODBERNA_MISTA_ZAKAZNIK_IC : Lang.ODBERNA_MISTA_ZAKAZNIK_DATUM_NAROZENI;

const getValue = ({typ, ic, datumNarozeni}: ObchodniPartner) =>
    typ === ObchodniPartnerTyp.PRAVNICKA_OSOBA ? ic : datumNarozeni && <FormattedDate value={datumNarozeni} />;

/**
 * Komponenta pro zobrazení datumu narození nebo IČ z objektu obchodního partnera
 */
export const ObchodniPartnerIcDetailValue = ({obchodniPartner, ...props}: Props) => (
    <DetailValue title={getLabel(obchodniPartner.typ)} value={getValue(obchodniPartner)} {...props} />
);
