import {Komodita, Query, ZadostStav} from '@eon.cz/apollo13-graphql';
import Router from 'next/router';

import {useQuery} from '@apollo/client';
import {ReceiptOutlined} from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import {FormattedMessage} from 'react-intl';
import {useDispatch} from 'react-redux';
import {Lang} from '../../../../../Lang';
import {PageRoute, RouteService} from '../../../../Common';
import {NastenkaWidget} from '../../../../Nastenka/common/NastenkaWidget';
import {PocetStavuWidget} from '../../../../Nastenka/common/PocetStavuWidget';
import {ZadostiService} from '../../../services/ZadostiService';
import {ZadostiPlynActionCreator} from '../../actions';
import {ZadostiPlynQueriesNastenka} from '../../graphql';

type Props = {
    loading: boolean;
};
/**
 * Komponenta zobrazí widget žádostí o SOP na elektřině na nástěnce
 */
export const ZadostiPlynSopWidget = ({loading}: Props) => {
    const {changeTab, changeSopZadostiSelectedLeftMenuType, changeSopTab} = ZadostiPlynActionCreator(useDispatch());

    const {loading: loadingPocty, data} = useQuery<Query>(ZadostiPlynQueriesNastenka.gql.sopPodpisAktivni, {fetchPolicy: 'network-only'});

    const vCekaNaPodpisSopPrepis = data?.plyn?.zadosti?.nastenka?.sopPodpis?.prepis;

    const onClickZadostiSop = (stav: ZadostStav) => () => {
        changeTab(3);
        changeSopTab(1);
        changeSopZadostiSelectedLeftMenuType(stav);
        Router.push({pathname: RouteService.getPathname(PageRoute.ZADOSTI, '', Komodita.PLYN)});
    };

    return (
        <NastenkaWidget
            titleBlockIcon={<ReceiptOutlined color="primary" />}
            title={<FormattedMessage id={Lang.ZADOSTI_NASTENKA_WIDGET_SOP_TITLE} />}
            onClick={onClickZadostiSop(ZadostStav.CEKA_NA_PODPIS)}
            loading={loading || loadingPocty}
        >
            <Grid container spacing={2}>
                <Grid size={{xs: 12}}>
                    <PocetStavuWidget
                        stav={ZadostStav.CEKA_NA_PODPIS}
                        pocet={vCekaNaPodpisSopPrepis}
                        icon={ZadostiService.getZadostiIconByLeftMenuType(ZadostStav.CEKA_NA_PODPIS)}
                        onClick={onClickZadostiSop}
                    />
                </Grid>
            </Grid>
        </NastenkaWidget>
    );
};
