import {useMatches} from '@eon.cz/apollo13-frontend-common/lib/utils/CommonUtils';
import {Komodita} from '@eon.cz/apollo13-graphql';
import {SettingsInputComponentOutlined} from '@mui/icons-material';
import Grid from '@mui/material/Grid2';
import {useRouter} from 'next/router';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {PageRoute} from '../../Common/model/PageRouteModel';
import {useTablet} from '../../Common/services/PageService';
import {RouteService} from '../../Common/services/RouteService';
import {NastenkaWidget} from '../../Nastenka/common/NastenkaWidget';
import {OdbernaMistaIndexContainer} from '../containers/OdbernaMistaIndexContainer';

type Props = {
    komodita: Komodita | null | undefined;
};
export const OdbernaMistaWidget = ({komodita}: Props) => {
    const matches = useMatches();
    const isTablet = useTablet();

    const {push, pathname} = useRouter();
    const komoditaFromUrl = pathname.includes(`elektrina`) ? Komodita.ELEKTRINA : Komodita.PLYN;

    const handleOnClick = () => push(RouteService.getPathname(PageRoute.SMLOUVY_ODBERNYCH_MIST, '', komodita ?? komoditaFromUrl));
    return (
        <NastenkaWidget
            titleBlockIcon={<SettingsInputComponentOutlined color="primary" />}
            title={<FormattedMessage id={Lang.ODBERNA_MISTA_TITLE} />}
            onClick={handleOnClick}
            backgroundDefault={matches || isTablet}
            unlimitedheight
        >
            <Grid container>
                <Grid size={{xs: 12}}>
                    <OdbernaMistaIndexContainer dashboard={true} />
                </Grid>
            </Grid>
        </NastenkaWidget>
    );
};
