import {ApiDate, Komodita} from '@eon.cz/apollo13-graphql';

export type PrilohyKeStazeniFilterModel = {
    datumOd: ApiDate;
    datumDo: ApiDate;
    typ?: string | null;
    komodita: Komodita | undefined | null;
    cisloOp?: string;
    cisloDokladu?: string | null;
    stav?: DanoveDokladyStavPlatby | null;
};
export type TechnickeDetailyOMModel = {
    platnostZacatekSmlouvyOd: ApiDate;
    platnostZacatekSmlouvyDo: ApiDate;
    datumVymenyOd: ApiDate;
    datumVymenyDo: ApiDate;
};

export const DanoveDokladyStavPlatby = {
    VSE: 'VSE',
    CEKA_NA_ZAPLACENI: 'CEKA_NA_ZAPLACENI',
    CEKA_NA_ZAPLACENI_PO_SPLATNOSTI: 'CEKA_NA_ZAPLACENI_PO_SPLATNOSTI',
    CEKA_NA_ZAPLACENI_PRED_SPLATNOSTI: 'CEKA_NA_ZAPLACENI_PRED_SPLATNOSTI',
    UHRAZENO: 'UHRAZENO',
} as const;

export type DanoveDokladyStavPlatby = (typeof DanoveDokladyStavPlatby)[keyof typeof DanoveDokladyStavPlatby];
