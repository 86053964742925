import {useLazyQuery, useQuery} from '@apollo/client';
import {UserIcon, isNullOrUndefinedOrEmpty, useMatches} from '@eon.cz/apollo13-frontend-common';
import {
    DanovyDokladSekce,
    Komodita,
    PrilohyKeStazeniQueryDanovyDokladZobrazitArgs,
    Query,
    UcetSkupinaTyp,
    UcetSpravceZmocnenecTypPristupu,
    UcetTyp,
} from '@eon.cz/apollo13-graphql';
import {Avatar, List, ListItem, ListItemAvatar, ListItemText} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {KontaktyQueries} from 'client/modules/Kontakty/graphql/KontaktyQueries';
import dynamic from 'next/dynamic';
import {useContext, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {useSelector} from 'react-redux';
import {Store} from '../../../Store';
import {BezproudiQueries} from '../../Bezproudi/graphql/BezproudiQueries';
import {showBezproudiWidget} from '../../Bezproudi/services/BezproudiService';
import {PageContext} from '../../Common/contexts/PageContext';
import {sendDataToRN} from '../../Common/services/CommonService';
import {getFromLS} from '../../Common/services/PageService';
import {DodatkyQueries} from '../../Dodatky/graphql/DodatkyQueries';
import {BiometryConfirmationDialog} from '../../Mobile/components/BiometryConfirmationDialog';
import {OdbernaMistaWidget} from '../../OdbernaMista/components/OdbernaMistaWidget';
import {OdbernaMistaQueries} from '../../OdbernaMista/graphql';
import {OdbernaMistaService} from '../../OdbernaMista/service/OdbernaMistaService';
import {PrilohyKeStazeniWidget} from '../../PrilohyKeStazeni/components/PrilohyKeStazeniWidget';
import {PrilohyKeStazeniQueries} from '../../PrilohyKeStazeni/graphql/PrilohyKeStazeniQueries';
import {OdplombovaniWidget} from '../../Zadosti/elektrina/components/odbornici/OdplombovaniWidget';
import {ZadostiElektrinaSopWidget} from '../../Zadosti/elektrina/components/widget/ZadostiElektrinaSopWidget';
import {ZadostiElektrinaQueriesNastenka} from '../../Zadosti/elektrina/graphql';
import {ZadostiPlynSopWidget} from '../../Zadosti/plyn/components/widget/ZadostiPlynSopWidget';
import {ZadostiPlynQueriesNastenka} from '../../Zadosti/plyn/graphql';

const NastenkaWelcomeWidget = dynamic(() => import('../../Nastenka/components/NastenkaWelcomeWidget').then((mod) => mod.NastenkaWelcomeWidget));
const ZadatelWidget = dynamic(() => import('./ZadatelWidget').then((mod) => mod.ZadatelWidget));
const VyjadrovackyWidget = dynamic(() => import('./VyjadrovackyWidget').then((mod) => mod.VyjadrovackyWidget));
const SppLoginWidget = dynamic(() => import('../../Nastenka/components/SppLoginWidget').then((mod) => mod.SppLoginWidget));
const GlobalniKontaktyDistributoraWidget = dynamic(() =>
    import('../../Nastenka/components/GlobalniKontaktyDistributoraWidget').then((mod) => mod.GlobalniKontaktyDistributoraWidget),
);
const NastenkaInvesticniStavbyWidget = dynamic(() => import('../../Nastenka/components/InvesticniStavby').then((mod) => mod.NastenkaInvesticniStavbyWidget));
const BezproudiWidget = dynamic(() => import('../../Bezproudi/components/BezproudiWidget').then((mod) => mod.BezproudiWidget));
const ZadostiElektrinaWidget = dynamic(() => import('../../Zadosti/elektrina').then((mod) => mod.ZadostiElektrinaWidget));
const ZadostiPlynWidget = dynamic(() => import('../../Zadosti/plyn').then((mod) => mod.ZadostiPlynWidget));
const ReklamaceElektrinaWidget = dynamic(() => import('../../Reklamace/elektrina').then((mod) => mod.ReklamaceElektrinaWidget));
const ReklamacePlynWidget = dynamic(() => import('../../Reklamace/plyn').then((mod) => mod.ReklamacePlynWidget));
const DodatkyWidget = dynamic(() => import('../../Dodatky/components/widget/DodatkyWidget').then((mod) => mod.DodatkyWidget));
const KontaktyWidget = dynamic(() => import('../../Kontakty/components/KontaktyWidget').then((mod) => mod.KontaktyWidget));
// const ServisniZakazkyWidget = dynamic(() => import('../../ServisniZakazky/components/ServisniZakazkyWidget').then((mod) => mod.ServisniZakazkyWidget));

const IndexPage = () => {
    const matches = useMatches();
    const {me, komodita, loggedInRestrictedMode} = useContext(PageContext);
    const [openBiometricsDialog, setOpenBiometricsDialog] = useState(false);
    const admin = useSelector((state: Store) => state.common.admin);
    const mobile = getFromLS('mobile');
    const isMobile = getFromLS('mobile')?.isMobile;
    const canUseBiometrics = mobile.canUseBiometrics;
    const userId = mobile?.userId;
    const showBiometryConfirmation = isMobile && mobile?.biometryAvailable && isNullOrUndefinedOrEmpty(canUseBiometrics) && isNullOrUndefinedOrEmpty(userId);
    const isZadatel = me?.typ === UcetTyp.ZADATEL || me?.pristup?.typ === UcetSpravceZmocnenecTypPristupu.ZADATEL;
    const sppLoginAvailable = admin || me?.prihlaseniSppAvailable;
    const [loadDodatky, {data: dataDodatky, loading: loadingDodatky}] = useLazyQuery<Query>(DodatkyQueries.gql.nastenka, {
        fetchPolicy: 'network-only',
    });
    const {data: dataBezproudi, loading: loadingBezproudi} = useQuery<Query>(BezproudiQueries.gql.bezproudiNastenka, {
        fetchPolicy: 'no-cache',
        skip: !(me?.registrovany && komodita === Komodita.ELEKTRINA && me?.skupina?.typ !== UcetSkupinaTyp.OBCHODNIK && !isZadatel),
        variables: {isMunicipalita: me?.skupina?.typ === UcetSkupinaTyp.MUNICIPALITA},
        onError: () => undefined,
        errorPolicy: 'none',
    });
    const {data: dataDanoveDoklady, loading: loadingDanoveDoklady} = useQuery<Query, PrilohyKeStazeniQueryDanovyDokladZobrazitArgs>(
        PrilohyKeStazeniQueries.gql.danovyDokladyZobrazit,
        {
            fetchPolicy: 'no-cache',
            skip: me?.typ !== UcetTyp.OBCHODNIK && me?.pristup?.typ !== UcetSpravceZmocnenecTypPristupu.SPRAVCE,
            variables: {komodita: (komodita as Komodita) ?? Komodita.ELEKTRINA, sekce: DanovyDokladSekce.NASTENKA},
            notifyOnNetworkStatusChange: true,
        },
    );

    // const [loadSZ, {data: dataSZ, loading: loadingSZ}] = useLazyQuery<Query, ServisniZakazkyQuerySearchArgs>(ServisniZakazkyQueries.gql.search, {
    //     fetchPolicy: 'network-only',
    // });
    const [loadSopElektrina, {data: dataSopElektrina, loading: loadingSopElektrina}] = useLazyQuery<Query>(
        ZadostiElektrinaQueriesNastenka.gql.sopPodpisAktivni,
        {
            fetchPolicy: 'network-only',
        },
    );
    const [loadNezvalidovaneKontakty, {data: dataNezvalidovaneKontakty, loading: loadingNezvalidovaneKontakty}] = useLazyQuery<Query>(
        KontaktyQueries.gql.kontaktyNastenka,
        {
            fetchPolicy: 'no-cache',
        },
    );
    const [loadSopPlyn, {data: dataSopPlyn, loading: loadingSopPlyn}] = useLazyQuery<Query>(ZadostiPlynQueriesNastenka.gql.sopPodpisAktivni, {
        fetchPolicy: 'network-only',
    });

    const [loadHDOs] = useLazyQuery<Query>(OdbernaMistaQueries.gql.getHDOs, {
        fetchPolicy: 'network-only',
        onCompleted: (data) => {
            const hdo = data.elektrina.hdos?.getHdos;
            const remap = hdo?.map((neco) => ({
                ean: neco?.ean,
                vlastniNazevOM: neco?.vlastniNazevOM,
                hdo: neco?.hdo.map((n) => ({
                    sazby: n?.sazby.map((sazba) => ({
                        id: Math.floor(Math.random() * (1000000 - 1 + 1)) + 1,
                        dny: sazba?.dny?.map((den) => ({id: Math.floor(Math.random() * (1000000 - 1 + 1)) + 1, casy: den?.casy, denVTydnu: den?.denVTydnu})),
                        sazba: OdbernaMistaService.getNazevSazbyString(sazba?.sazba).trim(),
                    })),
                    endDate: n?.endDate,
                }))[0],
            }));
            sendDataToRN({setWidgetData: remap});
        },
    });

    useEffect(() => {
        if (!isZadatel && !loggedInRestrictedMode && (me?.typ === UcetTyp.OBCHODNIK || me?.pristup?.id)) {
            if (me?.registrovany && komodita === Komodita.PLYN) {
                loadDodatky();
            }
            if (me?.registrovany && komodita === Komodita.ELEKTRINA && me?.skupina?.typ === UcetSkupinaTyp.OBCHODNIK) {
                loadSopElektrina();
            }
            if (me?.registrovany && komodita === Komodita.PLYN && me?.skupina?.typ === UcetSkupinaTyp.OBCHODNIK) {
                loadSopPlyn();
            }
            if (me?.admin && (me?.skupina?.typ === UcetSkupinaTyp.MUNICIPALITA || me?.skupina?.typ === UcetSkupinaTyp.KONCOVY_ZAKAZNIK)) {
                loadNezvalidovaneKontakty();
            }
            if (me?.skupina?.typ !== UcetSkupinaTyp.OBCHODNIK && isMobile) {
                loadHDOs();
            }

            // if (me?.skupina?.typ === UcetSkupinaTyp.MUNICIPALITA || me?.skupina?.typ === UcetSkupinaTyp.KONCOVY_ZAKAZNIK) {
            //     loadSZ({variables: {single: true}});
            // }
        }
        if (me?.typ !== UcetTyp.OBCHODNIK && isMobile) {
            sendDataToRN({hideTabs: 'HIDE'});
        }
    }, [me, komodita, loadDodatky, loadSopElektrina, loadSopPlyn, loadNezvalidovaneKontakty, isZadatel, loggedInRestrictedMode, isMobile, loadHDOs]);

    const handleCloseBiometryDialog = () => setOpenBiometricsDialog(false);

    if (loggedInRestrictedMode) {
        // No other agendas available in limited mode
        return <Grid container spacing={2} />;
    }

    if (showBiometryConfirmation && !openBiometricsDialog) {
        setOpenBiometricsDialog(true);
    }

    if (!me?.registrovany || isZadatel) {
        return (
            <Grid container spacing={1}>
                {isZadatel && (
                    <>
                        <Grid size={{xs: 12, sm: 6, lg: 4}}>
                            <NastenkaWelcomeWidget me={me} />
                        </Grid>
                        <Grid size={{xs: 12, sm: 6, lg: 4}}>
                            <Grid container spacing={0.5}>
                                <Grid size={{xs: 12}}>
                                    <ZadatelWidget />
                                </Grid>
                                {komodita === Komodita.ELEKTRINA && (
                                    <Grid size={{xs: 12}}>
                                        <OdplombovaniWidget />
                                    </Grid>
                                )}
                                {komodita === Komodita.PLYN && (
                                    <Grid size={{xs: 12}}>
                                        <VyjadrovackyWidget komodita={komodita} />
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        {komodita === Komodita.ELEKTRINA && (
                            <Grid size={{xs: 12, sm: 6, lg: 4}}>
                                <VyjadrovackyWidget komodita={komodita} />
                            </Grid>
                        )}
                        {openBiometricsDialog && <BiometryConfirmationDialog open={openBiometricsDialog} onClose={handleCloseBiometryDialog} />}
                    </>
                )}
            </Grid>
        );
    }

    if (me?.registrovany && !isZadatel) {
        const isObchodnik = me?.skupina?.typ === UcetSkupinaTyp.OBCHODNIK;
        const isMunicipalita = me?.skupina?.typ === UcetSkupinaTyp.MUNICIPALITA;
        const isZakaznik = me?.skupina?.typ === UcetSkupinaTyp.KONCOVY_ZAKAZNIK;
        const pravaDodatky = isObchodnik && 'podpisDodatku' in me && !!me.podpisDodatku && komodita === Komodita.PLYN;
        const kPodpisuAktivni = dataDodatky?.plyn?.dodatky?.nastenka?.dodatkyPodpisStav?.kPodpisuAktivni ?? false;
        const zobrazitDodatky = pravaDodatky && kPodpisuAktivni;
        const zobrazitBezproudi =
            komodita === Komodita.ELEKTRINA && !isObchodnik && !isZadatel && showBezproudiWidget(dataBezproudi?.elektrina.bezproudi?.nastenka);
        const zadostiElektrina = isObchodnik && komodita === Komodita.ELEKTRINA;
        const zadostiPlyn = isObchodnik && komodita === Komodita.PLYN;
        const sopPodpis = isObchodnik && komodita === Komodita.ELEKTRINA ? dataSopElektrina?.elektrina?.zadosti?.nastenka?.sopPodpis : undefined;
        const showSopPodpis =
            (sopPodpis && sopPodpis?.prepisAktivni && sopPodpis?.prepis > 0) || (sopPodpis?.zmenaJisticeAktivni && sopPodpis?.zmenaJistice > 0);
        const sopPodpisPlyn = isObchodnik && komodita === Komodita.PLYN ? dataSopPlyn?.plyn?.zadosti?.nastenka?.sopPodpis : undefined;
        const reklamaceElektrina = isObchodnik && komodita === Komodita.ELEKTRINA;
        const reklamacePlyn = isObchodnik && komodita === Komodita.PLYN;
        const showKontakty =
            !isObchodnik && komodita === Komodita.ELEKTRINA && dataNezvalidovaneKontakty && dataNezvalidovaneKontakty?.kontakty?.kontaktyInvalid?.length > 0;
        const showDanoveDoklady = dataDanoveDoklady?.prilohyKeStazeni?.danovyDokladZobrazit ?? false;

        return (
            <Grid container spacing={1}>
                {openBiometricsDialog && <BiometryConfirmationDialog open={openBiometricsDialog} onClose={handleCloseBiometryDialog} />}
                {matches && (
                    <Grid size={{xs: 12}}>
                        <List dense disablePadding>
                            <ListItem>
                                <ListItemAvatar>
                                    <Avatar sx={{backgroundColor: 'secondary.main'}}>
                                        <UserIcon />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={`${me?.jmeno} ${me?.prijmeni}`}
                                    secondary={<FormattedMessage id={`ucet.skupina.typ.${me?.skupina?.typ.toUpperCase()}`} />}
                                />
                            </ListItem>
                        </List>
                    </Grid>
                )}
                {!!me && (
                    <Grid size={{xs: 12, sm: 6, lg: 6, xl: 4}}>
                        <NastenkaWelcomeWidget me={me} />
                    </Grid>
                )}

                {showDanoveDoklady && !sppLoginAvailable && !loadingDanoveDoklady && (
                    <Grid size={{xs: 12, sm: 6, lg: 6, xl: 4}}>
                        <PrilohyKeStazeniWidget komodita={komodita} isObchodnik={me.skupina?.typ === UcetSkupinaTyp.OBCHODNIK} standAlone />
                    </Grid>
                )}

                {zobrazitBezproudi && !loadingBezproudi && (
                    <Grid size={{xs: 12, sm: 6, lg: 6, xl: 4}}>
                        <BezproudiWidget
                            dataBezproudi={dataBezproudi}
                            loading={loadingBezproudi}
                            isZakaznik={isZakaznik}
                            spravce={me?.pristup?.typ === UcetSpravceZmocnenecTypPristupu.SPRAVCE}
                        />
                    </Grid>
                )}

                {sppLoginAvailable && !showDanoveDoklady && !me.mobileLogin && komodita === Komodita.ELEKTRINA && (
                    <Grid size={{xs: 12, sm: 6, md: 6, lg: 6, xl: 4}}>
                        <SppLoginWidget standAlone komodita={komodita} />
                    </Grid>
                )}
                {sppLoginAvailable && showDanoveDoklady && !loadingDanoveDoklady && (
                    <Grid size={{xs: 12, sm: 6, lg: 4}}>
                        <Grid container spacing={0.5}>
                            {!me.mobileLogin && komodita === Komodita.ELEKTRINA && (
                                <Grid size={{xs: 12}}>
                                    <SppLoginWidget komodita={komodita} />
                                </Grid>
                            )}
                            <Grid size={{xs: 12}}>
                                <PrilohyKeStazeniWidget komodita={komodita} isObchodnik={me.skupina?.typ === UcetSkupinaTyp.OBCHODNIK} />
                            </Grid>
                        </Grid>
                    </Grid>
                )}

                {/* Na produkci zakazano */}
                {/* {showServisniZakazky && (
                    <Grid item xs={12} sm={6} lg={4}>
                        <ServisniZakazkyWidget loading={loadingSZ} />
                    </Grid>
                )} */}

                {isMunicipalita && !matches && (
                    <Grid size={{xs: 12, sm: 6, lg: 6, xl: 4}}>
                        <GlobalniKontaktyDistributoraWidget komodita={komodita} />
                    </Grid>
                )}

                {isMunicipalita && (
                    <Grid size={{xs: 12, sm: 6, lg: 6, xl: 4}}>
                        <NastenkaInvesticniStavbyWidget />
                    </Grid>
                )}
                {reklamaceElektrina && (
                    <Grid size={{xs: 12, sm: 6, lg: 6, xl: 4}}>
                        <ReklamaceElektrinaWidget />
                    </Grid>
                )}
                {zadostiElektrina && showSopPodpis && (
                    <Grid size={{xs: 12, md: 6}}>
                        <ZadostiElektrinaSopWidget loading={loadingSopElektrina} />
                    </Grid>
                )}
                {zadostiElektrina && (
                    <Grid size={{xs: 12, md: 12, sm: 12, lg: 12, xl: 6}}>
                        <ZadostiElektrinaWidget loading={loadingSopElektrina} />
                    </Grid>
                )}

                {reklamacePlyn && (
                    <Grid size={{xs: 12, sm: 6, md: 6, lg: 6, xl: 4}}>
                        <ReklamacePlynWidget />
                    </Grid>
                )}
                {zadostiPlyn && sopPodpisPlyn?.prepisAktivni && sopPodpisPlyn?.prepis > 0 && (
                    <Grid size={{xs: 12, md: 6, xl: 4}}>
                        <ZadostiPlynSopWidget loading={loadingSopPlyn} />
                    </Grid>
                )}
                {zadostiPlyn && (
                    <Grid size={{xs: 12, sm: 12, md: 12, xl: 8}}>
                        <ZadostiPlynWidget loading={loadingSopPlyn} />
                    </Grid>
                )}

                {zobrazitDodatky && (
                    <Grid size={{xs: 12, sm: 6, md: 3}}>
                        <DodatkyWidget loading={loadingDodatky} />
                    </Grid>
                )}
                {showKontakty && (
                    <Grid size={{xs: 12, sm: 6, lg: 6, xl: 4}}>
                        <KontaktyWidget loading={loadingNezvalidovaneKontakty} />
                    </Grid>
                )}

                {!isObchodnik && (
                    <Grid size={{xs: 12}}>
                        <OdbernaMistaWidget komodita={komodita} />
                    </Grid>
                )}
                {sppLoginAvailable && me.mobileLogin && komodita === Komodita.ELEKTRINA && (
                    <Grid size={{xs: 12, sm: 6, lg: 6, xl: 4}}>
                        <SppLoginWidget standAlone komodita={komodita} />
                    </Grid>
                )}
            </Grid>
        );
    }
    return null;
};

export default IndexPage;
