import {Komodita} from '@eon.cz/apollo13-graphql';
import {Switch} from '@mui/material';
import {ChangeEvent} from 'react';
import {KomoditaToggleIcon} from '../../../Komodita/components/KomoditaToggleIcon';
import {theme} from './theme';

type Props = {
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    plyn: boolean;
};

/**
 * Komponenta pro zobrazení přepínání mezi komoditami
 */
export const KomoditaSwitchBase = ({onChange, plyn}: Props) => {
    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(e);
    };

    return (
        <Switch
            sx={{
                '.MuiSwitch-switchBase': {
                    color: theme.palette.primary.main,
                    backgroundColor: 'transparent',
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                },
                '.MuiSwitch-track': {
                    mt: '2px',
                    backgroundColor: theme.palette.primary.main,
                    opacity: 0.5,
                },
                '.Mui-checked': {
                    color: theme.palette.primary.main,
                    transform: 'translateX(40%)',
                },
            }}
            disableRipple
            checked={plyn}
            onChange={handleOnChange}
            checkedIcon={<KomoditaToggleIcon type={plyn ? Komodita.PLYN : Komodita.ELEKTRINA} border />}
            icon={<KomoditaToggleIcon type={plyn ? Komodita.PLYN : Komodita.ELEKTRINA} border />}
            data-testid={`app-bar-komodita-button`}
        />
    );
};
