import {ApiDate} from '@eon.cz/apollo13-graphql';
import {format, isValid} from 'date-fns';

type DayTime = 'midnight' | 'beforeMidnight';

const dayTimeVariants = {
    midnight: 'T00:00:00Z',
    beforeMidnight: 'T23:59:59Z',
};

const CZECH_DATE_FORMAT_MATCHER = /^\d{1,2}.\d{1,2}.\d{4}$/;
/**
 * Funkce bere v potaz datum ve formátu ApiDate i ApiDateTime a odstraní v případě ApiDateTime čas a ponechá jen datum
 *
 * @param {ApiDate} date
 * @returns {string}
 */
export const apiDateToCzechFormat = (date: ApiDate): string => date?.split('T')[0].split('-').reverse().join('.');

/**
 * Převedení českého datumu na ApiDate
 * @param {string} date - datum, které se má převést
 */
export const czechDateToApiDate = (date: string): ApiDate | null => {
    const isValidCzechDate: boolean = CZECH_DATE_FORMAT_MATCHER.test(date);

    if (!isValidCzechDate || typeof date === 'undefined') {
        return null;
    }

    const [day, month, year] = date.split('.');

    return `${year}-${month}-${day}` as ApiDate;
};

export const toApiDate = (date: string | null | undefined): string | null =>
    typeof date === 'string' && isValid(new Date(date)) ? format(new Date(date), 'yyyy-MM-dd') : null;

/**
 * Funkce pro převod datumu s možností ovlivnit výstup vlastní podmínkou.
 * Součástí podmínky je vždy předpoklad,že datum musí být validní.
 *
 * @param {boolean} predicate
 * @param {string} datum
 */
export const toApiDateByPredicate = (predicate: boolean, datum: string | null | undefined): string | null =>
    predicate && typeof datum === 'string' && isValid(new Date(datum)) ? format(new Date(datum), 'yyyy-MM-dd') : null;

const toApiDateTime = (date: string | null | undefined, variant: DayTime = 'midnight') => {
    const dateFrom = toApiDate(date as string);
    return dateFrom ? `${dateFrom}${dayTimeVariants[variant]}` : null;
};

export const toApiDateTimeFrom = (dateFrom: string | null | undefined) => toApiDateTime(dateFrom);

export const toApiDateTimeTo = (dateFrom: string | null | undefined) => toApiDateTime(dateFrom, 'beforeMidnight');

export const parseDigits = (s: string | undefined) => (s?.match(/[\d]+/g) || []).join('');

/**
 * The `formatDate` extracts digits from it, and formats
 * them into a date string with dots.
 * @param {string} s - The `formatDate` function takes a string `s` as input and formats it into a date
 * string in the format `dd.mm.yyyy`.
 * @returns The `formatDate` function is returning a formatted date string. The function takes a string
 * `s` as input, parses the digits from the input string, splits the digits into an array of
 * characters, and then reduces the array to create a formatted date string. The formatted date string
 * includes periods ('.') inserted after the second and fourth characters to represent a date format.
 * The final result is a substring
 */
export const formatDate = (s: string) => {
    const digits = parseDigits(s);
    const chars = digits.split('');

    return chars.reduce((r, v, index) => (index === 1 || index === 3 ? `${r}${v}.` : `${r}${v}`), '').substring(0, 10);
};

export const OpositeDate = {
    DATUM_OD: 'datumOd',
    DATUM_DO: 'datumDo',
};

export type OpositeDate = (typeof OpositeDate)[keyof typeof OpositeDate];

/**
 * The function `validateDatumOdIsLowerOrEqualThenDatumDo` checks if the start date is lower or equal
 * to the end date and returns an error message if not.
 * @param {Date} datumFrom - The `datumFrom` parameter represents the starting date, while the
 * `datumTo` parameter represents the ending date. The function
 * `validateDatumOdIsLowerOrEqualThenDatumDo` checks if the starting date is lower than or equal to the
 * ending date. If the starting date is greater than the
 * @param {Date} datumTo - The `datumTo` parameter represents the end date or the date that should be
 * greater in value when comparing two dates. In the context of the
 * `validateDatumOdIsLowerOrEqualThenDatumDo` function, `datumTo` is the second date being compared to
 * `datumFrom` to ensure
 * @param {string} [customErrorMessage] - The `customErrorMessage` parameter in the
 * `validateDatumOdIsLowerOrEqualThenDatumDo` function allows you to provide a custom error message
 * that will be displayed if the validation fails. If you do not provide a custom error message, a
 * default message "Datum od musí být menší
 * @returns The function `validateDatumOdIsLowerOrEqualThenDatumDo` returns an object with two
 * properties:
 * - `message`: A string message indicating whether the validation passed or failed. If the validation
 * passes (i.e., `isDatumOdLowerOrEqualThenDatumDo` is true), this property is set to `undefined`. If
 * the validation fails, it contains an error message.
 * - `error
 */
export const validateDatumOdIsLowerOrEqualThenDatumDo = (datumFrom: Date, datumTo: Date, customErrorMessage?: string) => {
    if (datumFrom && datumTo) {
        const datumOd = new Date(datumFrom);
        const datumDo = new Date(datumTo);
        const isDatumOdLowerOrEqualThenDatumDo = datumOd.getTime() <= datumDo.getTime();
        const errorMessage = customErrorMessage ?? 'Datum od musí být menší než datum do';
        return {message: isDatumOdLowerOrEqualThenDatumDo ? undefined : errorMessage, error: !isDatumOdLowerOrEqualThenDatumDo};
    }
    return undefined;
};

type HolidayDate = {d: number; m: number};

/* The `holidayDates` constant is an array of objects representing specific dates and months that are
considered holidays in the Czech Republic. Each object in the array has properties `d` for the day
and `m` for the month of the holiday. These dates are used as reference points for calculating
holidays and determining if a given date falls on a holiday in the Czech Republic. */
const holidayDates: HolidayDate[] = [
    {d: 1, m: 1},
    {d: 1, m: 5},
    {d: 8, m: 5},
    {d: 5, m: 7},
    {d: 6, m: 7},
    {d: 28, m: 9},
    {d: 28, m: 10},
    {d: 17, m: 11},
    {d: 24, m: 12},
    {d: 25, m: 12},
    {d: 26, m: 12},
];

/**
 * The function `getEaster` calculates the date of Easter for a given year using a mathematical formula
 * based on the Gregorian calendar.
 * @param {number} year - The `getEaster` function you provided calculates the date of Easter for a
 * given year using the computus algorithm. The algorithm involves several mathematical calculations
 * based on the year input to determine the month and day of Easter.
 * @returns The function `getEaster` returns an object with properties `m` representing the month and
 * `d` representing the day of the month for Easter in the given `year`.
 */
const getEaster = (year: number): HolidayDate => {
    const f = Math.floor,
        // Golden Number - 1
        G = year % 19,
        C = f(year / 100),
        // related to Epact
        H = (C - f(C / 4) - f((8 * C + 13) / 25) + 19 * G + 15) % 30,
        // number of days from 21 March to the Paschal full moon
        I = H - f(H / 28) * (1 - f(29 / (H + 1)) * f((21 - G) / 11)),
        // weekday for the Paschal full moon
        J = (year + f(year / 4) + I + 2 - C + f(C / 4)) % 7,
        // number of days from 21 March to the Sunday on or before the Paschal full moon
        L = I - J,
        month = 3 + f((L + 40) / 44),
        day = L + 28 - 31 * f(month / 4);

    return {m: month, d: day};
};

/**
 * The function `czechHolidays` calculates the dates of Czech holidays for a given year, including
 * Easter Friday and Easter Monday.
 * @param {number} year - The function `czechHolidays` takes a `year` parameter of type number to
 * calculate the holidays for that specific year.
 * @returns The function `czechHolidays` returns an array of holiday dates for the specified year in
 * Czech Republic, including Easter Friday and Easter Monday.
 */
const czechHolidays = (year: number): HolidayDate[] => {
    const holidays: HolidayDate[] = [...holidayDates];
    const easterSunday = getEaster(year);
    const easterFridayDate = new Date(year, easterSunday.m - 1, easterSunday.d);
    easterFridayDate.setDate(easterFridayDate.getDate() - 2);
    const easterMondayDate = new Date(year, easterSunday.m - 1, easterSunday.d);
    easterMondayDate.setDate(easterMondayDate.getDate() + 1);

    holidays.push({
        d: easterFridayDate.getDate(),
        m: easterFridayDate.getMonth() + 1,
    });
    holidays.push({
        d: easterMondayDate.getDate(),
        m: easterMondayDate.getMonth() + 1,
    });

    holidays.sort((a, b) => a.m - b.m || a.d - b.d);

    return holidays;
};

/**
 * The function calculates a date based on a given date and number of days, excluding weekends and
 * holidays.
 * @param {Date} date - The `date` parameter is a Date object representing the starting date from which
 * you want to calculate the number of holidays or weekdays within a specified interval.
 * @param {number} days - The `days` parameter in the `howMatchHolidaysOrWeekDayIsInDateInterval`
 * function represents the number of days you want to move forward or backward from the given `date`.
 * Positive values of `days` will move the date forward in time, while negative values will move the
 * date
 * @returns The function `howMatchHolidaysOrWeekDayIsInDateInterval` returns the last date within the
 * specified date interval that is not a Sunday, Saturday, or a holiday in the Czech Republic.
 */
export const howMatchHolidaysOrWeekDayIsInDateInterval = (date: Date, days: number) => {
    const year = new Date().getFullYear();
    let time = date.getTime();
    const holidays = czechHolidays(year);
    const sign = Math.sign(days);
    let count = Math.abs(days);
    let lastDate = date;
    if (count > 0) {
        do {
            time += sign * 24 * 60 * 60 * 1000;
            lastDate = new Date(time);
            const isSunday = lastDate.getDay() === 0;
            const isSaturday = lastDate.getDay() === 6;
            const isHolliday = holidays.find((holiday) => holiday.d === lastDate.getDate() && holiday.m === lastDate.getMonth() + 1) !== undefined;

            if (!isSunday && !isSaturday && !isHolliday) {
                count--;
            }
        } while (count > 0);
    }

    return lastDate;
};
