import {ElectricityIcon} from '@eon.cz/apollo13-frontend-common';
import {Komodita} from '@eon.cz/apollo13-graphql';
import {WhatshotOutlined} from '@mui/icons-material';
import {theme} from '../../Common/components/layout/theme';

type Props = {
    type: Komodita;
    border?: boolean;
};

/**
 * Zobrazení ikony pro danou komoditu
 */
export const KomoditaToggleIcon = ({type, border}: Props) => {
    return type === Komodita.PLYN ? (
        <WhatshotOutlined
            sx={{
                padding: 0.2,
                borderRadius: 50,
                backgroundColor: theme.palette.common.white,
                fontSize: 24,
                boxShadow: theme.shadows[3],
                border: border ? `1px solid ${theme.palette.primary.main}` : 'none',
                color: theme.palette.primary.main,
            }}
        />
    ) : (
        <ElectricityIcon
            width={24}
            height={24}
            sx={{
                padding: 0.2,
                borderRadius: 50,
                backgroundColor: theme.palette.common.white,
                fontSize: 24,
                boxShadow: theme.shadows[3],
                border: border ? `1px solid ${theme.palette.primary.main}` : 'none',
            }}
        />
    );
};
