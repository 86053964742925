import {
    BezproudiNastenka,
    Maybe,
    OdstavkaElektrina,
    OdstavkyZakaznikFilter,
    PoruchaElektrina,
    PoruchaElektrinaFilter,
    TerminOdstavkaElektrinaStav,
    TerminyOdstavkaElektrinaFilter,
} from '@eon.cz/apollo13-graphql';
import {Cancel as CancelIcon} from '@mui/icons-material';
import isEqual from 'lodash/isEqual';
import {ReactNode} from 'react';
import {FormatDateOptions, IntlShape} from 'react-intl';
import {Lang} from '../../../Lang';
import {ActiveStatus, EXPORT_DATUM_CAS_FORMAT, ExportService, IconWithTooltip} from '../../Common';
import {BEZPROUDI_ODSTAVKY_VSE, BezproudiOdstavkyFilterModel} from '../model/BezproudiOdstavkyFilterModel';
import {BezproudiOdstavkyZakaznikFilterModel} from '../model/BezproudiOdstavkyZakaznikFilterModel';
import {BezproudiPoruchyFilterModel} from '../model/BezproudiPoruchyFilterModel';

const DATE_TIME_FORMAT: FormatDateOptions = {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
};

export const BEZPROUDI_ODSTAVKY_FILTER_FORM_NAME = 'BEZPROUDI_ODSTAVKY_FILTER_FORM_NAME';
export const BEZPROUDI_ODSTAVKY_ZAKAZNIK_FILTER_FORM_NAME = 'BEZPROUDI_ODSTAVKY_FILTER_FORM_NAME';
export const BEZPROUDI_PORUCHY_FILTER_FORM_NAME = 'BEZPROUDI_PORUCHY_FILTER_FORM_NAME';

const EXPORT_ODSTAVKY_FILENAME = 'odstavka.xlsx';
const EXPORT_PORUCHY_FILENAME = 'porucha.xlsx';

/**
 * Zkonvertuje filtr do formátu vyžadovaného graphql
 */
export const mapToSearchOdstavkyFiltratioGraphqlModel = (filter: BezproudiOdstavkyFilterModel): TerminyOdstavkaElektrinaFilter => {
    return {
        datumOd: filter?.datumOd !== '' ? filter.datumOd : null,
        datumDo: filter?.datumDo !== '' ? filter.datumDo : null,
        fulltextAdresa: filter.adresa !== '' ? filter.adresa : null,
        stav: filter.stav === BEZPROUDI_ODSTAVKY_VSE ? null : filter.stav,
    };
};
/**
 * Zkonvertuje filtr do formátu vyžadovaného graphql
 */
export const mapToSearchOdstavkyZakaznikFiltratioGraphqlModel = (filter: BezproudiOdstavkyZakaznikFilterModel): OdstavkyZakaznikFilter => {
    return {
        datumOd: filter?.datumOd !== '' ? filter.datumOd : null,
        datumDo: filter?.datumDo !== '' ? filter.datumDo : null,
        fulltextAdresa: filter.adresa !== '' ? filter.adresa : null,
        fulltextEan: filter.ean !== '' ? filter.ean : null,
        fulltextNazev: filter.nazev !== '' ? filter.nazev : null,
        stav: filter.stav === BEZPROUDI_ODSTAVKY_VSE ? null : filter.stav,
    };
};

/**
 * Zkonvertuje filtr do formátu vyžadovaného graphql
 */
export const mapToSearchPoruchyFiltratioGraphqlModel = (filter: BezproudiPoruchyFilterModel): PoruchaElektrinaFilter => {
    return {
        kalamita: filter.kalamita,
        fulltextAdresa: filter.adresa !== '' ? filter.adresa : null,
    };
};

/**
 * Vrátí, zda je filtr aktivní
 */
export const isSearchFilterActive = (
    initialFilter: BezproudiOdstavkyFilterModel | BezproudiPoruchyFilterModel,
    currentFilter: BezproudiOdstavkyFilterModel | BezproudiPoruchyFilterModel,
): boolean => !isEqual(initialFilter, currentFilter);

export const getOdstavkaStav = (): Array<TerminOdstavkaElektrinaStav | 'vse'> => [BEZPROUDI_ODSTAVKY_VSE, ...Object.values(TerminOdstavkaElektrinaStav)];

export const getOdstavkaStavIcon = (typ: TerminOdstavkaElektrinaStav): ReactNode => {
    switch (typ) {
        case TerminOdstavkaElektrinaStav.AKTIVNI:
            return <IconWithTooltip title={Lang.BEZPROUDI_STAV_AKTIVNI} icon={<ActiveStatus active={true} />} />;
        case TerminOdstavkaElektrinaStav.STORNOVANA:
            return <IconWithTooltip title={Lang.BEZPROUDI_STAV_STORNOVANA} icon={<CancelIcon color="error" />} />;
    }
};

// Funkce pro sestavení dat do exportu souboru
export const exportOdstavka = (odstavka: OdstavkaElektrina | null, intl: IntlShape) => {
    // Sestavení jednotlivých řádků - nadpisy,prázdé řádky a řádky s hodnotami
    const emptyRow: Array<any> = [];
    const popisTabulky = [intl.formatMessage({id: Lang.BEZPROUDI_XLSX_NADPIS1})];
    const popisOm = [intl.formatMessage({id: Lang.BEZPROUDI_XLSX_NADPIS2})];
    const row0 = [intl.formatMessage({id: Lang.BEZPROUDI_CISLO}), odstavka?.cislo ?? ''];
    const row1 = [intl.formatMessage({id: Lang.BEZPROUDI_POPIS}), odstavka?.popis ?? ''];
    const row2 = [intl.formatMessage({id: Lang.BEZPROUDI_STAV})];
    const row3 = [intl.formatMessage({id: Lang.BEZPROUDI_PREHLED_TERMINU})];

    // naplnění řádku se stavem
    odstavka?.terminy?.forEach((termin) => {
        row2.push(termin?.stav ?? '');
    });

    // naplnění řádku s termíny
    odstavka?.terminy.forEach((termin) => {
        row3.push(`${intl.formatDate(termin?.datumCasOd, DATE_TIME_FORMAT)} - ${intl.formatDate(termin?.datumCasDo, DATE_TIME_FORMAT)}`);
    });

    const headerOmPopis = [
        Lang.BEZPROUDI_OBEC,
        Lang.BEZPROUDI_CAST_OBCE,
        Lang.BEZPROUDI_ULICE,
        Lang.BEZPROUDI_CISLO_POPISNE,
        Lang.BEZPROUDI_CISLO_ORIENTACNI,
        Lang.BEZPROUDI_CISLO_EVIDENCNI,
        Lang.BEZPROUDI_PSC,
        Lang.BEZPROUDI_POCET_OM,
    ];

    // Zkonvertujeme id hlaviček na řetězce
    const omHeader = headerOmPopis.map((id) => intl.formatMessage({id}));

    // naplnění každého řádku jednou adresou, rozdělenou po buňkách
    const omValue = odstavka?.adresy?.map((adresa) => {
        const valueRorOm: Array<string | number> = [];
        valueRorOm.push(
            adresa?.obec ?? '',
            adresa?.castObce ?? '',
            adresa?.ulice ?? '',
            adresa?.cisloPopisne ?? '',
            adresa?.cisloOrientacni ?? '',
            adresa?.cisloEvidencni ?? '',
            adresa?.psc ?? '',
            adresa?.pocetOm ?? 0,
        );
        return valueRorOm;
    });

    // Spojíme hlavičku a řádky
    const data = [popisTabulky, row0, row1, row2, row3, emptyRow, popisOm, omHeader, ...(omValue ?? [])];

    /* Zavoláme export ze sestavených dat */
    ExportService.exportArrayOfArraysAsXLSX(data, {
        // název souboru
        filename: EXPORT_ODSTAVKY_FILENAME,
        // hlavičky
        // headers: headers,
        // formát datumu a času
        dateFormat: EXPORT_DATUM_CAS_FORMAT,
        // Informace o sloupcích
        columnInfo: ExportService.computeColumnSizes(data),
    });
};

// Funkce pro sestavení dat do exportu souboru
export const exportPorucha = (porucha: PoruchaElektrina | null, intl: IntlShape) => {
    // Sestavení jednotlivých řádků - nadpisy,prázdé řádky a řádky s hodnotami
    const emptyRow: Array<any> = [];
    const popisTabulky = [intl.formatMessage({id: Lang.BEZPROUDI_PORUCHY_XLSX_NADPIS1})];
    const popisOm = [intl.formatMessage({id: Lang.BEZPROUDI_XLSX_NADPIS2})];
    const row0 = [intl.formatMessage({id: Lang.BEZPROUDI_CISLO}), porucha?.cislo ?? ''];
    const row1 = [intl.formatMessage({id: Lang.BEZPROUDI_PORUCHY_CAS_VZNIKU})];
    const row2 = [intl.formatMessage({id: Lang.BEZPROUDI_PORUCHY_PREDPOKLADANY_CAS})];
    const row3 = [intl.formatMessage({id: Lang.BEZPROUDI_PORUCHY_KALAMITA})];

    // naplnění řádku s termíny
    row1.push(`${intl.formatDate(porucha?.datumCasOd, DATE_TIME_FORMAT)}`);
    row2.push(`${intl.formatDate(porucha?.datumCasDo, DATE_TIME_FORMAT)}`);
    row3.push(intl.formatMessage({id: porucha?.kalamita ? Lang.ANO : Lang.NE}));

    const headerOmPopis = [
        Lang.BEZPROUDI_OBEC,
        Lang.BEZPROUDI_CAST_OBCE,
        Lang.BEZPROUDI_ULICE,
        Lang.BEZPROUDI_CISLO_POPISNE,
        Lang.BEZPROUDI_CISLO_ORIENTACNI,
        Lang.BEZPROUDI_CISLO_EVIDENCNI,
        Lang.BEZPROUDI_PSC,
        Lang.BEZPROUDI_POCET_OM,
    ];

    // Zkonvertujeme id hlaviček na řetězce
    const omHeader = headerOmPopis.map((id) => intl.formatMessage({id}));

    // naplnění každého řádku jednou adresou, rozdělenou po buňkách
    const omValue = porucha?.adresy?.map((adresa) => {
        const valueRorOm: Array<string | number> = [];
        valueRorOm.push(
            adresa?.obec ?? '',
            adresa?.castObce ?? '',
            adresa?.ulice ?? '',
            adresa?.cisloPopisne ?? '',
            adresa?.cisloOrientacni ?? '',
            adresa?.cisloEvidencni ?? '',
            adresa?.psc ?? '',
            adresa?.pocetOm ?? 0,
        );
        return valueRorOm;
    });

    // Spojíme hlavičku a řádky
    const data = [popisTabulky, row0, row1, row2, row3, emptyRow, popisOm, omHeader, ...(omValue ?? [])];

    /* Zavoláme export ze sestavených dat */
    ExportService.exportArrayOfArraysAsXLSX(data, {
        // název souboru
        filename: EXPORT_PORUCHY_FILENAME,
        // hlavičky
        // headers: headers,
        // formát datumu a času
        dateFormat: EXPORT_DATUM_CAS_FORMAT,
        // Informace o sloupcích
        columnInfo: ExportService.computeColumnSizes(data),
    });
};

export const showBezproudiWidget = (bezproudi: Maybe<BezproudiNastenka> | undefined) => {
    const isPorucha = bezproudi?.poruchy?.evidovanaPorucha || bezproudi?.poruchy?.evidovanaPoruchaVypadekOm;
    const isOdstavka = bezproudi?.odstavky?.zaplanovanaOdstavkaMunicipalita || bezproudi?.odstavky?.zaplanovanaOdstavkaZakaznik;
    return isPorucha || isOdstavka;
};
