import {
    ApiDate,
    HistorieSpotrebTypPristroje,
    Komodita,
    Maybe,
    OdberneMistoElektrinaHistorieSpotrebHodnota,
    OdberneMistoElektrinaHistorieSpotrebPolozka,
    OdberneMistoPlynHistorieSpotrebHodnota,
    OdberneMistoPlynHistorieSpotrebPolozka,
    RoleProfilElektrina,
    RoleProfilPlyn,
    SmlouvaOdbernehoMistaKategorieMereni,
    SmlouvaOdbernehoMistaStav,
    SmlouvaOdbernehoMistaTypMereni,
    StatusHistorieSpotrebElektrina,
    TypDiagramuHistorieSpotrebElektrina,
    TypDiagramuHistorieSpotrebPlyn,
    TypStatusuHistorieSpotrebElektrina,
} from '@eon.cz/apollo13-graphql';
import {Link, Typography} from '@mui/material';
import 'core-js/features/array/to-sorted';
import {addDays, addMonths, endOfMonth, format, isAfter, isBefore, startOfMonth, subDays, subMonths} from 'date-fns';
import {uniqBy} from 'lodash';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import isEqual from 'lodash/isEqual';
import {ReactNode} from 'react';
import {FormattedMessage, IntlShape} from 'react-intl';
import {Lang} from '../../../Lang';
import {PageRoute, RouteService} from '../../Common';
import {TooltipIcon} from '../../Common/components/layout/TooltipIcon';
import {toApiDate} from '../../Utils';
import {OMHistorieSpotrebFilterArgs} from '../actions';
import {OMHistorieSpotrebFilterModel} from '../model';

const PREFIX_FORM_NAME = 'OM_HISTORIE_SPOTREB';

export const EXPORT_HISTORIE_SPOTREB_FILENAME_ELEKTRINA = 'historie-spotreb-elektrina.xlsx';
export const EXPORT_HISTORIE_SPOTREB_FILENAME_PLYN = 'historie-spotreb-plyn.xlsx';
export const TYP_DIAGRAMU_DENNI = 'denni';

export const HistorieSpotrebHodnotaStatusTyp = {
    F: 'F',
    G: 'G',
    N: 'N',
    M: 'M',
    W: 'W',
    E: 'E',
    V: 'V',
    B: 'B',
} as const;

export type HistorieSpotrebHodnotaStatusTyp = (typeof HistorieSpotrebHodnotaStatusTyp)[keyof typeof HistorieSpotrebHodnotaStatusTyp];

export const OMHistorieSpotrebService = {
    FILTER_FORM_NAME: `${PREFIX_FORM_NAME}_FILTER_FORM_NAME`,

    // mapování hodnot na model pro filtraci historie spotřeb
    remapGraphQLFilterToFormModel({idPristroje, typDiagramu, datumOd, datumDo}: OMHistorieSpotrebFilterArgs): OMHistorieSpotrebFilterModel {
        return {
            idPristroje: !!idPristroje ? idPristroje : '',
            typDiagramu: !!typDiagramu ? typDiagramu : '',
            datumOd: toApiDate(datumOd),
            datumDo: toApiDate(datumDo),
        };
    },

    // Nastavení datumu podle stavu smlouvy
    getDatumOdByStav(stav: SmlouvaOdbernehoMistaStav, datumOd: ApiDate, datumPristrojeOd?: ApiDate, datumPristrojeDo?: ApiDate) {
        const after = datumPristrojeDo && isAfter(new Date(datumPristrojeDo), endOfMonth(subMonths(new Date(), 1)));
        const datumByStav =
            !!stav && stav === SmlouvaOdbernehoMistaStav.AKTIVNI
                ? format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
                : format(subMonths(new Date(datumOd), 1), 'yyyy-MM-dd');
        return !after ? (datumPristrojeOd ?? format(startOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')) : datumByStav;
    },

    // Nastavení datumu podle stavu smlouvy
    getDatumDoByStav(stav: SmlouvaOdbernehoMistaStav, datumDo: ApiDate, datumPristrojeDo?: ApiDate, datumPristrojeOd?: ApiDate) {
        const before = datumPristrojeDo && isBefore(new Date(datumPristrojeDo), new Date(datumDo));
        const datumByStav =
            !!stav && stav === SmlouvaOdbernehoMistaStav.AKTIVNI
                ? format(endOfMonth(subMonths(new Date(), 1)), 'yyyy-MM-dd')
                : format(endOfMonth(subMonths(new Date(datumDo), 1)), 'yyyy-MM-dd');
        return before ? format(subDays(addMonths(new Date(datumPristrojeOd), 1), 1), 'yyyy-MM-dd') : datumByStav;
    },

    // Kontrola možnosti resetu filtru
    isFilterActive: (filter: OMHistorieSpotrebFilterArgs, initialQueryArgs: OMHistorieSpotrebFilterArgs) => {
        return !isEqual(filter, initialQueryArgs);
    },

    validateHodinovy: (datumOdHodinovy: Date, datumDoHodinovy: Date, intl: IntlShape) => {
        const nowPlusOneYear = new Date(datumOdHodinovy);
        nowPlusOneYear.setFullYear(datumOdHodinovy.getFullYear() + 1);

        if (!(nowPlusOneYear > datumDoHodinovy)) {
            return {field: 'datumOd', message: intl.formatMessage({id: Lang.ODBERNA_MISTA_WARNING}, {obdobi: 'rok'})};
        }
        if (!(datumOdHodinovy.getTime() <= datumDoHodinovy.getTime())) {
            return {field: 'datumDo', message: intl.formatMessage({id: Lang.ODBERNA_MISTA_WARNING}, {obdobi: 'rok'})};
        }
    },

    validateFutureDatumDo: (datumDo: Date, intl: IntlShape) => {
        const validateFuture = datumDo && isAfter(new Date(datumDo), addDays(new Date(), -1));

        if (validateFuture) {
            return {field: 'datumDo', message: intl.formatMessage({id: Lang.ODBERNA_MISTA_WARNING_FUTURE})};
        }
    },
};
// Výsledkem je asociativní mapa kde klíčem je role profilu,která obsahuje vlastnosti pro daný profil
const transformDataToRoleProfiluMap = <T extends Array<TypDiagramuHistorieSpotrebElektrina | TypDiagramuHistorieSpotrebPlyn>>(data: T) => {
    // Vytvoření pole s profily dodávek a spotřeb - očištění od propert nastavení
    const ret = data?.reduce(
        (prev, current) => {
            if (current.hasOwnProperty('profilyDodavky')) {
                const picked = ((item) => ({
                    profilySpotreby: item.profilySpotreby,
                    profilyDodavky: 'profilyDodavky' in item ? item.profilyDodavky : undefined,
                }))(current);
                return [...prev, picked];
            } else {
                const picked = (({profilySpotreby}) => ({profilySpotreby}))(current);
                return [...prev, picked];
            }
        },
        [] as Array<{
            profilySpotreby: Array<RoleProfilElektrina | RoleProfilPlyn>;
            profilyDodavky?: Array<RoleProfilElektrina> | undefined;
        }>,
    );
    // Spojení polí profilySpotreby a profilyDodavky a přemapování na objekt s klíči.Každý klíč obsahuje informace o daném profilu
    return ret
        ?.reduce(
            (prevItem, currentItem) => {
                return Object.values(currentItem).reduce((prevSubItem, currentSubItem) => {
                    return Object.values(currentSubItem ?? {}).reduce((prevSub2, currentSub2) => {
                        return [...prevSub2, currentSub2];
                    }, prevSubItem);
                }, prevItem);
            },
            [] as Array<RoleProfilElektrina | RoleProfilPlyn>,
        )
        .reduce(
            (sum, args) => ({
                ...sum,
                [args.nazev]: args,
            }),
            {} as Record<string, RoleProfilElektrina | RoleProfilPlyn>,
        );
};

const transformDataToTypyDiagramuMap = (data: Array<TypDiagramuHistorieSpotrebElektrina | TypDiagramuHistorieSpotrebPlyn>) => {
    return data.reduce((prev, current) => Object.assign({}, prev, {[current.id]: {id: current.id, klic: current.klic, nazev: current.nazev}}), {});
};

export type HistorieSpotrebPolozka = OdberneMistoElektrinaHistorieSpotrebPolozka | OdberneMistoPlynHistorieSpotrebPolozka;
export type HistorieSpotrebEnhancedData = {
    columnData: string[] | ReactNode[] | undefined;
    tabularData: (ReactNode[] | undefined)[] | undefined;
};

export const getRoleProfiluMap = <T extends TypDiagramuHistorieSpotrebElektrina | TypDiagramuHistorieSpotrebPlyn>(roleProfilu: Array<T>) => {
    return transformDataToRoleProfiluMap(roleProfilu);
};

export const getTypyDiagramuMap = (typyDiagramu: Array<TypDiagramuHistorieSpotrebElektrina | TypDiagramuHistorieSpotrebPlyn>) => {
    return transformDataToTypyDiagramuMap(typyDiagramu);
};

/**
 *
 * @param array - Pole,které chceme přetřídit
 * @param order - dle čeho budeme třídit
 * @param key - klíčové slovo,podle kterého budeme třídit
 */
export const getSortedHistorie = (array: OdberneMistoElektrinaHistorieSpotrebPolozka[] | undefined, order: Array<string>, key: 'roleProfilu') => {
    const arraySorted = array && array?.length > 0 ? [...array] : [];
    return arraySorted.sort((a, b) => {
        const aKey = a[key];
        const bKey = b[key];
        if (typeof aKey === 'string' && typeof bKey === 'string') {
            return order.indexOf(aKey) - order.indexOf(bKey);
        }
        return 0;
    });
};

const prepareDataMapElektrina = (rawData: OdberneMistoElektrinaHistorieSpotrebPolozka[] | undefined): Map<string, Map<string, number | undefined>> => {
    // Map<roleProfilu, Map<datumCas, hodnota>>
    const dataMap: Map<string, Map<string, number | undefined>> = new Map<string, Map<string, number | undefined>>();
    rawData?.map((rawItem) => {
        const hodnoty: Map<string, number | undefined> = new Map<string, number | undefined>();
        rawItem.hodnoty.map((hodnota) => {
            hodnoty.set(hodnota.datumCasOd, hodnota.hodnota);
        });
        dataMap.set(rawItem.roleProfilu, hodnoty);
    });
    return dataMap;
};
const prepareDataMapPlyn = (rawData: OdberneMistoPlynHistorieSpotrebPolozka[] | undefined): Map<string, Map<string, number | undefined>> => {
    // Map<roleProfilu, Map<datumCas, hodnota>>
    const dataMap: Map<string, Map<string, number | undefined>> = new Map<string, Map<string, number | undefined>>();
    rawData?.map((rawItem) => {
        const hodnoty: Map<string, number | undefined> = new Map<string, number | undefined>();
        rawItem.hodnoty.map((hodnota) => {
            hodnoty.set(hodnota.datumCas, hodnota.hodnota);
        });
        dataMap.set(rawItem.roleProfilu, hodnoty);
    });
    return dataMap;
};
/**
 * @param rawData
 * @param {any} nastaveni - data role profilů podle typy diagramu
 * @param {string} typDiagramu - ID vybraného typu diagramu
 */
export const transformToTabularDataElektrina = (rawData: OdberneMistoElektrinaHistorieSpotrebPolozka[] | undefined, nastaveni: any, typDiagramu: string) => {
    // get current roleProfiluMap
    const roleProfiluMap = getRoleProfiluMap(nastaveni);
    const typyDiagramuMap = getTypyDiagramuMap(nastaveni) as Record<string, {id: string; klic: string; nazev: string}>;

    const isDenniDiagram = typyDiagramuMap[typDiagramu].klic === TYP_DIAGRAMU_DENNI;
    const defaultProps = isDenniDiagram ? ['Datum'] : ['Datum', 'Interval'];

    // extract column data
    const columnData = rawData?.reduce(
        (prev: string[], current: HistorieSpotrebPolozka): string[] => [
            ...prev,
            `${roleProfiluMap[current.roleProfilu].popis} (${roleProfiluMap[current.roleProfilu].mernaJednotka.nazev})`,
        ],
        defaultProps,
    );
    // extract rows only for date and time by the longest array of values
    // some arrays might be empty so we need the longest one to extract all dates
    // extract all dates from all rows because longest array does not have to contain all dates
    const tabularData = preprareDataForExportElektrina(rawData, isDenniDiagram);

    // V tabulce budou vždy data řádků s hodnotami
    return {columnData, tabularData};
};
export const transformToTabularDataPlyn = (rawData: OdberneMistoPlynHistorieSpotrebPolozka[] | undefined, nastaveni: any, typDiagramu: string) => {
    // get current roleProfiluMap
    const roleProfiluMap = getRoleProfiluMap(nastaveni);
    const typyDiagramuMap = getTypyDiagramuMap(nastaveni) as Record<string, {id: string; klic: string; nazev: string}>;

    const isDenniDiagram = typyDiagramuMap[typDiagramu].klic === TYP_DIAGRAMU_DENNI;
    const defaultProps = isDenniDiagram ? ['Datum'] : ['Datum', 'Čas'];

    // extract column data
    const columnData = rawData?.reduce(
        (prev: string[], current: HistorieSpotrebPolozka): string[] => [
            ...prev,
            `${roleProfiluMap[current.roleProfilu].popis} (${roleProfiluMap[current.roleProfilu].mernaJednotka.nazev})`,
        ],
        defaultProps,
    );
    // extract rows only for date and time by the longest array of values
    // some arrays might be empty so we need the longest one to extract all dates
    // extract all dates from all rows because longest array does not have to contain all dates
    const tabularData = preprareDataForExportPlyn(rawData, isDenniDiagram);

    // V tabulce budou vždy data řádků s hodnotami
    return {columnData, tabularData};
};
export const transformDataStatistika = (
    rawData: OdberneMistoElektrinaHistorieSpotrebPolozka[],
    nastaveni: TypDiagramuHistorieSpotrebElektrina[],
    typDiagramu: string,
    komodita: Komodita | null | undefined,
    id: string,
    handleIndex: (index: number) => () => void,
) => {
    const roleProfiluMap = getRoleProfiluMap(nastaveni);
    const defaultProps = [
        <b key="celkem">
            <FormattedMessage id={Lang.ODBERNA_MISTA_STATISTIKA_CELKEM} />
        </b>,
    ];

    const hodinovyInterval = nastaveni.filter((interval) => interval.id === typDiagramu && interval.klic.toLowerCase().includes('ctvrthodinovy')).length === 0;
    const delitel = hodinovyInterval ? 1 : 4;

    const columnData = rawData?.reduce(
        (prev, current: HistorieSpotrebPolozka, index) => [
            ...prev,
            <TooltipIcon key={index} textRaw={roleProfiluMap[current.roleProfilu]?.tooltip ?? ''} showIcon placement="top-start">
                {`${roleProfiluMap[current.roleProfilu].popis} (${roleProfiluMap[current.roleProfilu].mernaJednotka.nazev})`}
            </TooltipIcon>,
        ],
        defaultProps,
    );

    const SouhrnJednotky = () => <FormattedMessage id={Lang.ODBERNA_MISTA_HISTORIE_SPOTREB_CELKEM_UNIT} />;
    // Pro sloupec Celkem navíc přidá tučně zvýraznění
    const defaultSumProps = [
        <b key="souhrn">
            <TooltipIcon
                htmlText={
                    <Typography variant="body2">
                        <FormattedMessage id={Lang.ODBERNA_MISTA_STATISTIKA_TOOLTIP} />
                    </Typography>
                }
            >
                <span>
                    <FormattedMessage id={Lang.ODBERNA_MISTA_HISTORIE_SPOTREB_CELKEM} values={{unit: <SouhrnJednotky />}} />
                </span>
            </TooltipIcon>
        </b>,
    ];
    const defaultMinProps = [<FormattedMessage key="min" id={Lang.ODBERNA_MISTA_HISTORIE_SPOTREB_MIN} />];
    const defaultMaxProps = [<FormattedMessage key="max" id={Lang.ODBERNA_MISTA_HISTORIE_SPOTREB_MAX} />];

    // Vyrobí řádek souhrnu, kde nejprve do pole přidá sloupec 'Celkem' a pak hodnoty součtů pro jednotlivé profily vydělených 4
    const sumRow = [
        [
            ...defaultSumProps,
            ...rawData
                .map((historiePolozka) => Number(historiePolozka.hodnoty.reduce((p, c) => p + c.hodnota, 0).toFixed(2)) / delitel)
                .map((polozka) => polozka.toString().replace(/\./g, ',')),
        ],
    ];

    // Vyrobí řádek s minimálními hodnotami, kde nejprve do pole přidá sloupec 'Minimum' a pak minimální hodnoty pro jednotlivé profily
    const minRow = [
        [
            ...defaultMinProps,
            ...rawData.map((historiePolozka) => {
                // pokud jsou hodnoty prázdné pole, tak není co brát a minimum je vždy 0
                const minPolozka =
                    historiePolozka.hodnoty.length > 0 ? historiePolozka.hodnoty.reduce((p, c) => (p.hodnota < c.hodnota ? p : c)) : {hodnota: 0};
                return minPolozka.hodnota;
            }),
        ],
    ];
    // Vyrobí řádek s maximálními hodnotami, kde nejprve do pole přidá sloupec 'Maximum' a pak maximální hodnoty pro jednotlivé profily
    const maxRow = [
        [
            ...defaultMaxProps,
            ...rawData?.map((historiePolozka, indexData) => {
                if (historiePolozka.hodnoty.length > 0) {
                    // pokud jsou hodnoty prázdné pole, tak není co brát a maximum je vždy 0
                    const index = findIndex(historiePolozka.hodnoty, [
                        'hodnota',
                        historiePolozka.hodnoty.length
                            ? Math.max(
                                  historiePolozka.hodnoty.reduce((a, b) => (a.hodnota > b.hodnota ? a : b), {
                                      hodnota: 0,
                                      datumCas: format(new Date(), 'd.M.yyyy'),
                                  } as OdberneMistoElektrinaHistorieSpotrebHodnota | OdberneMistoPlynHistorieSpotrebHodnota).hodnota,
                              )
                            : 0,
                    ]);
                    const maxPolozka =
                        historiePolozka.hodnoty.length > 0
                            ? historiePolozka.hodnoty.reduce((p, c): any => (p.hodnota > c.hodnota ? p : c), {
                                  hodnota: 0,
                                  datumCas: format(new Date(), 'd.M.yyyy'),
                              } as OdberneMistoElektrinaHistorieSpotrebHodnota | OdberneMistoPlynHistorieSpotrebHodnota)
                            : {hodnota: 0};
                    return (
                        <TooltipIcon
                            key={`tooltip-${indexData}`}
                            textRaw={format(
                                new Date(
                                    find(historiePolozka.hodnoty, [
                                        'hodnota',
                                        historiePolozka.hodnoty.length
                                            ? Math.max(
                                                  historiePolozka.hodnoty.reduce((a, b) => (a.hodnota > b.hodnota ? a : b), {
                                                      hodnota: 0,
                                                      datumCas: format(new Date(), 'd.M.yyyy'),
                                                  } as OdberneMistoElektrinaHistorieSpotrebHodnota | OdberneMistoPlynHistorieSpotrebHodnota).hodnota,
                                              )
                                            : 0,
                                    ])?.datumCasDo,
                                ),
                                "'Dne 'd.M.yyyy' v čase 'HH:mm",
                            )}
                        >
                            <Link
                                href={RouteService.getPathname(PageRoute.SMLOUVY_ODBERNYCH_MIST, `/detail/${id}#${index}`, komodita)}
                                onClick={handleIndex(index)}
                            >
                                {maxPolozka.hodnota}
                            </Link>
                        </TooltipIcon>
                    );
                } else {
                    return null;
                }
            }),
        ],
    ];

    const tabularData = [...sumRow, ...minRow, ...maxRow];

    return {columnData, tabularData};
};

/**
 * @param {HistorieSpotrebData} rawData - data obsahující hodnoty historie spotřeb
 * @param {any} nastaveni - data role profilů podle typu diagramu
 * @param {string} typDiagramu - ID vybraného typu diagramu
 */
export const transformToXLSXDataElektrina = (
    rawData: OdberneMistoElektrinaHistorieSpotrebPolozka[] | undefined,
    nastaveni: any,
    typDiagramu: string,
    statusy: Maybe<StatusHistorieSpotrebElektrina>[] | undefined,
): HistorieSpotrebEnhancedData => {
    // Získá aktuální mapu rolí profilů
    const roleProfiluMap = getRoleProfiluMap(nastaveni);
    // Získá mapu typů diagramů
    const typyDiagramuMap = getTypyDiagramuMap(nastaveni) as Record<string, {id: string; klic: string; nazev: string}>;

    // Kontroluje, zda je vybrán typ diagramu Denní
    const isDenniDiagram = typyDiagramuMap[typDiagramu].klic === TYP_DIAGRAMU_DENNI;

    // Nastaví textaci pro první sloupec tabulky
    const defaultProps = ['Datum', 'Interval'];

    // Vytvoří sloupce tabulky
    const columnData = rawData?.reduce(
        (prev: string[], current: HistorieSpotrebPolozka): string[] => [
            ...prev,
            `${roleProfiluMap[current.roleProfilu].popis} (${roleProfiluMap[current.roleProfilu].mernaJednotka.nazev})`,
            'Status',
        ],
        defaultProps,
    );

    // Naplní řádky tabulky daty do příslušných sloupců, a prázdné hodnoty nahradí '-'
    const tabularData = preprareDataForExportElektrina(rawData, isDenniDiagram, true, statusy);

    // Vrátí hlavičku (columnData) a řádky tabulky (tabularData)
    return {columnData, tabularData};
};
export const transformToXLSXDataPlyn = (
    rawData: OdberneMistoPlynHistorieSpotrebPolozka[] | undefined,
    nastaveni: any,
    typDiagramu: string,
    statusy: Maybe<StatusHistorieSpotrebElektrina>[] | undefined,
): HistorieSpotrebEnhancedData => {
    // Získá aktuální mapu rolí profilů
    const roleProfiluMap = getRoleProfiluMap(nastaveni);
    // Získá mapu typů diagramů
    const typyDiagramuMap = getTypyDiagramuMap(nastaveni) as Record<string, {id: string; klic: string; nazev: string}>;

    // Kontroluje, zda je vybrán typ diagramu Denní
    const isDenniDiagram = typyDiagramuMap[typDiagramu].klic === TYP_DIAGRAMU_DENNI;

    // Nastaví textaci pro první sloupec tabulky
    const defaultProps = isDenniDiagram ? ['Datum'] : ['Datum a čas'];

    // Vytvoří sloupce tabulky
    const columnData = rawData?.reduce(
        (prev: string[], current: HistorieSpotrebPolozka): string[] => [
            ...prev,
            `${roleProfiluMap[current.roleProfilu].popis} (${roleProfiluMap[current.roleProfilu].mernaJednotka.nazev})`,
            'Status',
        ],
        defaultProps,
    );

    // Naplní řádky tabulky daty do příslušných sloupců, a prázdné hodnoty nahradí '-'
    const tabularData = preprareDataForExportPlyn(rawData, isDenniDiagram, true, statusy);

    // Vrátí hlavičku (columnData) a řádky tabulky (tabularData)
    return {columnData, tabularData};
};

const preprareDataForExportElektrina = (
    rawData: OdberneMistoElektrinaHistorieSpotrebPolozka[] | undefined,
    isDenniDiagram: boolean,
    isExport?: boolean,
    statusy?: Maybe<StatusHistorieSpotrebElektrina>[] | undefined,
) => {
    const allDates = uniqBy(
        rawData
            ?.map((historiePolozka) =>
                historiePolozka.hodnoty.map((hodnota) => ({datumCasOd: hodnota.datumCasOd, datumcasDo: hodnota.datumCasDo, status: hodnota.status})),
            )
            .flat(),
        'datumCasOd',
    );
    const preparedDataMapObj = prepareDataMapElektrina(rawData);
    const formatOptionOd = 'd.M.yyyy';
    const tabularRows = [];
    tabularRows.push(
        allDates?.map(({datumCasOd, datumcasDo, status}) => {
            const hodnoty: Array<string | number> = [];
            preparedDataMapObj.forEach((profil) => {
                const value = profil.get(datumCasOd) || profil.get(datumCasOd) === 0 ? `${profil.get(datumCasOd)}/${status}` : '-';
                const valuebaseOnIsExport = isExport ? (value === '-' ? '' : Number(value.split('/')[0])) : value;
                const statusHumanReadable = value === '-' ? '' : (statusy?.find((statusItem) => statusItem?.klic === status)?.nazev ?? '');
                hodnoty.push(valuebaseOnIsExport);
                if (isExport) {
                    hodnoty.push(statusHumanReadable);
                }
            });
            const data: Array<string | number> = [`${format(new Date(datumCasOd), formatOptionOd)}`];
            if (!isDenniDiagram) {
                data.push(`${format(new Date(datumCasOd), 'HH:mm')} - ${format(new Date(datumcasDo), 'HH:mm')}`);
            }
            data.push(...hodnoty);
            return data;
        }),
    );
    return tabularRows.flat();
};
const preprareDataForExportPlyn = (
    rawData: OdberneMistoPlynHistorieSpotrebPolozka[] | undefined,
    isDenniDiagram: boolean,
    isExport?: boolean,
    statusy?: Maybe<StatusHistorieSpotrebElektrina>[] | undefined,
) => {
    const allDates = uniqBy(
        rawData?.map((historiePolozka) => historiePolozka.hodnoty.map((hodnota) => ({datumCas: hodnota.datumCas, status: hodnota.status}))).flat(),
        'datumCas',
    );
    const preparedDataMapObj = prepareDataMapPlyn(rawData);
    const formatOption = isExport ? 'd.M.yyyy HH:mm' : 'd.M.yyyy';
    const tabularRows = [];
    tabularRows.push(
        allDates?.map(({datumCas, status}) => {
            const hodnoty: Array<string | number | undefined> = [];
            preparedDataMapObj.forEach((profil) => {
                const value = profil.get(datumCas) || profil.get(datumCas) === 0 ? `${profil.get(datumCas)}/${status}` : '-';
                const valuebaseOnIsExport = isExport ? Number(value.split('/')[0]) : value;
                const statusHumanReadable = statusy?.find((statusItem) => statusItem?.klic === status)?.nazev ?? '';
                hodnoty.push(valuebaseOnIsExport);
                if (isExport) {
                    hodnoty.push(statusHumanReadable);
                }
            });
            const data: Array<string | number | undefined> = [format(new Date(datumCas), formatOption)];
            if (!isDenniDiagram && !isExport) {
                data.push(format(new Date(datumCas), 'HH:mm'));
            }
            data.push(...hodnoty);
            return data;
        }),
    );
    return tabularRows.flat();
};

export const validationKategoriemereni = (
    kategorieMereni: Maybe<Maybe<SmlouvaOdbernehoMistaKategorieMereni>[]> | undefined,
    datumOd: string,
    datumDo: string,
) => {
    return kategorieMereni
        ?.filter((item) => item?.kategorieMereni !== '4')
        ?.filter((item) => new Date(item?.platnostOd) <= new Date(datumOd) && new Date(item?.platnostDo) >= new Date(datumDo));
};

export const historieSpotrebHodnotaStatus = (
    status: HistorieSpotrebHodnotaStatusTyp | undefined,
    statusy: Maybe<StatusHistorieSpotrebElektrina>[] | undefined,
) => {
    const statusMap = {
        [TypStatusuHistorieSpotrebElektrina.FINALNI]: 'custom.green.main',
        [TypStatusuHistorieSpotrebElektrina.CHYBI]: 'custom.orange.main',
        [TypStatusuHistorieSpotrebElektrina.NAHRADNI]: 'custom.indigo.main',
    };
    const statusHumanReadable = statusy?.find((statusItem) => statusItem?.klic === status)?.typ;
    return {color: statusHumanReadable ? statusMap[statusHumanReadable] : undefined, status: statusHumanReadable};
};

/**
 * The function `sortedTypyDiagramuByKlic` sorts an array of specific types of diagrams based on a key
 * and filters them based on certain conditions.
 * @param typyDiagramu - The `typyDiagramu` parameter is an array containing elements of type
 * `TypDiagramuHistorieSpotrebElektrina` or `TypDiagramuHistorieSpotrebPlyn`.
 * @param {boolean} isSouctovyProfil - The `isSouctovyProfil` parameter is a boolean value that
 * indicates whether a certain profile is a "souctovy profil" or not.
 * @param {SmlouvaOdbernehoMistaTypMereni | undefined} typMereni - The `typMereni` parameter is of type
 * `SmlouvaOdbernehoMistaTypMereni | undefined`. It represents the type of measurement for the contract
 * of the supply point. It can have one of the following values:
 * @returns The function `sortedTypyDiagramuByKlic` takes an array of
 * `TypDiagramuHistorieSpotrebElektrina` or `TypDiagramuHistorieSpotrebPlyn`, a boolean
 * `isSouctovyProfil`, and an optional `typMereni` parameter.
 */
export const sortedTypyDiagramuByKlic = (
    typyDiagramu: Array<TypDiagramuHistorieSpotrebElektrina | TypDiagramuHistorieSpotrebPlyn>,
    isSouctovyProfil: boolean,
    typMereni: SmlouvaOdbernehoMistaTypMereni | undefined,
) => {
    return typyDiagramu
        .toSorted((a, b) => {
            if (a.klic.toLowerCase().includes('fakturacni') && !b.klic.toLowerCase().includes('fakturacni')) {
                return -1;
            } else if (!a.klic.toLowerCase().includes('fakturacni') && b.klic.toLowerCase().includes('fakturacni')) {
                return 1;
            } else {
                return 0;
            }
        })
        .filter((typ) => {
            const zobrazitPro = isSouctovyProfil
                ? HistorieSpotrebTypPristroje.SOUCTOVY_PROFIL
                : typMereni === SmlouvaOdbernehoMistaTypMereni.C
                  ? HistorieSpotrebTypPristroje.PRISTROJ_C1_C2_C3
                  : HistorieSpotrebTypPristroje.PRISTROJ_A_B;

            return (typ as TypDiagramuHistorieSpotrebElektrina).zobrazitPro?.includes(zobrazitPro);
        });
};
