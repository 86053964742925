import {Div, SafeFormattedMessage} from '@eon.cz/apollo13-frontend-common';
import {Komodita} from '@eon.cz/apollo13-graphql';
import {ExitToAppOutlined} from '@mui/icons-material';
import {Avatar, Button, List, ListItemAvatar, ListItemButton, ListItemText, Paper, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {LandingBackground} from '../../Common/components/layout/LandingBackground';
import {KomoditaLogoIcon} from '../../Komodita/components/KomoditaLogoIcon';

type Props = {
    handleLogout: () => void;
    handleOnChooseKomodita: (komodita: Komodita) => void;
    zadnaKomoditaKVyberu: boolean;
    komodityList: Komodita[];
};

export const ChooseKomodita = ({handleLogout, handleOnChooseKomodita, zadnaKomoditaKVyberu, komodityList}: Props) => {
    const handleChangeKomodita = (komodita: Komodita) => () => {
        handleOnChooseKomodita(komodita);
    };
    return (
        <LandingBackground pageOff>
            <Paper elevation={8}>
                <Div sx={{padding: 2}}>
                    <Typography variant="h6">
                        <FormattedMessage id={zadnaKomoditaKVyberu ? Lang.KOMODITA_ZADNA_TITLE : Lang.KOMODITA_VYBER} />
                    </Typography>
                </Div>
                {!zadnaKomoditaKVyberu && (
                    <List>
                        {komodityList.map((row) => (
                            <ListItemButton sx={{gap: 2}} onClick={handleChangeKomodita(row)} key={row}>
                                <ListItemAvatar>
                                    <Avatar sx={{width: 55, height: 55, backgroundColor: 'primary.main'}}>
                                        <KomoditaLogoIcon type={row} />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={<SafeFormattedMessage langArray={Lang} langArrayKey={`KOMODITA_${row}`} fallbackMessage={row} />} />
                            </ListItemButton>
                        ))}
                    </List>
                )}
                {zadnaKomoditaKVyberu && (
                    <>
                        <Div sx={{padding: 2}}>
                            <Typography variant="body1">
                                <FormattedMessage id={Lang.KOMODITA_ZADNA_DESCRIPTION} />
                            </Typography>
                        </Div>
                        <Div sx={{padding: 2}}>
                            <Button onClick={handleLogout}>
                                <ExitToAppOutlined />
                                &nbsp;
                                <FormattedMessage id={Lang.ODHLASIT_SE} />
                            </Button>
                        </Div>
                    </>
                )}
            </Paper>
        </LandingBackground>
    );
};
