import {ColInfo, utils, writeFile} from 'xlsx';

type ExportXLSXConfig = {
    /**
     * Definuje custom textaci hlaviček
     */
    headers?: any;

    /**
     * Název výsledného souboru
     */
    filename?: string;

    /**
     * Formát výsledného datumu v exportu
     */
    dateFormat?: string;

    /**
     * Column sizes / hidden flag
     */
    columnInfo?: ColInfo[];
};

const DEFAULT_FILENAME = 'export.xlsx';

export const EXPORT_DATUM_FORMAT = 'D.M.YYYY';
export const EXPORT_DATUM_CAS_FORMAT = 'D.M.YYYY H:mm';

export const ExportService = {
    computeColumnSizes: (items: any[][]): ColInfo[] => {
        const res: Array<ColInfo | any> = [];

        // Pass all rows
        for (const row of items) {
            // Pass all cells in a row
            for (let i = 0; i < row.length; i++) {
                // Get cell
                const cell = row[i];
                if (typeof cell === 'string') {
                    // Handle string
                    const l = cell.length;

                    // Extend ColInfos to cover this cell
                    while (res.length <= i) {
                        res.push(null);
                    }

                    // Make sure the length in colinfo is at least as long as this string
                    if (res[i] === null) {
                        res[i] = {wch: l};
                    } else {
                        if (res[i].wch < l) {
                            res[i].wch = l;
                        }
                    }
                }
            }
        }

        return res;
    },

    exportArrayOfArraysAsXLSX: (items: any[][], config: ExportXLSXConfig) => {
        const {filename, headers, columnInfo} = config;

        const data = !!headers ? [headers, ...items] : items;

        const wsname = 'SheetJS';

        const wb = utils.book_new();

        /* convert an array of arrays in JS to a CSF spreadsheet */
        const ws = utils.aoa_to_sheet(data, {cellDates: true});

        ws['!cols'] = columnInfo;

        /* TEST: add worksheet to workbook */
        utils.book_append_sheet(wb, ws, wsname);

        writeFile(wb, !!filename ? filename : DEFAULT_FILENAME);
    },

    /**
     * Handler exportu dat do excelu (.xlsx)
     */
    exportXLSXFile: (items: any[] | undefined, config: ExportXLSXConfig) => {
        const {headers, filename, dateFormat, columnInfo} = config;
        // Přidá custom hodnoty záhlaví do prvního řádku
        // Check if headers is Array of strings

        if (!Array.isArray(headers)) {
            items?.unshift(headers);
        }
        if (Array.isArray(headers) && typeof headers[0] === 'string') {
            items?.unshift(headers);
        }

        // Check if headers is Array of Arrays
        if (Array.isArray(headers) && Array.isArray(headers[0])) {
            headers.forEach((header) => {
                items?.unshift(header);
            });
        }

        // Založí prázdný excel soubor
        const workbook = utils.book_new();
        // Zkonvertuje json data do excel sheetu
        const worksheet = utils.json_to_sheet(items ?? [], {
            /**
             * Nastaví přeskakování záhlaví tabulky - defaultně nastavuje textaci záhlaví podle názvů klíčů v objektech.
             * Záhlaví lze přepsat předáním objektu headers s vlastními textacemi.
             */
            skipHeader: !!headers,
            dateNF: dateFormat ? dateFormat : 'FMT 14',
        });

        if (columnInfo) {
            worksheet['!cols'] = columnInfo;
        }

        // Přidá sheet do souboru
        utils.book_append_sheet(workbook, worksheet);
        // Zapíše a vytvoří nový XLSX soubor a vyvolá jeho stažení v prohlížeči
        return writeFile(workbook, filename ? filename : DEFAULT_FILENAME);
    },
};
