import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {BezproudiOdstavkyFilterModel} from '../model/BezproudiOdstavkyFilterModel';
import {BezproudiOdstavkyZakaznikFilterModel} from '../model/BezproudiOdstavkyZakaznikFilterModel';
import {BezproudiPoruchyFilterModel} from '../model/BezproudiPoruchyFilterModel';
import {BezproudiStore} from '../store/BezproudiSore';

/**
 * Výchozí stav reduceru
 */
export const initialState: BezproudiStore = {
    activeTab: 0,
    odstavky: {
        filter: {
            datumOd: undefined,
            datumDo: undefined,
            adresa: undefined,
            stav: 'vse',
        },
    },
    odstavkyZakaznik: {
        filter: {
            datumOd: undefined,
            datumDo: undefined,
            adresa: undefined,
            stav: 'vse',
            ean: undefined,
            nazev: undefined,
        },
    },
    poruchy: {
        filter: {
            adresa: undefined,
            kalamita: false,
        },
    },
};

export const bezproudiSlice = createSlice({
    name: 'bezproudi',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setTabData: (store: BezproudiStore, {payload}: PayloadAction<number>): BezproudiStore => ({
            ...store,
            activeTab: payload,
        }),
        // reducers pro odstávky
        setOdstavkyFilter: (store: BezproudiStore, {payload}: PayloadAction<BezproudiOdstavkyFilterModel>): BezproudiStore => ({
            ...store,
            odstavky: {filter: payload},
        }),
        clearOdstavkyFilter: (store: BezproudiStore): BezproudiStore => ({
            ...store,
            odstavky: {filter: initialState.odstavky.filter},
        }),
        // reducers pro odstávky zakaznik
        setOdstavkyZakaznikFilter: (store: BezproudiStore, {payload}: PayloadAction<BezproudiOdstavkyZakaznikFilterModel>): BezproudiStore => ({
            ...store,
            odstavkyZakaznik: {filter: payload},
        }),
        clearOdstavkyZakaznikFilter: (store: BezproudiStore): BezproudiStore => ({
            ...store,
            odstavkyZakaznik: {filter: initialState.odstavkyZakaznik.filter},
        }),
        // reducers pro poruchy
        setPoruchyFilter: (store: BezproudiStore, {payload}: PayloadAction<BezproudiPoruchyFilterModel>): BezproudiStore => ({
            ...store,
            poruchy: {filter: payload},
        }),
        clearPorychyFilter: (store: BezproudiStore): BezproudiStore => ({
            ...store,
            poruchy: {filter: initialState.poruchy.filter},
        }),
    },
});

export const {
    setTabData,
    clearOdstavkyFilter,
    clearOdstavkyZakaznikFilter,
    clearPorychyFilter,
    setOdstavkyFilter,
    setOdstavkyZakaznikFilter,
    setPoruchyFilter,
} = bezproudiSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const bezproudi = (state: Store) => state.bezproudi;
export const selectbezproudi = createSelector([bezproudi], (bezproudi) => bezproudi);
