import {NotificationType} from '@eon.cz/apollo13-frontend-common';
import {NotificationAddRequest} from '@eon.cz/apollo13-frontend-common/lib/notification/reducer/NotificationsReducer';
import {Komodita, UcetSkupinaTyp} from '@eon.cz/apollo13-graphql';
import {endOfMonth, format, isSameMonth, startOfDay, startOfMonth, subDays, subMonths} from 'date-fns';
import isEqual from 'lodash/isEqual';
import Router from 'next/router';
import {FormattedMessage} from 'react-intl';
import {BackendEndpoints} from '../../../../server/BackendEndpoints';
import {Lang} from '../../../Lang';
import {PageRoute} from '../../Common/model/PageRouteModel';
import {getFromLS} from '../../Common/services/PageService';
import {RouteService} from '../../Common/services/RouteService';
import {toApiDate} from '../../Utils';
import {PrilohyKeStazeniFilterModel} from '../model/PrilohyKeStazeni';
import {PrilohyKeStazeniMenuItemModel, PrilohyKeStazeniStav} from '../model/PrilohyKeStazeniMenuItemModel';

export const PRILOHY_KE_STAZENI_TYP_VSE = 'vse';

export const STATIC_SESTAVA_ELEKTRINA_IMPORT_FILE = RouteService.getPathname(PageRoute.DOWNLOAD, '/EGD_Sestava_technickych_detailu_elektrina_VZOR.xlsx');
export const STATIC_SESTAVA_PLYN_IMPORT_FILE = RouteService.getPathname(PageRoute.DOWNLOAD, '/EGD_Sestava_technickych_detailu_plyn_VZOR.xlsx');

/**
 * Na základě toho,jestli se jedná sestavy nebo daňové doklady,které mají filtrování buď po měsíci nebo po dnech
 */
export const getDatumOdByStav = (selectedLeftMenuType?: PrilohyKeStazeniStav) => {
    return selectedLeftMenuType !== PrilohyKeStazeniStav.DANOVE_DOKLADY ? format(startOfDay(subMonths(new Date(), 3)), 'yyyy-MM-dd') : '2018-01-01';
};

// nastaví datum podle stavu
/**
 * The function returns the current date in the format 'yyyy-MM-dd'.
 * @returns the current date in the format 'yyyy-MM-dd'.
 */
export const getDatumDoByStav = () => {
    return format(new Date(), 'yyyy-MM-dd');
};

/**
 * The function `getMinDatumOd` returns a minimum date based on the selectedLeftMenuType and datumDo
 * parameters.
 * @param {PrilohyKeStazeniStav} [selectedLeftMenuType] - The selectedLeftMenuType parameter is an
 * optional parameter of type PrilohyKeStazeniStav.
 * @returns a string representing a date.
 */
export const getMinDatumOd = (selectedLeftMenuType?: PrilohyKeStazeniStav) => {
    return selectedLeftMenuType === PrilohyKeStazeniStav.MONTAZNI_LISTY
        ? '2019-08-01'
        : selectedLeftMenuType === PrilohyKeStazeniStav.SESTAVY_KE_STAZENI
          ? '2019-09-01'
          : '2018-01-01';
};

/**
 * The function `getDatumOdTDOM` returns the date 8 days ago in the format 'yyyy-MM-dd'.
 * @returns A string in the format 'yyyy-MM-dd' representing the date 8 days ago from the current date.
 */
export const getDatumOdTDOM = (): string => {
    return format(startOfDay(subDays(new Date(), 8)), 'yyyy-MM-dd');
};

/**
 * The function `getDatumDoTDOM` returns the formatted date of the previous day.
 * @returns a string in the format 'yyyy-MM-dd', which represents the date of the previous day.
 */
export const getDatumDoTDOM = (): string => {
    return format(startOfDay(subDays(new Date(), 1)), 'yyyy-MM-dd');
};

/**
 * The function `getIconByLeftMenuType` takes a parameter `stavType` of type
 * `PrilohyKeStazeniMenuItemModel` or `undefined` and returns a string representing an icon based on
 * the value of `stavType`.
 * @param {PrilohyKeStazeniMenuItemModel | undefined} stavType - The `stavType` parameter is of type
 * `PrilohyKeStazeniMenuItemModel` or `undefined`.
 * @returns The function `getIconByLeftMenuType` returns a string representing an icon name if the
 * input `stavType` matches one of the cases in the switch statement. If `stavType` does not match any
 * of the cases, the function returns `null`.
 */
export const getIconByLeftMenuType = (stavType: PrilohyKeStazeniMenuItemModel | undefined): string | null => {
    switch (stavType) {
        case PrilohyKeStazeniStav.SESTAVY_KE_STAZENI:
            return 'archive';
        case PrilohyKeStazeniStav.DANOVE_DOKLADY:
            return 'ballot';
        case PrilohyKeStazeniStav.TECHNICKE_DETAILY_OM:
            return 'storage';
        case PrilohyKeStazeniStav.MONTAZNI_LISTY:
            return 'summarize';
        case undefined:
            return null;
        default:
            return null;
    }
};

/**
 * The function returns an array of all values of the PrilohyKeStazeniStav enum.
 * @returns An array of all the values of the enum `PrilohyKeStazeniStav`.
 */
export const getAllPrilohyKeStazeniStavy = (): PrilohyKeStazeniStav[] => {
    return Object.values(PrilohyKeStazeniStav);
};

/**
 * The function returns an array of menu items based on the input parameters.
 * @param {UcetSkupinaTyp | undefined} skupina - The parameter "skupina" is of type "UcetSkupinaTyp |
 * undefined". It represents the type of account group.
 * @param {boolean} fakturujiciPoskytovatel - A boolean value indicating whether the provider should be
 * invoiced or not.
 * @returns an array of PrilohyKeStazeniStav values based on the conditions specified in the if
 * statements.
 */
export const getAllLeftPrilohyKeStazeniMenuItems = (skupina: UcetSkupinaTyp | undefined, fakturujiciPoskytovatel: boolean) => {
    if (skupina === UcetSkupinaTyp.OBCHODNIK) {
        return getAllPrilohyKeStazeniStavy().filter((stav) => stav !== PrilohyKeStazeniStav.MONTAZNI_LISTY);
    }
    if ((skupina === UcetSkupinaTyp.MUNICIPALITA || skupina === UcetSkupinaTyp.KONCOVY_ZAKAZNIK) && fakturujiciPoskytovatel) {
        return getAllPrilohyKeStazeniStavy().filter(
            (stav) => stav !== PrilohyKeStazeniStav.SESTAVY_KE_STAZENI && stav !== PrilohyKeStazeniStav.TECHNICKE_DETAILY_OM,
        );
    }
    if (skupina === UcetSkupinaTyp.MUNICIPALITA || skupina === UcetSkupinaTyp.KONCOVY_ZAKAZNIK) {
        return getAllPrilohyKeStazeniStavy().filter((stav) => stav === PrilohyKeStazeniStav.MONTAZNI_LISTY);
    }
};

/**
 * The function checks if the initial filter and current filter are equal.
 * @param {PrilohyKeStazeniFilterModel} initialFilter - The initialFilter parameter is the initial
 * state or value of the filter that is being used. It represents the filter settings or criteria that
 * were set when the filter was first initialized.
 * @param {PrilohyKeStazeniFilterModel} currentFilter - The `currentFilter` parameter is the current
 * state of the filter that is being used. It represents the current selection or configuration of the
 * filter.
 * @returns a boolean value.
 */
export const canResetPrilohyKeStazeniFilter = (initialFilter: PrilohyKeStazeniFilterModel, currentFilter: PrilohyKeStazeniFilterModel): boolean => {
    return !isEqual(initialFilter, currentFilter);
};

/**
 * The function `mapToPrilohyKeStazeniFiltratioGraphqlModel` maps input parameters to a GraphQL model
 * object.
 * @param typyPrilohy - An array of objects with the following properties:
 * @param {PrilohyKeStazeniFilterModel} filter - The `filter` parameter is of type
 * `PrilohyKeStazeniFilterModel`. It is an object that contains various properties used for filtering
 * data.
 * @param {boolean} typPrilohyNotObchodnik - A boolean value indicating whether the type of attachment
 * is not related to a trader.
 * @returns an object with properties based on the input parameters. The properties include the spread
 * of the `filter` object, the `datumOd` and `datumDo` properties converted to API date format using
 * the `toApiDate` function, the `typ` property based on the `typPrilohyNotObchodnik` and `filter.typ`
 * values, the `
 */
export const mapToPrilohyKeStazeniFiltratioGraphqlModel = (
    typyPrilohy: Array<{id: string; klic?: string} | null>,
    filter: PrilohyKeStazeniFilterModel,
    selectedLeftMenuType: PrilohyKeStazeniStav | undefined,
) => {
    const isThisMonth = isSameMonth(new Date(), new Date(filter.datumDo));
    const datumDoSestavy = isThisMonth ? toApiDate(filter.datumDo) : format(endOfMonth(new Date(filter.datumDo)), 'yyyy-MM-dd');
    const datumDoOthers = toApiDate(filter.datumDo);
    return {
        ...filter,
        datumOd:
            selectedLeftMenuType === PrilohyKeStazeniStav.SESTAVY_KE_STAZENI && filter.datumOd
                ? format(startOfMonth(new Date(filter.datumOd)), 'yyyy-MM-dd')
                : toApiDate(filter.datumOd),
        datumDo: selectedLeftMenuType === PrilohyKeStazeniStav.SESTAVY_KE_STAZENI ? datumDoSestavy : datumDoOthers,
        typ: filter.typ === PRILOHY_KE_STAZENI_TYP_VSE ? undefined : remapTypValue(filter?.typ, typyPrilohy),
        cisloDokladu: filter.cisloDokladu,
        komodita: filter.komodita as Komodita,
    };
};

/**
 * The function remapTypValue takes a typ value and an array of typy objects, and returns the klic
 * value of the typy object that has a matching id with the typ value.
 * @param {string | undefined | null} typ - The parameter `typ` is a string that can be `undefined`,
 * `null`, or a valid string value.
 * @param typy - An array of objects with optional properties "id" and "klic".
 * @returns The function `remapTypValue` returns a string value or `undefined`.
 */
export const remapTypValue = (typ: string | undefined | null, typy: Array<{id?: string; klic?: string} | null>): string | undefined => {
    const typValue = typy.find((t) => t?.id === typ);
    return typValue ? typValue.klic : undefined;
};

/**
 * The function `getSopPrepisSampleImportFile` returns a specific import file based on the value of the
 * `komodita` parameter.
 * @param {Komodita | null | undefined} komodita - The parameter "komodita" is of type "Komodita" which
 * can be either "ELEKTRINA", "PLYN", or undefined/null.
 */
export const getSopPrepisSampleImportFile = (komodita: Komodita | null | undefined) =>
    komodita === Komodita.ELEKTRINA ? STATIC_SESTAVA_ELEKTRINA_IMPORT_FILE : STATIC_SESTAVA_PLYN_IMPORT_FILE;

/* The `handleStahnoutPrilohu` function is a callback function that is used to handle the download of a
file attachment. It takes several parameters: */
export const handleStahnoutPrilohu =
    (
        id: string | undefined | null,
        addNotification: (notification: NotificationAddRequest) => {type: string; payload: NotificationAddRequest},
        handleRefresh: () => void,
        setOpen: (status: boolean) => void,
        repository?: string | undefined | null,
        nazev?: string | undefined | null,
        isDanoveDoklady?: boolean,
    ) =>
    () => {
        const isMobile = getFromLS('mobile')?.isMobile;
        const chyba = () => {
            addNotification({type: NotificationType.ERROR, text: <FormattedMessage id={Lang.KOMUNIKACE} />});
            setOpen(false);
        };
        const ENDPOINT = isMobile ? BackendEndpoints.DOWNLOAD_DMS_MOBILE : BackendEndpoints.DOWNLOAD_DMS;
        const fetchDanoveDoklady = `${window.location.origin}/api/${ENDPOINT}/${id}:${repository}?nazev=${nazev}`;
        const fetchOstatni = `${window.location.origin}/api/${ENDPOINT}/${id}?nazev=${nazev}`;
        setOpen(true);

        //www.roytuts.com/download-file-from-server-using-react/
        fetch(isDanoveDoklady ? fetchDanoveDoklady : fetchOstatni)
            .then((response) => {
                switch (response.status) {
                    case 200: // BE nalezl a vratil soubor
                        if (isMobile) {
                            response.json().then((data) => {
                                Router.push(data.downloadLink);
                            });
                        }
                        if (!isMobile) {
                            response.blob().then((blob) => {
                                const fileName = response?.headers?.get('content-disposition')?.split('filename=')[1];
                                const url = window.URL.createObjectURL(blob);
                                const a = document.createElement('a');
                                a.href = url;
                                a.download = nazev ?? fileName ?? 'Soubor';
                                a.click();
                            });
                        }
                        setOpen(false);
                        handleRefresh();
                        break;
                    case 413:
                        addNotification({type: NotificationType.WARNING, text: <FormattedMessage id={Lang.SESTAVY_PRILOHY_KE_STAZENI_VELKA_PRILOHA} />});
                        handleRefresh(); // musime si znova dotahnout filtr > tam uz by mel byt stav upraven
                        break;
                    default:
                        chyba();
                }
            })
            .catch(() => {
                chyba();
            });
    };
