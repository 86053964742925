import {Komodita, PovoleneTypyPriloh, Ucet} from '@eon.cz/apollo13-graphql';
import {useMediaQuery} from '@mui/material';
import {ServerResponse} from 'http';
import memoize from 'lodash/memoize';
import Router from 'next/router';
import sanitizeHtml from 'sanitize-html';
import {MobileProps} from '../model/MobilePropsModel';

export const PageService = {
    /**
     * Redirect correctly to given path
     *
     * @param path Path to redirect to
     * @param res Express response
     */
    redirect: async (path: string, res?: ServerResponse) => {
        if (res) {
            res.writeHead(302, {Location: path});
            res.end();
            res.finished = true;
        } else {
            await Router.push(path);
        }
    },
};

export const useMatches = (maxWidth = '500px') => useMediaQuery(`(max-width:${maxWidth})`);
export const useMatchesTouchDevice = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

export const useTablet = () => {
    const minWidth600 = useMediaQuery('(min-width:600px)');
    const maxWidth1200 = useMatches('1200px');
    return maxWidth1200 && minWidth600;
};

/**
 * It takes a string of HTML and returns a string of HTML that's been sanitized
 * @param {string} html - The HTML string to sanitize.
 */
export const sanitizeHTML = (html: string) => sanitizeHtml(html);

export const getKomodity = (me: Ucet | null | undefined): Komodita[] => {
    const result: Komodita[] = [];
    if (me?.skupina?.elektrina) {
        result.push(Komodita.ELEKTRINA);
    }
    if (me?.skupina?.plyn) {
        result.push(Komodita.PLYN);
    }
    return result;
};

/**
 * Zkonvertuje povolené typy příloh na mapu podle typu objektu
 */
export const convertPovoleneTypyPriloh = (typy: PovoleneTypyPriloh[]) =>
    typy.reduce(
        (map, typ: PovoleneTypyPriloh) => {
            map[typ.typObjektu] = typ;
            return map;
        },
        {} as {[key: string]: PovoleneTypyPriloh},
    );

/**
 * Memoizovaná verze. POZOR!!! Nezohledňue parametr (protože by to bylo skoro stejně náročné jako samotný výpočet),
 * tedy vrací bez ohledu na parametr to, co v prvním volání. Nicméně data by se neměla po dobu sesson měnit.
 */
const convertPovoleneTypyPrilohMemoized = memoize(convertPovoleneTypyPriloh, () => 1);

export const getPovoleneTypyPriloh = (povoleneTypyPriloh: PovoleneTypyPriloh[] | undefined) => {
    if (!povoleneTypyPriloh) {
        // No data
        return {};
    }

    return convertPovoleneTypyPrilohMemoized(povoleneTypyPriloh);
};

export const getFromLS = (id: 'mobile' | 'desktop', key?: keyof MobileProps): MobileProps => {
    let ls = {} as Record<string, any>;
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem(id) ?? '{}') || {};
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(`%c 🚨 -> getFromLS error: `, 'color: #e13019', error);
        }
    }
    return key ? ls[key] : ls;
};

export const saveToLS = <T extends 'mobile' | 'desktop', U = T extends 'mobile' ? MobileProps : any>(
    id: T,
    value: U extends MobileProps ? MobileProps : any,
) => {
    if (global.localStorage) {
        const oldValues = getFromLS(id);
        global.localStorage.setItem(
            id,
            JSON.stringify({
                ...oldValues,
                ...value,
            }),
        );
    }
};
