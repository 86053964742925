import {DetailValue, Div, StyledLink} from '@eon.cz/apollo13-frontend-common';
import {Komodita, ObchodniPartnerTyp, SmlouvaOdbernehoMista} from '@eon.cz/apollo13-graphql';
import {ArrowLeftOutlined, ArrowRightOutlined} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Grid from '@mui/material/Grid2';
import Typography from '@mui/material/Typography';
import {ReactElement, useEffect, useRef, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {PageRoute, RouteService} from '../../Common';
import {useTablet} from '../../Common/services/PageService';
import {formatAddress} from '../../Utils';
import {selectItem} from '../service/OdbernaMistaService';

type Props = {
    om: SmlouvaOdbernehoMista;
    komodita: Komodita | undefined | null;
    menu: ReactElement;
};
export const OdbernaMistaCard = ({om, komodita, menu}: Props) => {
    const isTablet = useTablet();

    const isElektrina = komodita === Komodita.ELEKTRINA;
    const adresa = isElektrina ? om.elektrina?.adresa : om.plyn?.adresa;
    const scrollContainerRef = useRef<HTMLDivElement>(null);
    const [showLeftArrow, setShowLeftArrow] = useState(false);
    const [showRightArrow, setShowRightArrow] = useState(false);

    const updateArrowsVisibility = () => {
        if (scrollContainerRef.current) {
            const {scrollLeft, scrollWidth, clientWidth} = scrollContainerRef.current;
            setShowLeftArrow(scrollLeft > 0);
            setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
        }
    };

    const scrollLeft = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({left: -100, behavior: 'smooth'});
        }
    };

    const scrollRight = () => {
        if (scrollContainerRef.current) {
            scrollContainerRef.current.scrollBy({left: 100, behavior: 'smooth'});
        }
    };
    useEffect(() => {
        updateArrowsVisibility();
        const handleResize = () => updateArrowsVisibility();
        const scrollContainer = scrollContainerRef.current;

        window.addEventListener('resize', handleResize);
        if (scrollContainer) {
            scrollContainer.addEventListener('scroll', updateArrowsVisibility);
        }

        return () => {
            window.removeEventListener('resize', handleResize);
            if (scrollContainer) {
                scrollContainer.removeEventListener('scroll', updateArrowsVisibility);
            }
        };
    }, []);
    return (
        <Card sx={{borderRadius: 5}}>
            <CardHeader
                avatar={
                    <Typography variant="h6">
                        <FormattedMessage id={isElektrina ? 'ean' : 'eic'} />
                    </Typography>
                }
                action={menu}
                title={
                    <StyledLink
                        href={{
                            pathname: RouteService.getPathname(PageRoute.SMLOUVY_ODBERNYCH_MIST, '/detail', komodita),
                            query: {id: om.id},
                        }}
                        asVariables={om.id}
                    >
                        {!isElektrina ? om?.plyn?.eic : om?.elektrina?.eanSpotrebni}
                    </StyledLink>
                }
            />
            <CardContent>
                <Grid container spacing={1}>
                    {isElektrina && om.elektrina?.eanVyrobni && (
                        <Grid size={{xs: 12}}>
                            <DetailValue title="odberna.mista.vyrobna.ean.vyrobni" value={om.elektrina?.eanVyrobni} inline={isTablet} />
                        </Grid>
                    )}
                    <Grid size={{xs: 12}}>
                        <DetailValue title="odberna.mista.nazev" value={om.nazev ?? 'Neuvedeno'} tooltipText="odberna.mista.nazev.tooltip" inline={isTablet} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <DetailValue
                            title="odberna.mista.zakaznik"
                            value={
                                om.zakaznik.typ === ObchodniPartnerTyp.PRAVNICKA_OSOBA
                                    ? om.zakaznik.nazevSpolecnosti
                                    : `${om.zakaznik.jmeno} ${om.zakaznik.prijmeni}`
                            }
                            inline={isTablet}
                        />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <DetailValue title="adresa.odberneho.mista" value={formatAddress(adresa)} inline={isTablet} />
                    </Grid>
                    <Grid size={{xs: 12}}>
                        <Grid container alignItems="center">
                            {/* Zobrazí šipku vlevo, pokud je třeba */}
                            {showLeftArrow && (
                                <Grid>
                                    <IconButton onClick={scrollLeft}>
                                        <ArrowLeftOutlined />
                                    </IconButton>
                                </Grid>
                            )}

                            {/* Kontejner s ikonami a textem */}
                            <Grid sx={{overflowX: 'hidden', position: 'relative'}}>
                                <Div
                                    ref={scrollContainerRef}
                                    sx={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        overflowX: 'auto',
                                        scrollbarWidth: 'none',
                                        '&::-webkit-scrollbar': {display: 'none'},
                                    }}
                                >
                                    {selectItem(menu.props.items)
                                        .filter((item) => item.icon)
                                        .map((item, index) => (
                                            <Div
                                                key={index}
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    marginRight: '16px', // Odstup mezi ikonami
                                                    whiteSpace: 'nowrap',
                                                }}
                                                onClick={item.onClick}
                                            >
                                                <IconButton color="secondary">{item.icon}</IconButton>
                                                <Typography variant="caption" color="textSecondary" align="center">
                                                    {item.shortTitle ?? item.title}
                                                </Typography>
                                            </Div>
                                        ))}
                                </Div>
                            </Grid>

                            {/* Zobrazí šipku vpravo, pokud je třeba */}
                            {showRightArrow && (
                                <Grid>
                                    <IconButton onClick={scrollRight}>
                                        <ArrowRightOutlined />
                                    </IconButton>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
};
