import {ReactNode} from 'react';
import {PageRouteModel} from './PageRouteModel';

export const MobileModalModel = {
    DASHBOARD: 'dashboard',
    KOMODITA: 'komodita',
    KONTAKTY: 'kontakty',
    PROFIL: 'profil',
    MENU: 'menu',
    PRISTUPY: 'pristupy',
} as const;
export type MobileModalModel = (typeof MobileModalModel)[keyof typeof MobileModalModel];

export type AppBottomNavigationModel = {
    value: string;
    label: ReactNode | string;
    icon: string | ReactNode;
    route?: PageRouteModel;
    modal?: MobileModalModel | undefined;
    showLabel?: boolean;
    allowInLimitedMode?: boolean;
    isVisible?: boolean;
};
