import {useQuery} from '@apollo/client';
import {isNullOrUndefinedOrEmpty} from '@eon.cz/apollo13-frontend-common';
import {Query, UcetSpravceZmocnenecTypPristupu, UcetTyp} from '@eon.cz/apollo13-graphql';
import Grid from '@mui/material/Grid2';
import dynamic from 'next/dynamic';
import {useContext, useEffect, useState} from 'react';
import {PageContext} from '../../Common/contexts/PageContext';
import {sendDataToRN} from '../../Common/services/CommonService';
import {getFromLS} from '../../Common/services/PageService';
import {OdbernaMistaQueries} from '../../OdbernaMista/graphql';
import {OdbernaMistaService} from '../../OdbernaMista/service/OdbernaMistaService';

const IndexPageZadatel = dynamic(() => import('./IndexPageZadatel').then((mod) => mod.IndexPageZadatel));
const IndexPageOstatni = dynamic(() => import('./IndexPageOstatni').then((mod) => mod.IndexPageOstatni));
const BiometryConfirmationDialog = dynamic(() => import('../../Mobile/components/BiometryConfirmationDialog').then((mod) => mod.BiometryConfirmationDialog));

const IndexPage = () => {
    // context
    const {me, komodita, loggedInRestrictedMode} = useContext(PageContext);

    // local state
    const [openBiometricsDialog, setOpenBiometricsDialog] = useState(false);

    // local variables
    const mobile = getFromLS('mobile');
    const isMobile = getFromLS('mobile')?.isMobile;
    const canUseBiometrics = mobile.canUseBiometrics;
    const userId = mobile?.userId;
    const showBiometryConfirmation = isMobile && mobile?.biometryAvailable && isNullOrUndefinedOrEmpty(canUseBiometrics) && isNullOrUndefinedOrEmpty(userId);
    const isZadatel = me?.typ === UcetTyp.ZADATEL || me?.pristup?.typ === UcetSpravceZmocnenecTypPristupu.ZADATEL;
    const isObchodnik = me?.typ === UcetTyp.OBCHODNIK;

    // methods
    const handleCloseBiometryDialog = () => setOpenBiometricsDialog(false);

    // queries
    useQuery<Query>(OdbernaMistaQueries.gql.getHDOs, {
        fetchPolicy: 'network-only',
        skip: !isMobile || isZadatel,
        onCompleted: (data) => {
            const hdo = data.elektrina.hdos?.getHdos;
            const remap = hdo?.map((neco) => ({
                ean: neco?.ean,
                vlastniNazevOM: neco?.vlastniNazevOM,
                hdo: neco?.hdo.map((n) => ({
                    sazby: n?.sazby.map((sazba) => ({
                        id: Math.floor(Math.random() * (1000000 - 1 + 1)) + 1,
                        dny: sazba?.dny?.map((den) => ({id: Math.floor(Math.random() * (1000000 - 1 + 1)) + 1, casy: den?.casy, denVTydnu: den?.denVTydnu})),
                        sazba: OdbernaMistaService.getNazevSazbyString(sazba?.sazba).trim(),
                    })),
                    endDate: n?.endDate,
                }))[0],
            }));
            sendDataToRN({setWidgetData: remap});
        },
    });

    // effects
    useEffect(() => {
        if (!isObchodnik && isMobile) {
            sendDataToRN({hideTabs: 'HIDE'});
        }
    }, [isObchodnik, isMobile]);

    if (showBiometryConfirmation && !openBiometricsDialog) {
        setOpenBiometricsDialog(true);
    }

    // If user not registered or is logged in restricted mode (groupe has certificate login) and user dont have the certificate in browser
    if (loggedInRestrictedMode || !me?.registrovany) {
        // No other agendas available in limited mode
        return <Grid container spacing={2} />;
    }

    if (isZadatel) {
        return (
            <>
                <IndexPageZadatel me={me} komodita={komodita} />
                {openBiometricsDialog && <BiometryConfirmationDialog open={openBiometricsDialog} onClose={handleCloseBiometryDialog} />}
            </>
        );
    }

    if (!isZadatel) {
        return (
            <>
                <IndexPageOstatni me={me} komodita={komodita} />
                {openBiometricsDialog && <BiometryConfirmationDialog open={openBiometricsDialog} onClose={handleCloseBiometryDialog} />}
            </>
        );
    }
};

export default IndexPage;
