import {useQuery} from '@apollo/client';
import {Div} from '@eon.cz/apollo13-frontend-common';
import {Komodita, PrilohyKeStazeniQueryDanoveDokladyNastenkaArgs, Query} from '@eon.cz/apollo13-graphql';
import {AllOutOutlined} from '@mui/icons-material';
import {Typography} from '@mui/material';
import {useRouter} from 'next/router';
import {FormattedMessage, FormattedNumber} from 'react-intl';
import {PageRoute, RouteService} from '../../Common';
import {theme} from '../../Common/components/layout/theme';
import {NastenkaWidget} from '../../Nastenka/common/NastenkaWidget';
import {usePrilohyKeStazeniActionDispatch} from '../action/PrilohyKeStazeniAction';
import {PrilohyKeStazeniQueries} from '../graphql/PrilohyKeStazeniQueries';
import {PrilohyKeStazeniStav} from '../model/PrilohyKeStazeniMenuItemModel';

type Props = {
    komodita: Komodita | null | undefined;
    isObchodnik: boolean;
    standAlone?: boolean;
};
export const PrilohyKeStazeniWidget = ({komodita, isObchodnik, standAlone}: Props) => {
    const {push} = useRouter();
    const {loading, data} = useQuery<Query, PrilohyKeStazeniQueryDanoveDokladyNastenkaArgs>(PrilohyKeStazeniQueries.gql.danoveDokladyNastenka, {
        variables: {komodita: komodita as Komodita},
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
    });

    const {changeSelectedLeftMenuType, setChangeTab} = usePrilohyKeStazeniActionDispatch();

    const handleClickDoklady = () => {
        if (isObchodnik) {
            setChangeTab(1);
            changeSelectedLeftMenuType(PrilohyKeStazeniStav.DANOVE_DOKLADY);
        }
        push({pathname: RouteService.getPathname(PageRoute.PRILOHY_KE_STAZENI)});
    };

    return (
        <NastenkaWidget
            onClick={handleClickDoklady}
            title={<FormattedMessage id="sestavy.tab.DANOVE_DOKLADY" />}
            titleBlockIcon={<AllOutOutlined color="primary" />}
            loading={loading}
            unlimitedheight={!standAlone}
        >
            <Div sx={{display: 'flex', flexDirection: 'column'}}>
                <Typography component="div" variant="subtitle1" style={{fontWeight: 'bold', color: theme.palette.custom.orange.main}}>
                    <FormattedMessage id="sestavy.danovy.doklad.widget.upozorneni" />
                </Typography>
                <Typography component="div">
                    <FormattedMessage
                        id="sestavy.danovy.doklad.widget.upozorneni.castka"
                        values={{
                            b: () => (
                                <strong>
                                    <FormattedNumber
                                        value={data?.prilohyKeStazeni.danoveDokladyNastenka ?? 0}
                                        style="currency"
                                        currency="CZK"
                                        maximumFractionDigits={2}
                                    />
                                </strong>
                            ),
                        }}
                    />
                </Typography>
                <Typography component="a" style={{cursor: 'pointer', fontSize: 15}} onClick={handleClickDoklady}>
                    <FormattedMessage id="sestavy.danovy.doklad.widget.upozorneni.prejit" />
                </Typography>
            </Div>
        </NastenkaWidget>
    );
};
