import {Span} from '@eon.cz/apollo13-frontend-common/lib/components/styledComponents/Span';
import {useMatches} from '@eon.cz/apollo13-frontend-common/lib/utils';
import {HelpOutline} from '@mui/icons-material';
import {Tooltip, TooltipProps, Typography, styled, tooltipClasses} from '@mui/material';
import {ReactNode, SyntheticEvent, useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';

const CustomWidthTooltip = styled(({className, ...props}: TooltipProps) => <Tooltip {...props} classes={{popper: className}} />)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 900,
        backgroundColor: theme.palette.background.paper,
        color: 'rgba(0, 0, 0, 0.87)',
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 5,
    },
}));

type Props = Omit<TooltipProps, 'title' | 'children'> & {
    text?: string | null;
    textRaw?: string | null;
    value?: any;
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top';
    color?: 'inherit' | 'disabled' | 'action' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
    customColor?: string;
    fontSize?: 'inherit' | 'small' | 'large' | 'medium';
    htmlText?: ReactNode;
    animate?: boolean;
    children?: ReactNode;
    showIcon?: boolean;
    withButton?: boolean;
    inline?: boolean;
    isInTable?: boolean;
    open?: boolean;
};
export const TooltipIcon = ({
    text,
    placement = 'top-start',
    color = 'inherit',
    customColor,
    fontSize,
    htmlText,
    animate,
    value,
    children,
    showIcon = true,
    textRaw,
    withButton,
    inline,
    open = true,
}: Props) => {
    const matches = useMatches();
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false);
    const handleOpenNapoveda = (e: SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setTooltipIsOpen(true);
    };
    const handleCloseNapoveda = () => setTooltipIsOpen(false);

    useEffect(() => {
        if (tooltipIsOpen && matches) {
            setTimeout(() => {
                setTooltipIsOpen(false);
            }, 30000);
        }
    }, [tooltipIsOpen, matches]);
    return (
        <CustomWidthTooltip
            open={tooltipIsOpen}
            onOpen={handleOpenNapoveda}
            onClose={handleCloseNapoveda}
            placement={placement}
            title={
                htmlText || text ? (
                    <Typography color="textPrimary" variant="body2" component="div">
                        {htmlText ? htmlText : <FormattedMessage id={text ?? 'empty'} values={value} />}
                    </Typography>
                ) : (
                    (textRaw ?? '')
                )
            }
            leaveTouchDelay={30000}
        >
            <Span sx={{display: 'flex'}} onClick={tooltipIsOpen ? handleCloseNapoveda : handleOpenNapoveda}>
                {children}
                {(htmlText || text || textRaw) && showIcon && (
                    <HelpOutline
                        sx={(theme) => ({
                            opacity: 0.6,
                            fontSize: '0.9rem',
                            marginLeft: withButton ? -1 : 1,
                            color: customColor ?? 'secondary.main',
                            ...(open &&
                                animate && {
                                    borderRadius: '50%',
                                    transform: 'scale(1)',
                                    animation: 'pulse 2s infinite',
                                    '@keyframes pulse': {
                                        '0%': {
                                            transform: 'scale(0.95)',
                                            boxShadow: `0 0 0 0 ${theme.palette.secondary.main}`,
                                        },
                                        '70%': {
                                            transform: 'scale(1)',
                                            boxShadow: '0 0 0 10px rgba(0, 0, 0, 0)',
                                        },
                                        '100%': {
                                            transform: 'scale(0.95)',
                                            boxShadow: '0 0 0 0 rgba(0, 0, 0, 0)',
                                        },
                                    },
                                }),
                            ...(inline && {
                                opacity: 0.6,
                                fontSize: '1rem',
                                marginBottom: `${theme.spacing(0)} !important`,
                                marginLeft: theme.spacing(1),
                            }),
                        })}
                        color={color}
                        fontSize={fontSize}
                        onClick={handleOpenNapoveda}
                    />
                )}
            </Span>
        </CustomWidthTooltip>
    );
};
