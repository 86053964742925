import {Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';

type Props = {
    title: string;
};

/**
 * Komponenta pro nadpis na widgetech
 */
export const DetailTitle = ({title}: Props) => {
    return (
        <Typography variant="subtitle2" component="div" sx={{lineHeight: 1.5}}>
            <FormattedMessage id={title} />
        </Typography>
    );
};
