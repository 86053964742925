import {Div, PaperBlock} from '@eon.cz/apollo13-frontend-common';
import {AppBar, Button, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';

type Props = {
    error?: Error;
    resetErrorBoundary?: () => void;
};

export const ErrorFallbackLogin = ({error, resetErrorBoundary}: Props) => {
    return (
        <Div sx={(theme) => ({padding: `64px ${theme.spacing()}px ${theme.spacing()}px`})}>
            <AppBar sx={(theme) => ({padding: theme.spacing(2), display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'})}>
                <Typography variant="h6" color="inherit">
                    <FormattedMessage id={Lang.LOGIN_ERROR} />
                </Typography>
            </AppBar>

            <PaperBlock sx={{mt: 8}} titleBlock={!!error ? error.message : undefined}>
                <Button color="primary" variant="contained" disableRipple onClick={resetErrorBoundary}>
                    <FormattedMessage id={Lang.BUTTON_RESET} />
                </Button>
            </PaperBlock>
        </Div>
    );
};
