import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {MessageStore} from '../../../Store/MessageStore';
import {MessageAddModel} from '../model/MessageAddModel';
import {MessageModel} from '../model/MessageModel';

const initialState = {
    messages: [],
    isOpenDrawer: false,
} as MessageStore;

/**
 * Vytvoreni Message objektu z MessageAddModel
 * @param {MessageAddModel} addMessage
 */
const createMessage = (addMessage: MessageAddModel): MessageModel => {
    return {...addMessage, read: false, count: addMessage.count || 1, updated: new Date(), created: new Date()};
};

const remapMessage = ({count, created}: MessageModel, newMessage: MessageModel): MessageModel => {
    return {...newMessage, read: false, count: count + newMessage.count, created};
};

export const messageSlice = createSlice({
    name: 'message',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setAddMessage: (state: MessageStore, {payload}: PayloadAction<MessageAddModel>): MessageStore => {
            const message: MessageModel = createMessage(payload);
            if (state.messages.findIndex((f) => f.key === payload.key) !== -1) {
                return {...state, messages: state.messages.map((m) => (m.key === payload.key ? remapMessage(m, message) : m))};
            }
            return {
                ...state,
                messages: [...state.messages, message],
            };
        },

        setReadMessage: (state: MessageStore, {payload}: PayloadAction<string>): MessageStore => ({
            ...state,
            messages: state.messages.map((m) => (m.key === payload ? {...m, read: true} : m)),
        }),

        setReadAllMessages: (state: MessageStore): MessageStore => ({...state, messages: state.messages.map((m) => ({...m, read: true}))}),

        setSelectMessageKey: (state: MessageStore, {payload}: PayloadAction<string>): MessageStore => ({
            ...state,
            selectedMessageKey: payload,
            messages: state.messages.map((m) => (m.key === payload ? {...m, read: true} : m)),
        }),

        setOpenMessageDrawer: (state: MessageStore): MessageStore => ({...state, isOpenDrawer: true, selectedMessageKey: undefined}),
        setCloseMessageDrawer: (state: MessageStore): MessageStore => ({...state, isOpenDrawer: false}),
    },
});

export const {setAddMessage, setCloseMessageDrawer, setOpenMessageDrawer, setReadAllMessages, setReadMessage, setSelectMessageKey} = messageSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const message = (state: Store) => state.message;
export const selectMessage = createSelector([message], (message) => message);
