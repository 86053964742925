import {Komodita, Maybe, PovoleneTypyPriloh, Ucet, UcetSpravceZmocnenecPristup} from '@eon.cz/apollo13-graphql';
import {createContext} from 'react';

export type AdminContext = {
    adminId: string;
    readOnly: boolean;
};
export type PageContextType = {
    /**
     * Informace o přihlášeném uživateli
     */
    me?: Ucet | null;
    /**
     * Informace o přihlášeném uživateli
     */
    pristupy?: Maybe<UcetSpravceZmocnenecPristup>[];
    /**
     * Aktuálně vybraná komodita
     */
    komodita?: Komodita | null;
    /**
     * Zda je uživatel v omezeném režimu
     */
    loggedInRestrictedMode: boolean;
    /**
     * Seznam povolených typů příloh
     */
    povoleneTypyPriloh: {[key: string]: PovoleneTypyPriloh};
    adminContext: AdminContext;
};

/**
 * Globální kontext poskytující důležité údaje kdekoliv v aplikaci
 */
export const PageContext = createContext<PageContextType>({
    me: null,
    pristupy: undefined,
    komodita: null,
    loggedInRestrictedMode: false,
    povoleneTypyPriloh: {},
    adminContext: {adminId: '', readOnly: false},
});
