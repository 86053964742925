import {Dispatch, PayloadAction, UnknownAction} from '@reduxjs/toolkit';
import {useDispatch} from 'react-redux';
import {PrilohyKeStazeniFilterModel} from '../model/PrilohyKeStazeni';
import {PrilohyKeStazeniMenuItemModel} from '../model/PrilohyKeStazeniMenuItemModel';
import {
    setChangePrilohyKeStazeniSelectedLeftMenuType,
    setClearPrilohyKeStazeniFilter,
    setPrilohyKeStazeniFilter,
    setPrilohyKeStazeniTab,
} from '../reducers/PrilohyKeStazeniReducer';

export type PrilohyKeStazeniAction = {
    setFilter: (filter: PrilohyKeStazeniFilterModel) => PayloadAction<PrilohyKeStazeniFilterModel>;
    clearFilter: () => UnknownAction;
    changeSelectedLeftMenuType: (type: PrilohyKeStazeniMenuItemModel | undefined) => PayloadAction<PrilohyKeStazeniMenuItemModel | undefined>;

    setChangeTab: (tab: number) => PayloadAction<number>;
};

export const usePrilohyKeStazeniActionDispatch = (): PrilohyKeStazeniAction => {
    const dispatch: Dispatch<PayloadAction<PrilohyKeStazeniFilterModel | PrilohyKeStazeniMenuItemModel> | UnknownAction> = useDispatch();
    return {
        /**
         * Nastavení filtru pro přílohy ke stažení
         * @param filter
         */
        setFilter: (filter: PrilohyKeStazeniFilterModel) => dispatch(setPrilohyKeStazeniFilter(filter)),

        /**
         * Smazání filtru a nastavení na default hodnoty
         */
        clearFilter: () => dispatch(setClearPrilohyKeStazeniFilter()),

        /**
         * Přepínání stavu v levém menu
         * @param type
         */
        changeSelectedLeftMenuType: (type) => dispatch(setChangePrilohyKeStazeniSelectedLeftMenuType(type)),
        /**
         * Přepínání stavu v levém menu
         * @param type
         */
        setChangeTab: (tab) => dispatch(setPrilohyKeStazeniTab(tab)),
    };
};
