import {CommonLang, DEFAULT_RESOLVER_PATH, GraphQLErrorResolver, NotificationType, ValidationErrorWithPath} from '@eon.cz/apollo13-frontend-common';
import {Dispatch} from '@reduxjs/toolkit';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../Lang';

const RULE_SHOW: any = undefined;

/**
 * Rules for getContact
 */
const RULES_GET_CONTACT: SapResolverConfigRule[] = [
    {
        type: 'E',
        rule: Lang.SAP_ERROR_GET_CONTACT,
    },
];

/**
 * Rules for setContact
 */
const RULES_SET_CONTACT: SapResolverConfigRule[] = [
    {
        type: 'E',
        number: 39,
        rule: RULE_SHOW,
    },
    {
        type: 'E',
        rule: Lang.SAP_ERROR_SET_CONTACT,
    },
];

/**
 * Rules for getContractPeriod
 */
const RULES_GET_CONTRACT_PERIOD: SapResolverConfigRule[] = [
    {
        type: 'E',
        number: 14,
        rule: Lang.SAP_ERROR_GET_CONTRACT_PERIOD_EAN,
    },
    {
        type: 'E',
        rule: Lang.SAP_ERROR_GET_CONTRACT_PERIOD,
    },
];

/**
 * Rules for getPodDetail
 */
const RULES_GET_POD_DETAIL: SapResolverConfigRule[] = [
    {
        type: 'E',
        rule: Lang.SAP_ERROR_GET_POD_DETAIL,
    },
];

/**
 * Rules for getEdm
 */
const RULES_GET_EDM: SapResolverConfigRule[] = [
    {
        type: 'I',
        number: 14,
        rule: RULE_SHOW,
    },
    {
        type: 'E',
        rule: Lang.SAP_ERROR_GET_EDM,
    },
    {
        type: 'W',
        rule: Lang.SAP_WARN_GET_EDM,
    },
];

/**
 * Map of paths to rule sets to be used for that path
 */
const RULES_MAP = {
    'kontakty/kontakty': RULES_GET_CONTACT,
    'kontakty/createKontakt': RULES_SET_CONTACT,
    'elektrina/reklamace/reklamaceSeznam': RULES_GET_CONTRACT_PERIOD,
    'elektrina/reklamace/reklamace': RULES_GET_CONTRACT_PERIOD,
    'plyn/reklamace/reklamaceSeznam': RULES_GET_CONTRACT_PERIOD,
    'plyn/reklamace/reklamace': RULES_GET_CONTRACT_PERIOD,
    'smlouvaOdbernehoMista/elektrina': RULES_GET_POD_DETAIL,
    'smlouvaOdbernehoMista/plyn': RULES_GET_POD_DETAIL,
    'smlouvaOdbernehoMista/elektrina/historieSpotreb': RULES_GET_EDM,
    'smlouvaOdbernehoMista/plyn/historieSpotreb': RULES_GET_EDM,
    'smlouvaOdbernehoMista/elektrina/historieOdectu': RULES_GET_EDM,
    'smlouvaOdbernehoMista/plyn/historieOdectu': RULES_GET_EDM,
} as Record<string, SapResolverConfigRule[]>;

export type SapResolverConfigRule = {
    /**
     * Message type
     */
    type?: string;

    /**
     * Message number
     */
    number?: number;

    /**
     * Rule. When it is a string, string is produced. When RULE_SHOW  is used, message is shown to user as is. When RULE_IGNORE is used, message is ignored.
     */
    rule: string | null | undefined;
};

/**
 * SAP error structure
 */
export type SapError = {
    sapErrNo: number;
    sapErrType: string;
    message: string;
};

const showError = (dispatch: Dispatch, error: SapError) => {
    GraphQLErrorResolver.addError(dispatch, error.message, null, error.sapErrType === 'E' ? NotificationType.ERROR : NotificationType.INFO);
};

const processError = (dispatch: Dispatch, rules: SapResolverConfigRule[], error: SapError) => {
    for (const rule of rules) {
        if ((rule.type === 'undefined' || rule.type === error.sapErrType) && (rule.number === undefined || rule.number === error.sapErrNo)) {
            // Matched rule
            if (rule.rule === RULE_SHOW) {
                // Show the error
                showError(dispatch, error);
            } else if (typeof rule.rule === 'string') {
                // Rule is string => message id
                GraphQLErrorResolver.addError(
                    dispatch,
                    <FormattedMessage id={rule.rule} />,
                    null,
                    error.sapErrType === 'E' ? NotificationType.ERROR : NotificationType.INFO,
                );
            } // RULE_IGNORE has no action so is not tested
            return;
        }
    }

    // Default behavior when not matched anything: When it is ERROR, show as "SAP ERROR", otherwise hide
    if (error.sapErrType === 'E') {
        GraphQLErrorResolver.addError(dispatch, <FormattedMessage id={CommonLang.SAP_ERROR} />, error, NotificationType.ERROR);
    }
};

export const SapErrorResolver = {
    init: () => {
        GraphQLErrorResolver.registerResolver(DEFAULT_RESOLVER_PATH, (dispatch, errors, path) => {
            const rules = RULES_MAP[path];
            if (!!rules) {
                const unfilteredErrors: ValidationErrorWithPath[] = [];

                errors?.forEach((error) => {
                    if (error.code === 'EXTERNAL_SYSTEM_ERROR') {
                        // SAP error - process
                        if (!!error.errors && error.errors.length > 0) {
                            // Process SAP errors level 1
                            error.errors.forEach((sapWrapper: any) => {
                                if (sapWrapper.type === 'SAP' && !!sapWrapper.errors && sapWrapper.errors.length > 0) {
                                    // Process SAP errors level 2
                                    sapWrapper.errors.forEach((sapError: any) => processError(dispatch, rules, sapError));
                                }
                            });
                        }
                    } else {
                        // Not our error
                        unfilteredErrors.push(error);
                    }
                });

                // Return errors we have not filtered
                return unfilteredErrors;
            }

            // We don't have rules for that - return original errors
            return errors ?? [];
        });
    },
};
