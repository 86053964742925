import {Div} from '@eon.cz/apollo13-frontend-common';
import {WarningOutlined} from '@mui/icons-material';
import {Button, Paper, Typography} from '@mui/material';
import {FormattedMessage} from 'react-intl';
import {LandingBackground} from '../../Common';

type Props = {
    handleReload: () => void;
    errorMessage: string;
    buttonTitle: string;
};

export const LoginErrorPage = ({handleReload, errorMessage, buttonTitle}: Props) => {
    return (
        <LandingBackground pageOff>
            <Paper elevation={2} sx={{backgroundColor: 'background.paper'}}>
                <Div sx={{paddingTop: 3, display: 'flex', justifyContent: 'center'}}>
                    <WarningOutlined sx={{fontSize: '3rem'}} color="error" />
                </Div>
                <Div sx={{padding: 2, textAlign: 'center'}}>
                    <Typography variant="h6">
                        <FormattedMessage id={errorMessage} />
                    </Typography>
                </Div>
                <Div sx={{padding: 2, display: 'flex', justifyContent: 'center'}}>
                    <Button variant="outlined" color="primary" onClick={handleReload}>
                        <FormattedMessage id={buttonTitle} />
                    </Button>
                </Div>
            </Paper>
        </LandingBackground>
    );
};
