import {useApolloClient} from '@apollo/client';
import {Div} from '@eon.cz/apollo13-frontend-common';
import {useMatches} from '@eon.cz/apollo13-frontend-common/lib/utils/CommonUtils';
import {Warning as WarningIcon} from '@mui/icons-material';
import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography} from '@mui/material';
import axios from 'axios';
import {useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {useAuthAction} from '../actions/AuthAction';

type Props = {
    email: string | undefined | null;
    onClose: () => void;
    onClickNo: () => void;
};

/**
 * Komponenta pro přihlášení zobrazení síly hesla v případě jiného než silného hesla
 */
export const LoginPasswordStrengthDialog = ({email, onClickNo, onClose}: Props) => {
    const matches = useMatches();
    const [resetSent, setResetSent] = useState(false);
    const client = useApolloClient();
    const {logout} = useAuthAction();

    const handleSubmitResetHesla = () => {
        axios.post(`${window.location.origin}/api/createResetHeslaPozadavek`, {email}).then(() => setResetSent(true));
    };

    const handleLoginRedirect = () => {
        // make sure to clear token from cookie
        onClose();
        logout(client);
    };

    return (
        <Dialog open={true}>
            <DialogTitle>
                <FormattedMessage id={Lang.PASSWORD_STRENGTH_TITLE} />
            </DialogTitle>
            <DialogContent
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                }}
            >
                {!resetSent && (
                    <>
                        <WarningIcon
                            sx={(theme) => ({
                                color: 'custom.orange.main',
                                marginRight: theme.spacing(2),
                                width: 80,
                                height: 80,
                            })}
                        />
                        <Div
                            sx={{
                                flex: '1',
                            }}
                        >
                            <Typography>
                                <FormattedMessage id={Lang.PASSWORD_STRENGTH_MESSAGE} />
                            </Typography>
                        </Div>
                    </>
                )}
                {resetSent && (
                    <Div>
                        <Typography variant="subtitle1">
                            <FormattedMessage id={Lang.RESET_HESLA_POZADAVEK_ODESLANO} />
                        </Typography>
                        <Typography variant="subtitle2">
                            <FormattedMessage
                                id={Lang.RESET_HESLA_POZADAVEK_ODESLANO_TEXT}
                                values={{
                                    email,
                                }}
                            />
                        </Typography>
                        <Typography variant="subtitle2">
                            <FormattedMessage id={Lang.RESET_HESLA_POZADAVEK_ODESLANO_UPOZORNENI} />
                        </Typography>
                    </Div>
                )}
            </DialogContent>
            <DialogActions sx={{width: {xs: '100%', sm: 'auto'}, textAlign: 'right', gap: 1}}>
                {!resetSent ? (
                    <>
                        <Button variant="outlined" onClick={handleSubmitResetHesla} fullWidth={matches}>
                            <FormattedMessage id={Lang.BUTTON_ZMENA_HESLA} />
                        </Button>

                        <Button variant="contained" onClick={onClickNo} fullWidth={matches}>
                            <FormattedMessage id={Lang.RESET_HESLA_ZPET} />
                        </Button>
                    </>
                ) : (
                    <Button variant="outlined" onClick={handleLoginRedirect} fullWidth={matches}>
                        <FormattedMessage id={Lang.LOGIN_ZPET_NA_PRIHLASENI} />
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
};
