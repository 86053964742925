import {gql} from '@apollo/client';
import {TooltipIcon} from '@eon.cz/apollo13-frontend-common/lib/components/icons/TooltipIcon';
import {ObchodniPartner, ObchodniPartnerTyp} from '@eon.cz/apollo13-graphql';
import {TableCell} from '@mui/material';
import {TableCellProps} from '@mui/material/TableCell';

type Props = TableCellProps & {
    obchodniPartner: ObchodniPartner;
    short?: boolean;
};

const MAX_LENGTH = 30;

const getNazevWithTooltip = (nazevSpolecnosti: string) => (
    <TooltipIcon textRaw={nazevSpolecnosti}>
        <span>
            {nazevSpolecnosti.substr(0, MAX_LENGTH)}
            ...
        </span>
    </TooltipIcon>
);

export const ObchodniPartnerTableCellFragment = {
    zakaznik: gql`
        fragment ObchodniPartnerTableCell on ObchodniPartner {
            id
            nazevSpolecnosti
            jmeno
            prijmeni
            typ
        }
    `,
};

/**
 * Komponenta pro zobrazení jména a příjmení nebo názvu společnosti z objektu obchodního partnera.
 * Pokud je předána proměnná 'short', název společnosti se zkrátí a celý název se zobrazí v Tooltipu.
 */
export const ObchodniPartnerTableCell = ({obchodniPartner: op, short, ...props}: Props) => {
    const nazevSpolecnosti = op?.nazevSpolecnosti ?? '';
    return (
        <TableCell {...props} sx={{whiteSpace: 'nowrap'}}>
            {op.typ === ObchodniPartnerTyp.PRAVNICKA_OSOBA
                ? short && nazevSpolecnosti?.length > MAX_LENGTH + 3
                    ? getNazevWithTooltip(op?.nazevSpolecnosti ?? '')
                    : op.nazevSpolecnosti
                : `${op.jmeno} ${op.prijmeni}`}
        </TableCell>
    );
};
