import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {isNotNullOrUndefinedOrEmpty, LoadingDialog, useMatches} from '@eon.cz/apollo13-frontend-common';

import {
    Komodita,
    Mutation,
    PasswordQuality,
    PristupyMutationSetActiveArgs,
    Query,
    UcetTyp,
    UctyMutationAddFcmRegistrationTokenArgs,
    UctyMutationEnableBiometricsArgs,
    UctyMutationRevokeFcmRegistrationTokenArgs,
    UctyQueryGetActivePristupyArgs,
} from '@eon.cz/apollo13-graphql';
import {useRouter} from 'next/router';
import {useContext, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Lang} from '../Lang';
import {Store} from '../Store';
import {useAuthAction} from '../modules/Auth/actions/AuthAction';
import {ChooseAccess} from '../modules/Auth/components/ChooseAccess';
import {ChooseKomodita} from '../modules/Auth/components/ChooseKomodita';
import {LoginErrorPage} from '../modules/Auth/components/LoginErrorPage';
import {LoginPasswordStrengthDialog} from '../modules/Auth/components/LoginPasswordStrengthDialog';
import {MobileKomoditaInfoDialog} from '../modules/Auth/components/MobileKomoditaInfo';
import {setChangeLoginStatus} from '../modules/Auth/reducers/AuthReducer';
import {isLogged, isLoggedInRestrictedMode, pathToSkip, removeCookie, storeLoggedUserForDebug} from '../modules/Auth/service/AuthService';
import {PageRoute} from '../modules/Common';
import {LandingBackground} from '../modules/Common/components';
import {KomoditaContext} from '../modules/Common/contexts/KomoditaContext';
import {AdminContext, PageContext} from '../modules/Common/contexts/PageContext';
import {PermissionStatus} from '../modules/Common/model/MobilePropsModel';
import {sendDataToRN} from '../modules/Common/services/CommonService';
import {getFromLS, getKomodity, getPovoleneTypyPriloh} from '../modules/Common/services/PageService';
import {ProfileService} from '../modules/Profile/service';
import {UcetMutations, UcetQueries} from '../modules/Ucet';
import {apolloClient} from './withApolloProvider';

type Props = {
    children: any;
};

/**
 * Wrapper komponenta pro přihlášeného uživatele
 */
export const WithProfile = ({children}: Props) => {
    const matches = useMatches();

    const {push, reload: handleReload, pathname} = useRouter();
    const {isLogouting, isLogin, reload, passwordQuality, refetchStatus} = useSelector((state: Store) => state.auth);
    const {admin, mobile} = useSelector((state: Store) => state.common);
    const {mobileNotification, notificationGranted, publicKey, isMobile, biometryAvailable, deviceId, userId, firstLogin} = getFromLS('mobile');
    const dispatch = useDispatch();

    const [showPasswordStrengthDialog, setShowPasswordStrengthDialog] = useState(false);

    const {komodita} = useContext(KomoditaContext);

    const {logout, fetchLoginConfig} = useAuthAction();

    const handleLogout = () => logout(apolloClient);

    const handleClosePasswordStrengthDialog = () => {
        setShowPasswordStrengthDialog(false);
    };

    const needprofile = isLogged();

    const {loading, data, refetch} = useQuery<Query>(UcetQueries.gql.withProfile, {
        fetchPolicy: 'no-cache',
        skip: !needprofile,
        onCompleted: (res) => {
            storeLoggedUserForDebug(res?.me?.email);
            if (notificationGranted === PermissionStatus.GRANTED && isMobile)
                sendToken({variables: {input: {token: mobileNotification?.tokenId ?? '', deviceId: mobileNotification?.deviceId as string}}});
            if (notificationGranted !== PermissionStatus.GRANTED && isMobile && mobileNotification?.deviceId) {
                revokeToken({variables: {input: {deviceId: mobileNotification?.deviceId as string}}});
            }
            if (publicKey && biometryAvailable && (deviceId || mobile?.deviceId || mobile?.mobileNotification?.deviceId)) {
                enableBiometrics({variables: {input: {publicKey, deviceId: deviceId ?? (mobile?.deviceId as string)}}});
            }
            if (userId && userId !== res.me?.id && isMobile && biometryAvailable) {
                sendDataToRN({saveUserId: res.me?.id});
            }
            if (isMobile && biometryAvailable) {
                sendDataToRN({biometricsAutoLogin: 'FALSE'});
            }
            if (komodita && komodita !== res.me?.pristup?.komodita && res.me?.typ !== UcetTyp.ZADATEL && res.me?.typ !== UcetTyp.OBCHODNIK) {
                ProfileService.setKomodita(isMobile ? Komodita.ELEKTRINA : (res.me?.pristup?.komodita ?? komodita), false);
            }
        },
        onError: () => handleLogout(),
        notifyOnNetworkStatusChange: true,
    });
    const {data: dataPristupy} = useQuery<Query, UctyQueryGetActivePristupyArgs>(UcetQueries.gql.withProfileUcty, {
        fetchPolicy: 'no-cache',
        skip: !needprofile,
        variables: {komodita: komodita ?? Komodita.ELEKTRINA},
    });

    const [getPristupNaOpacnouKomoditu, {data: dataPristupNaOpacnouKomoditu, called: calledPristupNaOpacnouKomoditu}] = useLazyQuery<Query>(
        UcetQueries.gql.opacnakomodita,
        {
            onCompleted: (res) => {
                if (isMobile) {
                    const pristupNaOpacnouKomoditu = res?.ucty?.pristupNaOpacnouKomoditu;
                    // GAS is not supported on mobile. If user on desktop switch tu GAS, on mobile we need to switch to ELECTRICITY.Only if user is on GAS.
                    if (pristupNaOpacnouKomoditu.komodita !== Komodita.PLYN) {
                        handleOnChooseKomodita(Komodita.ELEKTRINA, pristupNaOpacnouKomoditu.id);
                    }
                }
            },
        },
    );

    const handleRefresh = () => refetch();

    const [setActivePristup, {called, loading: loadingSetActivePristup}] = useMutation<Mutation, PristupyMutationSetActiveArgs>(
        UcetMutations.gql.setActivePristup,
        {
            onCompleted: (res) => {
                const skupina = res.ucty.pristupy?.setActive.skupina;
                if (skupina?.id) {
                    dispatch(setChangeLoginStatus());
                    handleRefresh();
                }
            },
        },
    );
    const [sendToken] = useMutation<Mutation, UctyMutationAddFcmRegistrationTokenArgs>(UcetMutations.gql.sendMobileToken);
    const [revokeToken] = useMutation<Mutation, UctyMutationRevokeFcmRegistrationTokenArgs>(UcetMutations.gql.revokeMobileToken);
    const [enableBiometrics] = useMutation<Mutation, UctyMutationEnableBiometricsArgs>(UcetMutations.gql.enableBiometrics);

    const me = data?.me;

    const isZadatel = me?.typ === UcetTyp.ZADATEL;

    const isObchodnik = me?.typ === UcetTyp.OBCHODNIK;

    const email = me?.email;

    const pristupy = dataPristupy?.ucty?.getActivePristupy;

    const adminContext = data?.adminContext;

    const povoleneTypyPriloh = data?.nastaveni?.prilohy?.povoleneTypyPriloh;

    const restrictedMode = typeof window !== 'undefined' && isLoggedInRestrictedMode();

    const hasMultipleKomodita = me?.typ !== UcetTyp.OBCHODNIK;

    const pristup = me?.pristup;

    const pristupNaOpacnouKomoditu = dataPristupNaOpacnouKomoditu?.ucty?.pristupNaOpacnouKomoditu;

    const handleOnChooseKomodita = (komoditaValue: Komodita, id?: string) => {
        if (isObchodnik || isZadatel) {
            ProfileService.setKomodita(komoditaValue, false);
        }
        if (pristup?.aktivni) {
            const idPristupu = komoditaValue === pristup.komodita ? pristup.id : pristupNaOpacnouKomoditu?.id;
            if (!loadingSetActivePristup) {
                setActivePristup({
                    variables: {idPristupu: idPristupu ?? (id as string), komodita: komoditaValue ?? Komodita.ELEKTRINA},
                }).then(() => {
                    ProfileService.setKomodita(komoditaValue, false);
                });
            }
        }
    };

    useEffect(() => {
        fetchLoginConfig();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!needprofile && !pathToSkip.includes(pathname)) {
            removeCookie();
            push({pathname: PageRoute.LOGIN.path});
        }
    }, [needprofile, pathname, push]);

    useEffect(() => {
        if (isLogin && passwordQuality === PasswordQuality.PRILIS_SLABE) {
            setShowPasswordStrengthDialog(true);
        }
    }, [passwordQuality, isLogin]);

    useEffect(() => {
        if (reload) {
            dispatch(setChangeLoginStatus());
            handleRefresh();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reload]);

    useEffect(() => {
        if (isNotNullOrUndefinedOrEmpty(pristup) && !calledPristupNaOpacnouKomoditu && me) {
            getPristupNaOpacnouKomoditu();
        }
    }, [pristup, me, calledPristupNaOpacnouKomoditu, getPristupNaOpacnouKomoditu]);

    if (isLogouting) {
        return (
            <LandingBackground pageOff>
                <LoadingDialog open={true} description={Lang.PROFILE_LOGGING_OUT} />
            </LandingBackground>
        );
    }
    if (loading) {
        return <LoadingDialog open={true} description={Lang.PROFILE_LOADING_PORTAL} />;
    }

    const profileError = needprofile && !loading && !me;

    if (profileError) {
        const errorMessage = Lang.PROFILE_LOAD_ERROR;
        const buttonTitle = Lang.PROFILE_RELOAD;
        return <LoginErrorPage errorMessage={errorMessage} buttonTitle={buttonTitle} handleReload={handleReload} />;
    }

    const handleChooseSkupina = (idPristupu: string | undefined, komodita: Komodita) => () => {
        ProfileService.setKomodita(komodita, false);
        if (!loadingSetActivePristup) {
            setActivePristup({variables: {idPristupu: idPristupu as string, komodita}});
        }
    };

    const isValidKomodita = ProfileService.getKomodita();

    if (isLogged() && me?.registrovany && showPasswordStrengthDialog) {
        return (
            <LandingBackground pageOff>
                <LoginPasswordStrengthDialog email={email} onClickNo={handleClosePasswordStrengthDialog} onClose={handleClosePasswordStrengthDialog} />
            </LandingBackground>
        );
    }

    if (pristupy?.length && !reload && !refetchStatus && !me?.pristup?.id && !called && !loading) {
        const isActivePristupy = pristupy?.filter((pristup) => !!pristup?.aktivni);
        if (isActivePristupy?.length === 1 && !admin) {
            const idPristupu = isActivePristupy[0]?.id;
            if (!loadingSetActivePristup) {
                setActivePristup({variables: {idPristupu: idPristupu as string, komodita: komodita ?? Komodita.ELEKTRINA}});
            }
        } else if (!me?.skupina || admin)
            return <ChooseAccess handleChooseSkupina={handleChooseSkupina} handleOnChooseKomodita={handleOnChooseKomodita} handleLogout={handleLogout} />;
    }

    if (isLogged() && !isValidKomodita && me?.registrovany && !restrictedMode && (me?.pristup?.id || isZadatel || isObchodnik)) {
        const komodityList = isMobile ? [Komodita.ELEKTRINA] : hasMultipleKomodita ? [Komodita.ELEKTRINA, Komodita.PLYN] : getKomodity(me);
        const zadnaKomoditaKVyberu = komodityList.length === 0;
        if (komodityList.length === 1) {
            handleOnChooseKomodita(komodityList[0]);

            // Wait for redirect
            return (
                <LandingBackground pageOff>
                    <LoadingDialog open={true} description={Lang.PROFILE_LOADING_PORTAL} />
                </LandingBackground>
            );
        }
        if (matches && komodityList.length === 2 && !firstLogin && !isMobile) {
            return <MobileKomoditaInfoDialog handleOnChooseKomodita={handleOnChooseKomodita} />;
        }

        return (
            <ChooseKomodita
                handleLogout={handleLogout}
                handleOnChooseKomodita={handleOnChooseKomodita}
                komodityList={komodityList}
                zadnaKomoditaKVyberu={zadnaKomoditaKVyberu}
            />
        );
    }

    if (me && (komodita || restrictedMode)) {
        return (
            <PageContext.Provider
                value={{
                    me,
                    pristupy,
                    komodita: komodita ?? ProfileService.getKomodita(),
                    loggedInRestrictedMode: restrictedMode,
                    povoleneTypyPriloh: getPovoleneTypyPriloh(povoleneTypyPriloh),
                    adminContext: adminContext as AdminContext,
                }}
            >
                <LoadingDialog open={loading} description={Lang.PROFILE_LOADING_PORTAL} />
                {children}
            </PageContext.Provider>
        );
    }

    return children;
};
