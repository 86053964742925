import {Span} from '@eon.cz/apollo13-frontend-common/lib/components/styledComponents/Span';
import {Tooltip} from '@mui/material';
import {ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';

type Props = {
    title: string;
    icon: ReactNode;
};

/**
 * Komponenta pro zobrazení Icony s tooltipem
 */
export const IconWithTooltip = ({title, icon}: Props) => (
    <Tooltip title={<FormattedMessage id={title} />} placement="top">
        <Span sx={{display: 'flex'}}>{icon}</Span>
    </Tooltip>
);
