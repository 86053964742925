import {gql} from '@apollo/client';

export const KontaktyQueries = {
    gql: {
        kontakty: gql`
            query Kontakty($komodita: Komodita!) {
                kontakty {
                    kontakty(komodita: $komodita) {
                        id
                        kategorie {
                            id
                            klic
                            kod
                            nazev
                            skupina {
                                id
                            }
                        }
                        druhKomunikace
                        kontaktniUdaj
                        komodita
                        ean
                        eic
                        valid
                        identifikaceReferencnihoObjektu
                        typReferencnihoObjektu
                        eanAdresa {
                            ulice
                            cisloPopisne
                            cisloOrientacni
                            mesto
                            mistniCast
                            psc
                        }
                        eicAdresa {
                            ulice
                            cisloPopisne
                            cisloOrientacni
                            mesto
                            mistniCast
                            psc
                        }
                        adresa {
                            ulice
                            cisloPopisne
                            cisloOrientacni
                            mesto
                            mistniCast
                            psc
                        }
                    }
                    skupinyNotifikaci(komodita: $komodita) {
                        id
                        nazev
                        klic
                        kategorie {
                            id
                            nazev
                            povoleneDruhyKomunikace
                            elektrina
                            plyn
                            kod
                            povolitVyberEAN
                            povolitVyberEIC
                            povolitVyberKomodity
                        }
                    }
                }
                elektrina {
                    odbernaMista {
                        smlouvyOdbernychMist(filter: {napetoveUrovne: [VN, VVN], stav: AKTIVNI}, first: 1) {
                            edges {
                                node {
                                    id
                                    stav
                                    elektrina {
                                        napetovaUroven
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,

        kontaktyNastenka: gql`
            query KontaktyNastenka {
                kontakty {
                    kontaktyInvalid {
                        id
                        valid
                    }
                }
            }
        `,

        ucty: gql`
            query KontaktyUcty($input: String!) {
                ucty {
                    ucty(first: 5, filter: {aktivni: true, fulltext: {value: $input, columns: [jmeno, prijmeni, email]}}) {
                        edges {
                            node {
                                id
                                jmeno
                                prijmeni
                                telefon
                                email
                                skupina {
                                    poskytovatelElektrina
                                    eanObchodnika
                                    elektrina
                                    id
                                    ic
                                    typ
                                    obchodniPartner {
                                        id
                                        nazevSpolecnosti
                                        adresa {
                                            cisloPopisne
                                            ulice
                                            cisloOrientacni
                                            mesto
                                            mistniCast
                                            psc
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
        telefon: gql`
            query KontaktyTelefon($input: String!) {
                ucty {
                    ucty(first: 5, filter: {fulltext: {value: $input, columns: [jmeno, prijmeni, email]}, vyplnenyTelefon: true}) {
                        edges {
                            node {
                                id
                                jmeno
                                prijmeni
                                email
                                telefon
                            }
                        }
                    }
                }
            }
        `,
        om: gql`
            query KontaktyOdbernaMista($input: String!, $napetovaUroven: [NapetovaUroven!], $komodita: Komodita) {
                smlouvyOdbernychMist {
                    naseptavac(
                        first: 5
                        filter: {fulltextValue: $input, fulltextFields: [EANEIC, ADRESA], napetoveUrovne: $napetovaUroven, komodita: $komodita, stav: AKTIVNI}
                    ) {
                        edges {
                            node {
                                id
                                stav
                                plyn {
                                    eic
                                    adresa {
                                        ulice
                                        cisloPopisne
                                        mesto
                                        psc
                                        cisloOrientacni
                                        mistniCast
                                    }
                                }
                                elektrina {
                                    napetovaUroven
                                    eanSpotrebni
                                    adresa {
                                        ulice
                                        cisloPopisne
                                        mesto
                                        psc
                                        cisloOrientacni
                                        mistniCast
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
        jmena: gql`
            query KontaktyJmena {
                ucty {
                    ucty {
                        edges {
                            node {
                                id
                                jmeno
                                prijmeni
                                email
                                telefon
                            }
                        }
                    }
                }
            }
        `,

        skupinyNotifikaciZaZakaznikaViditelneObchodnikemIds: gql`
            query SkupinyNotifikaciZaZakaznikaViditelneObchodnikemIds($komodita: Komodita!) {
                kontakty {
                    skupinyNotifikaciViditelneObchodnikem(komodita: $komodita) {
                        id
                    }
                }
            }
        `,

        kontaktyProZakaznikaZaObchodnika: gql`
            query KontaktyProZakaznikaZaObchodnika($zakaznik: ID!, $komodita: Komodita!) {
                kontakty {
                    ziskatZakaznikovyKontaktyZalozeneObchodnikem(zakaznik: $zakaznik) {
                        id
                        kategorie {
                            id
                            klic
                            kod
                            nazev
                            skupina {
                                id
                            }
                        }
                        druhKomunikace
                        kontaktniUdaj
                        komodita
                        ean
                        eic
                        valid
                        identifikaceReferencnihoObjektu
                        typReferencnihoObjektu
                        eanAdresa {
                            ulice
                            cisloPopisne
                            cisloOrientacni
                            mesto
                            mistniCast
                            psc
                        }
                        eicAdresa {
                            ulice
                            cisloPopisne
                            cisloOrientacni
                            mesto
                            mistniCast
                            psc
                        }
                        adresa {
                            ulice
                            cisloPopisne
                            cisloOrientacni
                            mesto
                            mistniCast
                            psc
                        }
                    }

                    skupinyNotifikaciViditelneObchodnikem(komodita: $komodita) {
                        id
                        nazev
                        klic
                        kategorie {
                            id
                            nazev
                            povoleneDruhyKomunikace
                            elektrina
                            plyn
                            kod
                            povolitVyberEAN
                            povolitVyberEIC
                            povolitVyberKomodity
                        }
                    }
                }

                elektrina {
                    odbernaMista {
                        smlouvyOdbernychMistProZakaznika(filter: {napetoveUrovne: [VN, VVN], stav: AKTIVNI}, first: 1, zakaznik: $zakaznik) {
                            edges {
                                node {
                                    id
                                    stav
                                    elektrina {
                                        napetovaUroven
                                    }
                                }
                            }
                        }
                    }
                }
            }
        `,
    },
};
