import {DetailValueError, SafeFormattedMessage, SentIcon} from '@eon.cz/apollo13-frontend-common';
import {Query, ValidationError, ZadostDavkaStav, ZadostStav} from '@eon.cz/apollo13-graphql';
import {createElement, ReactNode} from 'react';
import {FormattedMessage} from 'react-intl';
import {nullAsEmpty} from '../../Utils';
import {ZadostiMenuItemModel} from '../model';
import {Lang} from './../../../Lang';

const PREFIX_FORM_NAME = 'ZADOSTI_';

type OpravnaZadostNotificationMessages = {
    errorHeadText: ReactNode;
    errorTailText: ReactNode;
    infoSend: ReactNode;
    infoDraft: ReactNode;
    zadost: ReactNode;
};

export const ZADOST_MAX_PRILOH = 10;

const ZadostiStavLeftMenu = {
    KONCEPT: 'KONCEPT',
    V_RESENI: 'V_RESENI',
    VYRESENO: 'VYRESENO',
    ZAMITNUTO: 'ZAMITNUTO',
    CEKA_NA_OPRAVU: 'CEKA_NA_OPRAVU',
    CEKA_NA_PODPIS: 'CEKA_NA_PODPIS',
} as const;

export const ZadostiService = {
    ELEKTRINA_SMLOUVA_NN_FORM: `${PREFIX_FORM_NAME}ELEKTRINA_SMLOUVA_NN_FORM`,
    ELEKTRINA_SMLOUVA_VN_FORM: `${PREFIX_FORM_NAME}ELEKTRINA_SMLOUVA_VN_FORM`,
    ELEKTRINA_OBNOVENI_FORM: `${PREFIX_FORM_NAME}ELEKTRINA_OBNOVENI_FORM`,
    ELEKTRINA_PRERUSENI_FORM: `${PREFIX_FORM_NAME}ELEKTRINA_PRERUSENI_FORM`,

    getAllDavkyStavy(): ZadostDavkaStav[] {
        return Object.values(ZadostDavkaStav);
    },
    getAllZadostiStavy(cekaNaOpravu?: string, cekaNaPodpis?: string): ZadostStav[] {
        let stavy = Object.values(ZadostiStavLeftMenu);

        if (!!cekaNaOpravu) {
            stavy = stavy.filter((stav) => stav !== cekaNaOpravu);
        }

        if (!!cekaNaPodpis) {
            stavy = stavy.filter((stav) => stav !== cekaNaPodpis);
        }

        return stavy as ZadostStav[];
    },

    getDavkyColorByLeftMenuItem(stavType: ZadostDavkaStav | undefined) {
        switch (stavType) {
            case ZadostDavkaStav.KONCEPT:
                return 'primary';
            case ZadostDavkaStav.ODESLANO:
                return 'green';
            case undefined:
                return 'grey';
            default:
                return 'grey';
        }
    },

    getZadostiColorByLeftMenuItem(stavType: ZadostiMenuItemModel | undefined) {
        switch (stavType) {
            case 'vse':
                return 'grey';
            case ZadostStav.V_RESENI:
                return 'orange';
            case ZadostStav.KONCEPT:
                return 'primary';
            case ZadostStav.VYRESENO:
                return 'green';
            case ZadostStav.ZAMITNUTO:
                return 'error';
            case undefined:
                return 'secondary';
            case ZadostStav.CEKA_NA_OPRAVU:
                return 'secondary';
            case ZadostStav.CEKA_NA_PODPIS:
                return 'secondary';
            default:
                return 'secondary';
        }
    },

    getDavkyNameByLeftMenuType(stavType: ZadostDavkaStav): string {
        switch (stavType) {
            case ZadostDavkaStav.KONCEPT:
                return Lang.ZADOSTI_MENU_KONCEPT;
            case ZadostDavkaStav.ODESLANO:
                return Lang.ZADOSTI_MENU_ODESLANO;
            default:
                return Lang.ZADOSTI_MENU_NEZNAME;
        }
    },

    getZadostiNameByLeftMenuType(stavType: ZadostiMenuItemModel): string {
        switch (stavType) {
            case 'vse':
                return Lang.ZADOSTI_MENU_VSE;
            case ZadostStav.V_RESENI:
                return Lang.ZADOSTI_MENU_V_RESENI;
            case ZadostStav.KONCEPT:
                return Lang.ZADOSTI_MENU_KONCEPT;
            case ZadostStav.VYRESENO:
                return Lang.ZADOSTI_MENU_VYRESENO;
            case ZadostStav.ZAMITNUTO:
                return Lang.ZADOSTI_MENU_ZAMITNUTO;
            case ZadostStav.CEKA_NA_OPRAVU:
                return Lang.ZADOSTI_CEKA_NA_OPRAVU;
            case ZadostStav.CEKA_NA_PODPIS:
                return Lang.ZADOSTI_CEKA_NA_PODPIS;
            default:
                return Lang.ZADOSTI_MENU_NEZNAME;
        }
    },

    getDavkyIconByLeftMenuType(stavType: ZadostDavkaStav) {
        switch (stavType) {
            case ZadostDavkaStav.KONCEPT:
                return 'drafts';
            case ZadostDavkaStav.ODESLANO:
                return <SentIcon />;
            default:
                return undefined;
        }
    },

    getZadostiIconByLeftMenuType(stavType: ZadostiMenuItemModel) {
        switch (stavType) {
            case 'vse':
                return 'all_inbox';
            case ZadostStav.V_RESENI:
                return 'donut_large';
            case ZadostStav.KONCEPT:
                return 'drafts';
            case ZadostStav.VYRESENO:
                return 'check';
            case ZadostStav.ZAMITNUTO:
                return 'error_outline';
            case ZadostStav.CEKA_NA_OPRAVU:
                return 'update';
            case ZadostStav.CEKA_NA_PODPIS:
                return 'update';
            default:
                return '';
        }
    },

    getAllLeftZadostiMenuItems(cekaNaOpravu?: string, cekaNaPodpis?: string): ZadostiMenuItemModel[] {
        const [koncept, vReseni, schvaleno, storno, ...zadostStav] = ZadostiService.getAllZadostiStavy(cekaNaOpravu, cekaNaPodpis);
        return [koncept, 'vse', ...zadostStav, vReseni, schvaleno, storno];
    },
    getAllLeftDavkyMenuItems(): ZadostDavkaStav[] {
        return [...ZadostiService.getAllDavkyStavy()];
    },

    isDividerInLeftMenu(type: ZadostiMenuItemModel): boolean {
        switch (type) {
            case 'vse':
            case ZadostStav.KONCEPT:
                return true;
            case ZadostStav.CEKA_NA_OPRAVU:
                return false;
            case ZadostStav.CEKA_NA_PODPIS:
                return false;
            case ZadostStav.VYRESENO:
                return false;
            case ZadostStav.V_RESENI:
                return false;
            case ZadostStav.ZAMITNUTO:
                return false;
            default:
                return false;
        }
    },
    isDividerInLeftMenuDavky(type: ZadostDavkaStav): boolean {
        switch (type) {
            case ZadostDavkaStav.KONCEPT:
                return true;
            case ZadostDavkaStav.ODESLANO:
                return false;
            default:
                return false;
        }
    },

    getDuvodOdectu(kod: string | undefined, data: Query | undefined) {
        if (kod === '3') {
            return data?.elektrina?.samoodecty?.duvodyOdectu?.find((kodDuvodu) => kodDuvodu.kod === 'P')?.kod;
        }
        if (['5A', '5B', '5C', '5D', '5E'].includes(kod ?? '')) {
            return data?.elektrina?.samoodecty?.duvodyOdectu?.find((kodDuvodu) => kodDuvodu.kod === 'Z')?.kod;
        }
        return null;
    },

    getNotificationMessages(): OpravnaZadostNotificationMessages {
        return {
            errorHeadText: createElement(FormattedMessage, {id: Lang.ZADOSTI_NOTIFICATION_ERROR_HEAD_TEXT}),
            errorTailText: createElement(FormattedMessage, {id: Lang.ZADOSTI_NOTIFICATION_ERROR_TAIL_TEXT}),
            infoSend: createElement(FormattedMessage, {id: Lang.ZADOSTI_NOTIFICATION_INFO_SEND}),
            infoDraft: createElement(FormattedMessage, {id: Lang.ZADOSTI_NOTIFICATION_INFO_DRAFT}),
            zadost: createElement(FormattedMessage, {id: Lang.ZADOSTI}),
        };
    },

    /**
     * Create map field name => error message
     *
     * @param {ValidationError[]} errors Error array
     */
    createErrorMap: (errors: ValidationError[]) => {
        const errorMap = {} as {[key: string]: ValidationError[]};
        if (errors) {
            errors.forEach((error) => {
                if (error.field) {
                    if (!errorMap[error.field]) {
                        errorMap[error.field] = [];
                    }
                    errorMap[error.field].push(error);
                }
            });
        }
        return errorMap;
    },

    /**
     * Filter errors that are not for any field
     *
     * @param {ValidationError[]} errors Error array
     */
    filterRecordErrors: (errors: ValidationError[]) => {
        return errors ? errors.filter((error) => !error.field) : [];
    },

    /**
     * Format address into one string
     */
    formatAddress: (
        ulice: string | undefined | null,
        cisloPopisne: string | undefined | null,
        cisloOrientacni: string | undefined | null,
        mesto: string | undefined | null,
        mistniCast: string | undefined | null,
        psc: string | undefined | null,
        cisloEvidencni?: string | undefined | null,
        stat?: string | undefined | null,
    ): string => {
        const hasCP = cisloPopisne && cisloPopisne.length > 0;
        const hasCO = cisloOrientacni && cisloOrientacni.length > 0;
        const hasCE = cisloEvidencni && cisloEvidencni.length > 0;

        let res = nullAsEmpty(ulice) + ' ';

        if (hasCP && hasCO) {
            res += `${cisloPopisne}/${cisloOrientacni}`;
        } else if (hasCE && hasCO) {
            res += `E${cisloEvidencni}/${cisloOrientacni}`;
        } else if (hasCP) {
            res += cisloPopisne;
        } else if (hasCO) {
            res += cisloOrientacni;
        } else if (hasCE) {
            res += `E${cisloEvidencni}`;
        }

        res += `${res.length > 1 && (!!psc || !!mesto) ? ', ' : ''}${nullAsEmpty(psc)} ${nullAsEmpty(mesto)}`;

        if (mistniCast && mistniCast.length > 0) {
            res += ` / ${mistniCast}`;
        }

        if (stat && stat.length > 0) {
            res += ` , ${stat}`;
        }

        // When we ended with just two spaces, address is empty
        return res === '  ' ? '-' : res;
    },

    /**
     * Naformátuje adresu katastru,pokud přijde null
     * @param katastr
     * @param parcela
     */
    formatKatastr: (katastr: string | null | undefined, parcela: string | null | undefined): string => {
        const hasKatastr = katastr && katastr.length > 0;
        const hasParcela = parcela && parcela.length > 0;

        let res = '';
        if (hasKatastr && hasParcela) {
            res += `${katastr}/${parcela}`;
        } else if (hasKatastr) {
            res += `${katastr}/ - `;
        } else if (hasParcela) {
            res += `- /${parcela}`;
        }

        return res;
    },

    /**
     * Format errors on an address
     *
     * @param addressErrorMap Map field name => error list
     * @param prefix Prefix to put before field name when constructing field label key
     */
    formatAddressErrors: (addressErrorMap: {[key: string]: ValidationError[]}, prefix?: string) => {
        const errors: DetailValueError[] = [];

        ['Mesto', 'Ulice', 'MistniCast', 'CisloPopisne', 'CisloOrientacni', 'Psc', 'Stat', 'Obec', 'CisloEvidencni'].forEach((field) => {
            const key = prefix ? prefix + field : field;
            if (addressErrorMap[key]) {
                addressErrorMap[key].forEach((e) => {
                    errors.push({
                        type: e.type,
                        message: (
                            <span>
                                <SafeFormattedMessage langArray={Lang} langArrayKey={`ZADOSTI_ERROR_ADRESA_${field.toUpperCase()}`} />
                                :&nbsp;
                                {e.message}
                            </span>
                        ),
                    });
                });
            }
        });

        return errors.length === 0 ? undefined : errors;
    },

    /**
     * Error funkce pro pole katastrální území
     * @param katastrErrorMap
     * @param prefix
     */
    formatKatastrError: (katastrErrorMap: {[key: string]: ValidationError[]}, prefix?: string) => {
        const errors: DetailValueError[] = [];

        ['KodKatastralnihoUzemi', 'KatastralniUzemi'].forEach((field) => {
            const key = prefix ? prefix + field : field;
            if (katastrErrorMap[key]) {
                katastrErrorMap[key].forEach((e) => {
                    errors.push({
                        type: e.type,
                        message: (
                            <span>
                                <SafeFormattedMessage langArray={Lang} langArrayKey={`ZADOSTI_ERROR_KATASTR_${field.toUpperCase()}`} />
                                :&nbsp;
                                {e.message}
                            </span>
                        ),
                    });
                });
            }
        });

        return errors.length === 0 ? undefined : errors;
    },
    /**
     * Error funkce pro pole parcela
     * @param katastrErrorMap
     * @param prefix
     */
    formatParcelaError: (katastrErrorMap: {[key: string]: ValidationError[]}, prefix?: string) => {
        const errors: DetailValueError[] = [];

        ['CisloParcelni', 'CisloParcelniPodcislo'].forEach((field) => {
            const key = prefix ? prefix + field : field;
            if (katastrErrorMap[key]) {
                katastrErrorMap[key].forEach((e) => {
                    errors.push({
                        type: e.type,
                        message: (
                            <span>
                                <SafeFormattedMessage langArray={Lang} langArrayKey={`ZADOSTI_ERROR_KATASTR_${field.toUpperCase()}`} />
                                :&nbsp;
                                {e.message}
                            </span>
                        ),
                    });
                });
            }
        });

        return errors.length === 0 ? undefined : errors;
    },
};
