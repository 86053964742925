import {createSelector, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Store} from '../../../Store';
import {PrilohyKeStazeniStore} from '../../../Store/PrilohyKeStazeniStore';
import {DanoveDokladyStavPlatby, PrilohyKeStazeniFilterModel} from '../model/PrilohyKeStazeni';
import {PrilohyKeStazeniStav} from '../model/PrilohyKeStazeniMenuItemModel';
import {PRILOHY_KE_STAZENI_TYP_VSE} from '../services/PrilohyKeStazeniService';

export const initialState = {
    filter: {
        datumOd: null,
        datumDo: null,
        typ: PRILOHY_KE_STAZENI_TYP_VSE,
        komodita: undefined,
        cisloDokladu: undefined,
        stav: DanoveDokladyStavPlatby.CEKA_NA_ZAPLACENI,
    },
    selectedLeftMenuType: undefined,
    tab: 0,
} as PrilohyKeStazeniStore;

export const prilohyKeStazeniSlice = createSlice({
    name: 'prilohyKeStazeni',
    initialState,
    // The `reducers` field lets us define reducers and generate associated actions
    reducers: {
        setPrilohyKeStazeniFilter: (state: PrilohyKeStazeniStore, {payload}: PayloadAction<PrilohyKeStazeniFilterModel>): PrilohyKeStazeniStore => ({
            ...state,
            filter: {...state.filter, ...payload},
        }),

        setClearPrilohyKeStazeniFilter: (state: PrilohyKeStazeniStore): PrilohyKeStazeniStore => ({
            ...state,
            filter: initialState.filter,
        }),
        setChangePrilohyKeStazeniSelectedLeftMenuType: (
            state: PrilohyKeStazeniStore,
            {payload}: PayloadAction<PrilohyKeStazeniStav | undefined>,
        ): PrilohyKeStazeniStore => ({
            ...state,
            selectedLeftMenuType: payload,
            filter: initialState.filter,
        }),
        setPrilohyKeStazeniTab: (state: PrilohyKeStazeniStore, {payload}: PayloadAction<number>): PrilohyKeStazeniStore => ({
            ...state,
            tab: payload,
        }),
    },
});

export const {setChangePrilohyKeStazeniSelectedLeftMenuType, setClearPrilohyKeStazeniFilter, setPrilohyKeStazeniFilter, setPrilohyKeStazeniTab} =
    prilohyKeStazeniSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const prilohyKeStazeni = (state: Store) => state.prilohyKeStazeni;
export const selectPrilohyKeStazeniStore = createSelector([prilohyKeStazeni], (prilohyKeStazeni) => prilohyKeStazeni);
