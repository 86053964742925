import {CsvIcon, EnvironmentUtils, ExcelIcon, JpegIcon, JsonIcon, PngIcon, WordIcon} from '@eon.cz/apollo13-frontend-common';
import {HdoCas, Komodita, Maybe} from '@eon.cz/apollo13-graphql';
import {AttachmentOutlined, PictureAsPdfOutlined} from '@mui/icons-material';
import isEqual from 'lodash/isEqual';
import isFunction from 'lodash/isFunction';
import omitBy from 'lodash/omitBy';
import {ChangeEvent, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {Store} from '../../../Store';
import {useTableAction} from '../actions/TableActions';
import {getFilenameExtension} from './FileService';

/**
 * This function returns the current screen orientation of the device and updates it when the
 * orientation changes.
 * @returns The `useScreenOrientation` hook returns the current screen orientation as a string value of
 * `'portrait-primary'`, `'landscape-primary'`, `'landscape-secondary'`, or `'portrait-secondary'`.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/Screen/orientation
 */
export const useScreenOrientation = () => {
    const [orientation, setOrientation] = useState<'portrait-primary' | 'landscape-primary' | 'landscape-secondary' | 'portrait-secondary'>(
        'landscape-primary',
    );

    const updateOrientation = (mq: 'portrait-primary' | 'landscape-primary') => () => {
        setOrientation(mq);
    };

    useEffect(() => {
        if (typeof window !== 'undefined') {
            setOrientation(window.matchMedia('(orientation: portrait)').matches ? 'portrait-primary' : 'landscape-primary');
        }
    }, []);
    useEffect(() => {
        const mq = window.matchMedia('(orientation: portrait)').matches ? 'portrait-primary' : 'landscape-primary';
        window.addEventListener('orientationchange', updateOrientation(mq));
        return () => {
            window.removeEventListener('orientationchange', updateOrientation(mq));
        };
    }, []);

    return orientation;
};

/**
 * It takes an object with string keys and string values, and returns a new object with the keys and
 * values swapped
 * @param map - {[key: string]: string} - this is the object we want to reverse
 */
export const reversObjectKeyValue = (map: {[key: string]: string}) =>
    Object.entries(map).reduce((acc, [key, value]) => ({...acc, [value]: key}), {} as {[key: string]: string});

/**
 * The function removes null values from an object and returns a new object without those null values.
 * @param {T} object - The `object` parameter is the input object from which you want to remove null
 * values.
 * @returns The function `removeNullValuesFromObject` returns a new object with all null or undefined
 * values removed from the input object.
 */
export const removeNullValuesFromObject = <T extends any | undefined>(object: T) => {
    return Object.fromEntries(Object.entries(object ?? {}).filter(([, v]) => v !== null && v !== undefined)) as T;
};

/**
 * The function `changeUndefinedToNull` converts any undefined values in an object to null.
 * @param {T} object - The `object` parameter in the `changeUndefinedToNull` function is of type `T`,
 * which can be any type or `undefined`.
 * @returns The `changeUndefinedToNull` function returns an object where any `undefined` values are
 * replaced with `null`. The return type is the same as the input type `T`, which can be any type
 * including `undefined`.
 */
export const changeUndefinedToNull = <T extends any | undefined>(object: T) => {
    return Object.fromEntries(Object.entries(object ?? {}).map(([key, value]) => [key, value ?? null])) as T;
};

/**
 * The function `browserSupport` checks the user agent string for various browser
 * and device types to determine browser support.
 * @param {string} userAgent - The `browserSupport` function takes a `userAgent` string as a parameter.
 * This `userAgent` string typically contains information about the user's browser, device, and
 * operating system. The function then checks various conditions based on the `userAgent` string to
 * determine if the user's browser is
 * @returns The function `browserSupport` returns a boolean value based on whether the user agent
 * string provided includes certain keywords indicating support for various browsers and devices.
 */
export const browserSupoort = (userAgent: string) => {
    const UA = userAgent.toLowerCase() ?? '';
    // const isIE = UA && /; msie|trident/i.test(UA);
    const isEdge = UA && /edg/i.test(UA);
    const isAndroid = UA && UA.indexOf('android') > 0;
    const isIOS = UA && /iphone|ipad|ipod|ios/i.test(UA);
    const isChrome = UA && /chrome|crios/i.test(UA) && !/opr|opera|chromium|edg|ucbrowser|googlebot|presto/i.test(UA);
    // const isGoogleBot = UA && /googlebot/i.test(UA);
    const isChromium = UA && /chromium/i.test(UA);
    // const isUcBrowser = UA && /ucbrowser/i.test(UA);
    const isSafari = UA && /safari/i.test(UA) && !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox|presto/i.test(UA);
    const isFirefox = UA && /firefox|fxios/i.test(UA) && !/seamonkey/i.test(UA);
    const isOpera = UA && /opr|opera|presto/i.test(UA);
    const isMobile = /\b(iPhone)\b/i.test(UA) || /\b(Android|iPad|iPod)\b/i.test(UA);
    const isSamsung = UA && /samsungbrowser/i.test(UA);
    const isIPad = UA && /ipad/.test(UA);
    const isIPhone = UA && /iphone/.test(UA);
    const isIPod = UA && /ipod/.test(UA);
    const isWebView = userAgent.includes('wv');
    const Distribuce24App = userAgent.includes('Distribuce24App');
    const isMac = UA && /macintosh|mac os x/i.test(UA);
    return (
        isEdge ||
        isAndroid ||
        isIOS ||
        isIPad ||
        isIPhone ||
        isIPod ||
        isChrome ||
        isChromium ||
        isSafari ||
        isFirefox ||
        isSamsung ||
        isMobile ||
        isWebView ||
        Distribuce24App ||
        isMac ||
        isOpera
    );
};

/**
 * It takes a date string as input and returns true if the date is valid and false if the date is
 * invalid
 * @param [dateInput] - The date string to validate.
 */
export const validateDate = (dateInput = '') => {
    if (!dateInput) {
        // invalid date if dateInput is empty
        return false;
    }
    if (dateInput?.length < 8) {
        // invalid date if dateInput length is less than 10
        return false;
    }
    // splitter will split the date and get the date seperator eg: -
    const splitter = dateInput.replace(/[0-9]/g, '')[0];
    // using splitter will get the parts of the date
    const parts = dateInput.split(splitter);

    // since year can be in front for yyyy-mm-dd and in last for dd-mm-yyyy taking care of that logic
    const year = parts[0].length === 4 ? parts[0] : parts[2];
    // month will be always in center
    const month = parts[1];
    // taking care of day for the different formats like yyyy-mm-dd or dd-mm-yyyy
    const day = parts[0].length === 4 ? parts[2] : parts[0];

    // creating date our of the year, month day
    const date = new Date(Number(year), +month - 1, Number(day));

    //validates leapyear and dates exceeding month limit
    const isValidDate = Boolean(+date) && date.getDate() === Number(day);

    // isValid date is true if the date is valid else false
    return isValidDate;
};

/**
 * It returns true if the two objects are equal, except for functions
 * @param {T} prevProps - The previous props that were passed to the component.
 * @param {T} nextProps - The next props that will be received by the component.
 * @returns A function that takes two arguments, prevProps and nextProps, and returns a boolean.
 */
export const areEqual = <T extends Record<string, unknown>>(prevProps: T, nextProps: T): boolean => {
    const [prev, next] = [prevProps, nextProps].map((props) => omitBy(props, isFunction));
    return isEqual(prev, next);
};

export const getIconByMimeType = (file?: string | null, classes?: Record<string, string>) => {
    const mime = getFilenameExtension(file);

    switch (mime) {
        case 'doc':
        case 'docx':
            return <WordIcon sx={classes} />;
        case 'xls':
        case 'xlsx':
            return <ExcelIcon sx={classes} />;
        case 'pdf':
            return <PictureAsPdfOutlined sx={classes} />;
        case 'csv':
            return <CsvIcon sx={classes} />;
        case 'xml':
        case 'json':
            return <JsonIcon sx={classes} />;
        case 'jpg':
        case 'jpeg':
            return <JpegIcon sx={classes} />;
        case 'png':
            return <PngIcon sx={classes} />;
        case null:
            <AttachmentOutlined sx={classes} />;
        default:
            return <AttachmentOutlined sx={classes} />;
    }
};

/**
 * The useTablePagination function manages table pagination state and actions.
 * @param  - The `useTablePagination` function is a custom hook that helps manage pagination for a
 * table component. Here is an explanation of the parameters:
 * @returns The `useTablePagination` function returns an object with the following properties:
 */
export const useTablePagination = <T,>({
    data,
    rowsPerPageInitial,
    pageInitial,
    destroyOnUnmount,
}: {
    data: T;
    rowsPerPageInitial?: number;
    pageInitial?: number;
    destroyOnUnmount?: boolean;
}) => {
    const {resetTablePagination, setTablePage, setTableRowsPerPage} = useTableAction();
    const {page, rowsPerPage} = useSelector((state: Store) => state.table);

    useEffect(() => {
        return () => {
            if (destroyOnUnmount) {
                resetTablePagination();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (rowsPerPageInitial) {
            setTableRowsPerPage(rowsPerPageInitial);
        }
        if (pageInitial) {
            setTablePage(pageInitial);
        }
    }, [pageInitial, resetTablePagination, rowsPerPageInitial, setTablePage, setTableRowsPerPage]);

    const handleChangePage = (_: any, newPage: number) => {
        setTablePage(newPage);
        setTablePage(newPage);
    };
    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
        setTableRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
        setTableRowsPerPage(parseInt(event.target.value, 10));
        setTablePage(0);
    };

    const tableData = (data as T[])?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return {rowsPerPage, page, handleChangePage, handleChangeRowsPerPage, resetTablePagination, tableData: tableData as T};
};

type WidgetData =
    | {
          ean: string | undefined;
          vlastniNazevOM: Maybe<string> | undefined;
          hdo:
              | {
                    sazby:
                        | {
                              id: number;
                              dny:
                                  | {
                                        id: number;
                                        casy: Maybe<HdoCas>[] | undefined;
                                        denVTydnu: number | undefined;
                                    }[]
                                  | undefined;
                              sazba: string;
                          }[]
                        | undefined;
                    endDate: string | undefined;
                }
              | undefined;
      }[]
    | undefined;
type RNDataInput = {
    saveUserId?: string;
    openBiometrics?: boolean;
    openNativeCamera?: boolean;
    setWidgetData?: WidgetData;
    logouting?: boolean;
    hideTabs?: 'HIDE' | 'SHOW';
    openNativeSettings?: boolean;
    openLocationPermission?: boolean;
    downloadFile?: string;
    biometricsAutoLogin?: 'TRUE' | 'FALSE';
};

export const sendDataToRN = (data: RNDataInput) => {
    window?.ReactNativeWebView?.postMessage(JSON.stringify({data}, null, 2));
};

/**
 * The function checks if a given string is a valid JSON string.
 * @param {Record<string, unknown> | string | undefined} str - The `str` parameter in the
 * `isJsonString` function can be one of the following types:
 * @returns a boolean value. It returns true if the input string can be parsed as a valid JSON string,
 * and false otherwise.
 */
export const isJsonString = (str: Record<string, unknown> | string | undefined) => {
    if (typeof str !== 'string') return false;
    try {
        JSON.parse(str);
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(`%c 🚨 -> isJsonString error: `, 'color: #e13019', error);
        return false;
    }
    return true;
};

/**
 * Funkce pro formátování katastrálního území
 * @param {{
 *     obec: string | undefined | null;
 *     cisloParcely: string | undefined | null;
 *     katastralniUzemiNazev: string | undefined | null;
 *     psc: string | undefined | null;
 * }} adresa
 * @return {string}
 */
export const formatKatastralniUzemi = (adresa: {
    obec: string | undefined | null;
    cisloParcely: string | undefined | null;
    katastralniUzemiNazev: string | undefined | null;
    psc: string | undefined | null;
}): string => {
    let res = '';
    res += `${adresa.obec ?? ''}`;
    res += adresa.cisloParcely ? `, č.parcely ${adresa.cisloParcely}` : '';
    res += adresa.katastralniUzemiNazev ? `, k.ú.${adresa.katastralniUzemiNazev}` : '';
    res += `, ${adresa.psc ?? ''}`;
    return res;
};

/**
 * The function `switchToKomodita` determines the appropriate Komodita based on input parameters.
 * @param {Komodita | undefined | null} komodita - The `komodita` parameter represents a type of
 * commodity, which can be either `ELEKTRINA` or `PLYN`.
 * @param {boolean | undefined} pristupKomoditaElektrina - The parameter `pristupKomoditaElektrina` is
 * a boolean value that indicates whether there is access to electricity for the specified `komodita`.
 * If `pristupKomoditaElektrina` is `true`, it means there is access to electricity for the
 * @param {boolean | undefined} pristupKomoditaPlyn - The parameter `pristupKomoditaPlyn` is a boolean
 * value that indicates whether there is access to the gas commodity. If it is `true`, it means there
 * is access to the gas commodity, and if it is `false` or `undefined`, it means there is no access
 * @returns The function `switchToKomodita` returns either `Komodita.ELEKTRINA`, `Komodita.PLYN`, or
 * `undefined` based on the conditions specified in the code.
 */
export const switchToKomodita = (
    komodita: Komodita | undefined | null,
    pristupKomoditaElektrina: boolean | undefined,
    pristupKomoditaPlyn: boolean | undefined,
) => {
    if (komodita === Komodita.ELEKTRINA && pristupKomoditaElektrina) return Komodita.ELEKTRINA;
    if (komodita === Komodita.PLYN && pristupKomoditaPlyn) return Komodita.PLYN;
    if (komodita !== Komodita.ELEKTRINA && pristupKomoditaElektrina && !pristupKomoditaPlyn) return Komodita.ELEKTRINA;
    if (komodita !== Komodita.PLYN && pristupKomoditaPlyn && !pristupKomoditaElektrina) return Komodita.PLYN;
    return undefined;
};

/**
 * The function `getQuerySelector` takes a CSS selector as input and returns a list of elements that
 * match the selector in the document.
 * @param {string} selector - A CSS selector string that specifies the elements you want to select.
 * @returns An array-like NodeList containing all elements in the document that match the specified
 * selector.
 */
export const getQuerySelector = (selector: string) => {
    return document.querySelectorAll(selector);
};

/**
 * The function `changeFavicon` changes the website's favicon based on the provided `komodita` value
 * and sets the `komodita` value in a cookie.
 * @param {Komodita | undefined | null} komodita - The `komodita` parameter in the `changeFavicon`
 * function is of type `Komodita | undefined | null`. This means it can accept a value of type
 * `Komodita` (which seems to be an enum or a specific type), `undefined`, or `null`.
 */
export const changeFavicon = (komodita: Komodita | undefined | null) => {
    const link = getQuerySelector("link[rel~='icon']");
    const icon = komodita === Komodita.PLYN ? '/static/images/favicongas.png' : '/static/images/favicon.ico';
    if (link.length > 0) {
        link.forEach((l) => {
            if (l instanceof HTMLLinkElement) {
                l.href = icon; // Zde můžeme bezpečně přiřadit novou hodnotu href
            }
        });
    }

    // nastaveni komodity do cookie
    document.cookie = `komodita=${komodita}; path=/`;
};

export const isProdPreprod = EnvironmentUtils.isProdPreprod();
/**
 * The `logger` function logs data with a specified name and log type, with an option to hide logs in
 * production or pre-production environments.
 * @param  - The `logger` function takes in an object with the following parameters:
 * @returns If the `mode` is set to 'hide' and `isProdPreprod` is true, nothing will be logged and the
 * function will return undefined. Otherwise, the function will log the message using
 * `console[logType]` and return undefined.
 */
export const logger = <T extends string, U extends Record<string, unknown> | unknown>({
    name,
    data,
    logType = 'log',
    mode = 'development',
}: {
    name: T;
    data: U;
    logType?: 'error' | 'log';
    mode?: 'development' | 'production' | 'devAndProd';
}) => {
    if ((mode === 'production' && isProdPreprod) || (mode === 'development' && !isProdPreprod) || mode === 'devAndProd') {
        // eslint-disable-next-line no-console
        console[logType](`%c 🚨 -> ${name}: `, 'color: #e13019', data);
        return;
    }
    return;
};
