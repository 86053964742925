import {Komodita, PovoleneTypyPriloh, Ucet} from '@eon.cz/apollo13-graphql';
import {useMediaQuery} from '@mui/material';
import {ServerResponse} from 'http';
import memoize from 'lodash/memoize';
import Router from 'next/router';
import sanitizeHtml from 'sanitize-html';
import {MobileProps} from '../model/MobilePropsModel';

export const PageService = {
    /**
     * Redirect correctly to given path
     *
     * @param path Path to redirect to
     * @param res Express response
     */
    redirect: async (path: string, res?: ServerResponse) => {
        if (res) {
            res.writeHead(302, {Location: path});
            res.end();
            res.finished = true;
        } else {
            await Router.push(path);
        }
    },
};

/**
 * The `useMatches` function returns a boolean value indicating whether the current viewport width is
 * less than or equal to the specified `maxWidth`.
 * @param [maxWidth=500px] - The `maxWidth` parameter is a string that represents the maximum width for
 * a media query. In this case, the default value is set to `'500px'`. This parameter is used in the
 * `useMatches` function to check if the current viewport width matches the specified maximum width.
 */
export const useMatches = (maxWidth = '500px') => useMediaQuery(`(max-width:${maxWidth})`);
/**
 * The function `useMatchesTouchDevice` checks if the user's device is a touch device by matching the
 * user agent string with specific keywords.
 */
export const useMatchesTouchDevice = () => /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

/**
 * The useTablet function returns true if the screen width is between 600px and 1200px.
 * @returns The function `useTablet` is returning a boolean value that is true if the screen width is
 * between 600px and 1200px (inclusive) and false otherwise.
 */
export const useTablet = () => {
    const minWidth600 = useMediaQuery('(min-width:600px)');
    const maxWidth1200 = useMatches('1200px');
    return maxWidth1200 && minWidth600;
};

/**
 * It takes a string of HTML and returns a string of HTML that's been sanitized
 * @param {string} html - The HTML string to sanitize.
 */
export const sanitizeHTML = (html: string) => sanitizeHtml(html);

/**
 * The function `getKomodity` returns an array of commodities based on the properties of a given object
 * `me`.
 * @param {Ucet | null | undefined} me - The `me` parameter in the `getKomodity` function is of type
 * `Ucet` or can be `null` or `undefined`.
 * @returns An array of Komodita values based on the presence of elektrina and plyn properties in the
 * skupina object of the provided Ucet object.
 */
export const getKomodity = (me: Ucet | null | undefined): Komodita[] => {
    const result: Komodita[] = [];
    if (me?.skupina?.elektrina) {
        result.push(Komodita.ELEKTRINA);
    }
    if (me?.skupina?.plyn) {
        result.push(Komodita.PLYN);
    }
    return result;
};

/**
 * Zkonvertuje povolené typy příloh na mapu podle typu objektu
 */
export const convertPovoleneTypyPriloh = (typy: PovoleneTypyPriloh[]) =>
    typy.reduce(
        (map, typ: PovoleneTypyPriloh) => {
            map[typ.typObjektu] = typ;
            return map;
        },
        {} as {[key: string]: PovoleneTypyPriloh},
    );

/**
 * Memoizovaná verze. POZOR!!! Nezohledňue parametr (protože by to bylo skoro stejně náročné jako samotný výpočet),
 * tedy vrací bez ohledu na parametr to, co v prvním volání. Nicméně data by se neměla po dobu sesson měnit.
 */
const convertPovoleneTypyPrilohMemoized = memoize(convertPovoleneTypyPriloh, () => 1);

export const getPovoleneTypyPriloh = (povoleneTypyPriloh: PovoleneTypyPriloh[] | undefined) => {
    if (!povoleneTypyPriloh) {
        // No data
        return {};
    }

    return convertPovoleneTypyPrilohMemoized(povoleneTypyPriloh);
};

/**
 * The function `getFromLS` retrieves data from local storage based on the specified id and optional
 * key.
 * @param {'mobile' | 'desktop'} id - The `id` parameter in the `getFromLS` function is a string
 * literal type that can only have the values `'mobile'` or `'desktop'`.
 * @param [key] - The `key` parameter in the `getFromLS` function is of type `keyof MobileProps`, which
 * means it can be any key that exists in the `MobileProps` type. It is used to specify which specific
 * property of the `MobileProps` object you want to retrieve from the
 * @returns The function `getFromLS` returns a value of type `MobileProps`. If a `key` parameter is
 * provided, it returns the value corresponding to that key from the local storage object for the
 * specified `id` ('mobile' or 'desktop'). If no `key` is provided, it returns the entire local storage
 * object for the specified `id`.
 */
export const getFromLS = (id: 'mobile' | 'desktop', key?: keyof MobileProps): MobileProps => {
    let ls = {} as Record<string, any>;
    if (global.localStorage) {
        try {
            ls = JSON.parse(global.localStorage.getItem(id) ?? '{}') || {};
        } catch (error) {
            // eslint-disable-next-line no-console
            console.log(`%c 🚨 -> getFromLS error: `, 'color: #e13019', error);
        }
    }
    return key ? ls[key] : ls;
};

/**
 * The function `saveToLS` saves a value to local storage based on the device type provided.
 * @param {T} id - The `id` parameter in the `saveToLS` function is a string that specifies the
 * identifier under which the data will be saved in the local storage. It can have a value of either
 * `'mobile'` or `'desktop'`.
 * @param value - The `value` parameter in the `saveToLS` function is of type `U`, which is determined
 * based on the type `T`. If `T` is `'mobile'`, then `U` will be `MobileProps`, otherwise it will
 * default to `any`. This means that the
 */
export const saveToLS = <T extends 'mobile' | 'desktop', U = T extends 'mobile' ? MobileProps : any>(
    id: T,
    value: U extends MobileProps ? MobileProps : any,
) => {
    if (global.localStorage) {
        const oldValues = getFromLS(id);
        global.localStorage.setItem(
            id,
            JSON.stringify({
                ...oldValues,
                ...value,
            }),
        );
    }
};
